//NOTE: DO NOT UPDATE THESE VALUES WITHOUT UPDATING VALUES SAVED IN USER PREFERENCE TABLE
export enum UserPreferenceEnum {
  DefaultSenseDashboardReportingHierarchy = 'default_sense_dashboard_reporting_hierarchy',
  DefaultTempDashboardReportingHierarchy = 'default_temp_dashboard_reporting_hierarchy',
  DefaultLabellingDashboardReportingHierarchy = 'default_labelling_dashboard_reporting_hierarchy',
  TempDashboardSectionsSettings = 'temp_dashboard_customization',
  SenseDashboardCorporateSectionsSettings = 'sense_dashboard_corporate_customization',
  SenseDashboardStoreSectionsSettings = 'sense_dashboard_store_customization',
  DefaultAdvancedChecklistReportingHierarchy = 'default_advanced_checklist_dashboard_reporting_hierarchy',
  AdvancedChecklistDashboardSectionsSettings = 'checklist_dashboard_customization',
  DefaultLabelDesignerOption = 'default_label_designer_options',
  MenuBulkEditColumnSelection = 'menu_bulk_edit_column_selection',
  OpsChecklistBulkEditColumnSelection = 'ops_checklist_bulk_edit_column_selection',
  TempChecklistBulkEditColumnSelection = 'temp_checklist_bulk_edit_column_selection',
  MediaDashboardSectionsSettings = 'media_dashboard_customization',
  LabelingDashboardMenuSectionsSettings = 'labeling_dashboard_menu_items_customization',
  LabelingDashboardEmployeesSectionsSettings = 'labeling_dashboard_employees_customization',
  LabelingDashboardLabelsSectionsSettings = 'labeling_dashboard_labels_customization'
}
