export class CustomField {
  constructor(
    public menuItemCustomFieldId: number,
    public type: string,
    public typeId: number,
    public name: string,
    public tenantId: number,
    public createdBy?: number,
    public lastUpdatedBy?: number,
    public createdAt?: Date,
    public lastUpdatedAt?: Date,
    public key?: string,
    public isSubscription?: boolean,
    public width?: number,
    public height?: number,
    public file?: any,
    public listOptions?: CustomFieldListOptions[],
    public customFieldListOptionId?: number,
    public isDisabledForSubscriber?: boolean,
    public mediaId?: number,
    public baseUrl?: string,
    public isRequired?: boolean,
    public digits?: number,
    public startValue?: number,
    public isUsed?: number,
    public isSearchable?: boolean
  ) {}
}

export class CustomFieldListOptions {
  constructor(
    public displayName: string,
    public caption: string,
    public isDefault: boolean = false,
    public sortOrder: number,
    public statusId: number,
    public listOptionId?: number,
    public value?: string,
    public baseUrl?: string,
    public key?: string
  ) {}
}

export class CustomFieldValue {
  constructor(
    public menuItemCustomFieldId: number,
    public customFieldValue: string,
    public type: CustomFieldTypeEnum,
    public file?: any,
    public customFieldListOptionId?: number,
    public mediaId?: number,
    public baseUrl?: string,
    public isRequired?: boolean,
    public key?: string,
    public name?: string,
    public customFieldListOptionKey?: string
  ) {}
}

export class CustomFieldType {
  constructor(public code: number, public value: string, public description: string, public translationKey?: string) {}
}

export enum CustomFieldTypeEnum {
  Double = 1,
  String = 2,
  Int = 3,
  Image = 4,
  List = 5,
  SerialNumber = 6,
  Price = 7,
  ImageList = 8
}
