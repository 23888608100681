import { catchError, map, switchMap } from 'rxjs/operators';
import { BrandModel } from './../../shared/models/brand-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, Subject, forkJoin, from } from 'rxjs';
import { APIHttpResponseCode, APIResponseModel } from '../../shared/models/api-response';
import { TokenModule } from '../../shared/models/tokenModule.model';
import { environment } from './../../../environments/environment';
import { UpdateAppComponent } from './../../shared/components/update-app/update-app.component';
import { booleanIsDefinedAndNotEmpty, defined, definedAndNotEmptyString, onGetSubroute, setTempUoMId, stringIsDefinedAndNotEmpty } from './../../shared/helpers/app.helpers';
import { LocalStorageFlags } from '../../shared/models/enums/local-storage-flags.enum';
import { UserPreferenceEnum } from '../../shared/models/enums/user-preference.enum';
import { TenantModuleOptions, tenantModuleOptionsGroup } from '../../modules/account-manager/models/tenant-module-option.enum';
import { PunchOutModeEnum } from '../../shared/models/enums/punch-out-mode.enum';
import { I } from '@angular/cdk/keycodes';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { LoaderService } from '../../../app/shared/services/loader.service';
import { LoginWarningDialogComponent } from '../dialogs/login-warning-dialog/login-warning-dialog.component';
import { CookieConsentComponent } from '../../shared/components/cookie-consent/cookie-consent.component';
import { CookieService } from './cookie.service';
import { CookiesFlags, CookiesValues } from '../../shared/models/enums/cookies.enum';
import { COMPANY_NAME } from '../../shared/constants.config';
import { SnackbarService } from '../../shared/services/snackbar.service';
import { ResellerCheckService } from '../../shared/services/reseller-check.service';
import { NgxZendeskWebwidgetService } from '@nitsanzo/ngx-zendesk-webwidget';
import { ResellerTenantType } from '../../modules/account-manager/models/reseller-tenant-type.model';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiAppVersion: string;
  appRequiresUpdate: boolean;
  appRequiresLoginAfterUpdate: boolean;
  updateAppDialog: MatDialogRef<UpdateAppComponent>;
  dialogOpen: boolean;
  postponeRequest = false;
  requests = 0;
  showUpdateAppDialog: Subject<string> = new Subject();
  selectedBrand: BrandModel;
  tokenBeingRefreshed: BehaviorSubject<boolean> = new BehaviorSubject(false);
  keycloakTokenBeingRefreshed: BehaviorSubject<boolean> = new BehaviorSubject(false);
  zyTokenBeingRefreshed: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentToken: Observable<APIResponseModel<any>>;
  firstload: boolean;

  kbTokenChanged: Subject<void> = new Subject<void>();
  impersonationMode: Subject<boolean> = new Subject<boolean>();
  reloadPage: Subject<boolean> = new Subject<boolean>();
  public currentUserNameChanged: Subject<string> = new Subject();
  private _refreshTokenSubject: Subject<APIResponseModel<any>> = new Subject();
  private get refreshTokenSubject(): Subject<APIResponseModel<any>> {
    if (this._refreshTokenSubject.isStopped) {
      // check if the subject completed, if so, create another one
      this._refreshTokenSubject = new Subject();
    }
    return this._refreshTokenSubject;
  }
  allowedModules: TokenModule[];
  moduleName: string;

  loginWarningDialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private brandStorageKey = 'currentBrand';
  get accountSupportsTheTempApplication(): boolean {
    const hasTempConfActivated = this.hasTempConfActivated();
    const hasSenseConfActivated = this.hasSenseConfActivated();

    return hasTempConfActivated || hasSenseConfActivated;
  }

  get accountSupportsTheOpsApplication(): boolean {
    const labelingEnabled = this.hasLabelingActivated();
    const opsChecklistActivated = this.hasChecklistActivated();
    const advancedChecklistActivated = this.isAdvancedChecklistActivated();
    const timersActivated = this.hasTimersActivated();

    return labelingEnabled || opsChecklistActivated || advancedChecklistActivated || timersActivated;
  }
  constructor(
    public router: Router,
    private httpClient: HttpClient,
    private jwtHelper: JwtHelperService,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private keycloakService: KeycloakService,
    private loaderService: LoaderService,
    private cookieService: CookieService,
    private snackbarService: SnackbarService,
    private resellerCheckService: ResellerCheckService,
    private _NgxZendeskWebwidgetService: NgxZendeskWebwidgetService
  ) {
    this.showUpdateAppDialog.subscribe((show) => {
      this._showUpdateAppDialog(show === 'force' ? true : null);
    });

    if (this.getAuthenticateUsingKeycloakOIDC()) {
      this.keycloakService.keycloakEvents$.subscribe({
        next: (e) => {
          if (e.type == KeycloakEventType.OnAuthSuccess) {
          } else if (e.type == KeycloakEventType.OnTokenExpired) {
            this.refreshKeycloakToken().subscribe((response) => {
              if (response.results == null) this.logout();
            });
          } else if (e.type == KeycloakEventType.OnAuthRefreshError) {
          }
        }
      });
    }
  }

  getUseNewSubscriptionFlow(): boolean {
    const useNewSubscriptionFlow = localStorage.getItem('useNewSubscriptionFlow');
    return useNewSubscriptionFlow && useNewSubscriptionFlow == 'true' ? true : false;
  }

  setUseNewSubscriptionFlow(useNewSubscriptionFlow: boolean): void {
    localStorage.setItem(LocalStorageFlags.useNewSubscriptionFlow, useNewSubscriptionFlow.toString());
  }

  setHasDualNutrionalPanelsInLocalStorage(hasDualNutrionalPanels: boolean): void {
    localStorage.setItem(LocalStorageFlags.hasDualNutrionalPanels, hasDualNutrionalPanels.toString());
  }

  doesAccountSupportsDualNutrionalPanels(): boolean {
    return localStorage.getItem(LocalStorageFlags.hasDualNutrionalPanels) === 'true';
  }

  setRequirePinForUsers(requirePinForUsers: boolean): void {
    localStorage.setItem(LocalStorageFlags.requirePinForUsers, requirePinForUsers.toString());
  }

  doesAccountRequirePinForUsers(): boolean {
    return localStorage.getItem(LocalStorageFlags.requirePinForUsers) === 'true';
  }

  setSecondaryLanguageConfig(hasSecondaryLanguage: boolean) {
    localStorage.setItem('hasSecondaryLanguage', hasSecondaryLanguage.toString());
  }
  setDisplayItemThumbnail(displayItemThumbnail: boolean) {
    localStorage.setItem('displayItemThumbnail', displayItemThumbnail.toString());
  }

  setAccountPortalBaseUrl(portalBaseUrl: string) {
    localStorage.setItem('portalBaseUrl', portalBaseUrl);
  }

  getAccountPortalBaseUrl(): string {
    return localStorage.getItem('portalBaseUrl');
  }

  setKnowledgeBaseToken(kbToken: string): void {
    localStorage.setItem(LocalStorageFlags.KnowledgeBaseToken, kbToken);
  }

  getKnowledgeBaseToken(): string {
    return localStorage.getItem(LocalStorageFlags.KnowledgeBaseToken);
  }

  hasKnowledgeBaseToken(): boolean {
    return stringIsDefinedAndNotEmpty(this.getKnowledgeBaseToken());
  }

  private clearKnowledgeBaseToken(): void {
    localStorage.removeItem(LocalStorageFlags.KnowledgeBaseToken);
  }

  getResellerLoginId() {
    if (localStorage.getItem('resellerLoginId')) {
      return JSON.parse(localStorage.getItem('resellerLoginId'));
    }
    return null;
  }

  getSecondaryLanguageConfig(): boolean {
    const secondaryLanguage = localStorage.getItem('hasSecondaryLanguage');
    return secondaryLanguage && secondaryLanguage == 'true' ? true : false;
  }

  getDisplayItemThumbnail(): boolean {
    const displayItemThumbnail = localStorage.getItem('displayItemThumbnail');
    return displayItemThumbnail && displayItemThumbnail == 'true' ? true : false;
  }

  setRefreshToken(token: string) {
    localStorage.setItem('refreshtoken', token);
  }

  setAuthToken(token: string) {
    localStorage.setItem('userToken', token);
  }

  setParentRefreshToken(token: string) {
    localStorage.setItem(LocalStorageFlags.parentRefreshToken, token);
  }

  setParentAuthToken(token: string) {
    localStorage.setItem(LocalStorageFlags.parentAuthToken, token);
  }

  setZYToken(token: string) {
    if (token) {
      const zyToken = this.jwtHelper.decodeToken(token);
      const checklistsToken = zyToken.zyToken;
      localStorage.setItem('zyToken', checklistsToken);
    }
  }

  getZYToken(): string {
    return localStorage.getItem('zyToken');
  }

  hasZYToken(): boolean {
    const zyToken = this.getZYToken();
    return stringIsDefinedAndNotEmpty(zyToken) && zyToken != '00000000-0000-0000-0000-000000000000';
  }

  setIsReseller(isReseller: boolean) {
    localStorage.setItem('isReseller', String(isReseller));
  }

  setIsPublisher(isPublisher: boolean) {
    localStorage.setItem('isPublisher', String(isPublisher));
  }

  setShortcutsConfiguration(shortcutsConfiguration: boolean) {
    localStorage.setItem('shortcutsConfiguration', String(shortcutsConfiguration));
  }

  // account company name
  setAccountCompanyNameInc(accountcompanynameinc: string): void {
    localStorage.setItem(LocalStorageFlags.AccountCompanyNameInc, accountcompanynameinc);
  }

  getAccountCompanyNameInc(): string {
    return this.getItemByKey(LocalStorageFlags.AccountCompanyNameInc);
  }

  setAccountTermsAndConditionsLink(temsLink: string): void {
    localStorage.setItem(LocalStorageFlags.AccountTermsAndConditionsLink, temsLink);
  }

  setAllowCustomCode(allowCustomCode: boolean = false) {
    localStorage.setItem(LocalStorageFlags.AllowCustomCode, allowCustomCode.toString());
  }
  getAllowCustomCode(): boolean {
    let allowCustomCode = localStorage.getItem(LocalStorageFlags.AllowCustomCode);
    return allowCustomCode && allowCustomCode === 'true' ? true : false;
  }

  getAccountTermsAndConditionsLink(): string {
    return this.getItemByKey(LocalStorageFlags.AccountTermsAndConditionsLink);
  }

  setAccountPrivacyPolicyLink(privacyLink: string): void {
    localStorage.setItem(LocalStorageFlags.AccountPrivacyPolicyLink, privacyLink);
  }

  getAccountPrivacyPolicyLink(): string {
    return this.getItemByKey(LocalStorageFlags.AccountPrivacyPolicyLink);
  }

  // supportId
  setSupportId(supportId: string): void {
    if (defined(supportId)) {
      localStorage.setItem(LocalStorageFlags.SupportId, supportId);
    }
  }

  getSupportId() {
    return this.getItemByKey(LocalStorageFlags.SupportId);
  }

  // company name
  setCompanyNameInc(companyNameInc: string) {
    localStorage.setItem('companynameinc', companyNameInc);
  }

  getCompanyNameInc() {
    return this.getItemByKey('companynameinc');
  }
  // facebook link
  setFaceBookLink(faceBookLink: string) {
    localStorage.setItem('facebooklink', faceBookLink);
  }
  getFaceBookLink() {
    return this.getItemByKey('facebooklink');
  }
  // linked in link
  setLinkedInLink(linkedInLink: string) {
    localStorage.setItem('linkedinlink', linkedInLink);
  }

  getLinkedInLink() {
    return this.getItemByKey('linkedinlink');
  }
  // twitter
  getTwitterLink() {
    return this.getItemByKey('twitterlink');
  }
  setTwitterLink(twitterLink: string) {
    localStorage.setItem('twitterlink', twitterLink);
  }
  // website
  setWebsiteLink(websiteLink: string) {
    localStorage.setItem('websitelink', websiteLink);
  }
  getWebsiteLink() {
    return this.getItemByKey('websitelink');
  }

  setPrivacyPolicyLink(privacyLink: string) {
    localStorage.setItem('privacy-policy-link', privacyLink);
  }

  getPrivacyPolicyLink() {
    return this.getItemByKey('privacy-policy-link');
  }

  setTermsAndConditionsLink(temsLink: string) {
    localStorage.setItem('terms-and-conditions-link', temsLink);
  }

  getCanSetPasswordsFlag(): boolean {
    const canSetPasswords = this.getItemByKey(LocalStorageFlags.CanSetPasswords);
    return canSetPasswords && canSetPasswords == 'true' ? true : false;
  }

  setCanSetPasswordsFlag(canSetPasswords: boolean): void {
    if (defined(canSetPasswords)) {
      localStorage.setItem(LocalStorageFlags.CanSetPasswords, canSetPasswords.toString());
    }
  }

  getImpersonatedCanSetPasswordsFlag(): boolean {
    const canSetPasswords = this.getItemByKey(LocalStorageFlags.ImpersonatedCanSetPasswords);
    return canSetPasswords && canSetPasswords == 'true' ? true : false;
  }

  setImpersonatedCanSetPasswordsFlag(canSetPasswords: boolean): void {
    if (defined(canSetPasswords)) {
      localStorage.setItem(LocalStorageFlags.ImpersonatedCanSetPasswords, canSetPasswords.toString());
    }
  }

  getTermsAndConditionsLink() {
    return this.getItemByKey('terms-and-conditions-link');
  }

  setTempActivationConfig(hasTempActivated: boolean) {
    if (hasTempActivated !== undefined && hasTempActivated !== null) {
      localStorage.setItem('hasTempActivated', hasTempActivated.toString());
    }
  }

  hasTempActivated(): boolean {
    let hasTempActivated = localStorage.getItem('hasTempActivated');
    return hasTempActivated && hasTempActivated == 'true' ? true : false;
  }

  hasAdvancedChecklistActivated(): boolean {
    let hasAdvancedChecklistActivated = localStorage.getItem('hasAdvancedChecklistActivated');
    return hasAdvancedChecklistActivated && hasAdvancedChecklistActivated == 'true' ? true : false;
  }

  setLabelingActivationConfig(hasLabelingActivated: boolean) {
    if (hasLabelingActivated !== undefined && hasLabelingActivated !== null) {
      localStorage.setItem(LocalStorageFlags.hasLabelingActivated, hasLabelingActivated.toString());
    }
  }

  hasLabelingActivated(): boolean {
    let hasLabelingActivated = localStorage.getItem(LocalStorageFlags.hasLabelingActivated);
    return hasLabelingActivated && hasLabelingActivated == 'true' ? true : false;
  }

  setInvoicesActivationConfig(hasInvoicesActivated: boolean) {
    if (defined(hasInvoicesActivated)) {
      localStorage.setItem('hasInvoicesActivated', hasInvoicesActivated.toString());
    }
  }

  hasInvoicesActivated(): boolean {
    let hasInvoicesActivated = localStorage.getItem('hasInvoicesActivated');
    return hasInvoicesActivated && hasInvoicesActivated == 'true' ? true : false;
  }

  setOrdersActivationConfig(hasOrdersActivated: boolean) {
    if (defined(hasOrdersActivated)) {
      localStorage.setItem('hasOrdersActivated', hasOrdersActivated.toString());
    }
  }

  hasOrdersActivated(): boolean {
    let hasOrdersActivated = localStorage.getItem('hasOrdersActivated');
    return hasOrdersActivated && hasOrdersActivated == 'true' ? true : false;
  }

  setOrderGuidesActivationConfig(hasOrderGuidesActivated: boolean) {
    if (defined(hasOrderGuidesActivated)) {
      localStorage.setItem('hasOrderGuidesActivated', hasOrderGuidesActivated.toString());
    }
  }

  hasOrderGuidesActivated(): boolean {
    let hasOrderGuidesActivated = localStorage.getItem('hasOrderGuidesActivated');
    return hasOrderGuidesActivated && hasOrderGuidesActivated == 'true' ? true : false;
  }

  setMessageCenterActivationConfig(hasMessageCenterActivated: boolean) {
    if (defined(hasMessageCenterActivated)) {
      localStorage.setItem('hasMessageCenterActivated', hasMessageCenterActivated.toString());
    }
  }

  hasMessageCenterActivated(): boolean {
    let hasMessageCenterActivated = localStorage.getItem('hasMessageCenterActivated');
    return hasMessageCenterActivated && hasMessageCenterActivated == 'true' ? true : false;
  }

  setMediaActivationConfig(hasMediaActivated: boolean) {
    if (hasMediaActivated !== undefined && hasMediaActivated !== null) {
      localStorage.setItem(LocalStorageFlags.hasMediaActivated, hasMediaActivated.toString());
    }
  }

  hasMediaActivated(): boolean {
    let hasMediaActivated = localStorage.getItem(LocalStorageFlags.hasMediaActivated);
    return hasMediaActivated && hasMediaActivated == 'true' ? true : false;
  }

  setTimersActivationConfig(hasTimersActivated: boolean) {
    if (hasTimersActivated !== undefined && hasTimersActivated !== null) {
      localStorage.setItem(LocalStorageFlags.hasTimersActivated, hasTimersActivated.toString());
    }
  }

  hasTimersActivated(): boolean {
    let hasTimersActivated = localStorage.getItem(LocalStorageFlags.hasTimersActivated);
    return hasTimersActivated && hasTimersActivated == 'true' ? true : false;
  }

  setLabelingAndMediaDeactivationButtonConfig(allowLabelingAndMediaDeactivation: boolean) {
    if (allowLabelingAndMediaDeactivation !== undefined && allowLabelingAndMediaDeactivation !== null) {
      localStorage.setItem('allowLabelingAndMediaDeactivation', allowLabelingAndMediaDeactivation.toString());
    }
  }

  allowLabelingAndMediaDeactivation(): boolean {
    let allowLabelingAndMediaDeactivation = localStorage.getItem('allowLabelingAndMediaDeactivation');
    return allowLabelingAndMediaDeactivation && allowLabelingAndMediaDeactivation == 'true' ? true : false;
  }

  setInitStepsConfig(initSteps: boolean) {
    if (initSteps !== undefined && initSteps !== null) {
      localStorage.setItem('initSteps', initSteps.toString());
    }
  }

  hasInitStepsDefined(): boolean {
    let initSteps = localStorage.getItem('initSteps');
    return booleanIsDefinedAndNotEmpty(initSteps);
  }

  hasInitSteps(): boolean {
    let initSteps = localStorage.getItem('initSteps');
    return initSteps && initSteps == 'true' ? true : false;
  }

  setTempConfActivationConfig(hasTempConfActivated: boolean) {
    if (hasTempConfActivated !== undefined && hasTempConfActivated !== null) {
      localStorage.setItem(LocalStorageFlags.hasTempConfActivated, hasTempConfActivated.toString());
    }
  }

  setSenseConfActivationConfig(hasSenseConfActivated: boolean) {
    if (hasSenseConfActivated !== undefined && hasSenseConfActivated !== null) {
      localStorage.setItem(LocalStorageFlags.hasSenseConfActivated, hasSenseConfActivated.toString());
    }
  }

  hasMultiStoreCodeActivated(): boolean {
    let hasMultiStoreCodeActivated = localStorage.getItem(LocalStorageFlags.hasMultiStoreCodeActivated);
    return hasMultiStoreCodeActivated && hasMultiStoreCodeActivated == 'true' ? true : false;
  }

  setLocalKey(value: boolean, property: string) {
    if (value !== undefined && value !== null) {
      localStorage.setItem(property, value.toString());
    }
  }
  hasLocalKeyActivated(property): boolean {
    const localKeyActivated = localStorage.getItem(property);
    return localKeyActivated && localKeyActivated === 'true' ? true : false;
  }

  setFixedReadersConfig(value: boolean, property: string) {
    if (value !== undefined && value !== null) {
      localStorage.setItem(property, value.toString());
    }
  }

  hasTempConfActivated(): boolean {
    let hasTempConfActivated = localStorage.getItem(LocalStorageFlags.hasTempConfActivated);
    return hasTempConfActivated && hasTempConfActivated == 'true' ? true : false;
  }

  hasSenseConfActivated(): boolean {
    let hasSenseConfActivated = localStorage.getItem(LocalStorageFlags.hasSenseConfActivated);
    return hasSenseConfActivated && hasSenseConfActivated == 'true' ? true : false;
  }

  hasAddOnsActivated(): boolean {
    let hasAddons = localStorage.getItem(LocalStorageFlags.hasAddons);
    return hasAddons && hasAddons == 'true' ? true : false;
  }

  setResellerTempConfActivationConfig(hasTempConfActivated: boolean) {
    if (hasTempConfActivated !== undefined && hasTempConfActivated !== null) {
      localStorage.setItem('hasResellerTempConfActivated', hasTempConfActivated.toString());
    }
  }

  hasResellerTempConfActivated(): boolean {
    let hasTempConfActivated = localStorage.getItem('hasResellerTempConfActivated');
    return hasTempConfActivated && hasTempConfActivated == 'true' ? true : false;
  }

  setSenseActivationConfig(hasSenseActivated: boolean) {
    if (hasSenseActivated !== undefined && hasSenseActivated !== null) {
      localStorage.setItem('hasSenseActivated', hasSenseActivated.toString());
    }
  }

  hasSenseActivated(): boolean {
    let hasSenseActivated = localStorage.getItem('hasSenseActivated');
    return hasSenseActivated && hasSenseActivated == 'true' ? true : false;
  }

  setChecklistActivationConfig(hasChecklistActivated: boolean) {
    if (hasChecklistActivated !== undefined && hasChecklistActivated !== null) {
      localStorage.setItem(LocalStorageFlags.hasChecklistActivated, hasChecklistActivated.toString());
    }
  }

  hasChecklistActivated(): boolean {
    let hasChecklistActivated = localStorage.getItem(LocalStorageFlags.hasChecklistActivated);
    return hasChecklistActivated && hasChecklistActivated == 'true' ? true : false;
  }

  setChecklistReportsActivated(hasChecklistReportsActivated: boolean): void {
    if (defined(hasChecklistReportsActivated)) {
      localStorage.setItem('hasChecklistReportsActivated', hasChecklistReportsActivated.toString());
    }
  }
  setAccountScaleUoMId(accountScaleUoMId: number): void {
    if (defined(accountScaleUoMId)) {
      localStorage.setItem('accountScaleUoMId', accountScaleUoMId.toString());
    }
  }
  getAccountScaleUoMId(): number {
    if (localStorage.getItem('accountScaleUoMId')) {
      return JSON.parse(localStorage.getItem('accountScaleUoMId'));
    }
    return null;
  }

  setAccountTenantTypeId(tenantTypeId: number): void {
    if (defined(tenantTypeId)) {
      localStorage.setItem('accountTenantTypeId', tenantTypeId.toString());
    }
    return null;
  }
  getAccountTenantTypeId(): number {
    if (localStorage.getItem('accountTenantTypeId')) {
      return JSON.parse(localStorage.getItem('accountTenantTypeId'));
    }
    return null;
  }

  hasChecklistReportsActivated(): boolean {
    let hasChecklistReportsActivated = localStorage.getItem('hasChecklistReportsActivated');
    return hasChecklistReportsActivated && hasChecklistReportsActivated == 'true' ? true : false;
  }

  setSubLocationConfig(hasSubLocation: boolean) {
    if (hasSubLocation !== undefined && hasSubLocation !== null) {
      localStorage.setItem('hasSubLocation', hasSubLocation.toString());
    }
  }

  hasSubLocation(): boolean {
    let hasSubLocation = localStorage.getItem('hasSubLocation');
    return hasSubLocation && hasSubLocation == 'true' ? true : false;
  }

  getIsPublisher() {
    if (localStorage.getItem('isPublisher')) {
      return JSON.parse(localStorage.getItem('isPublisher'));
    }
    return null;
  }

  isPunchoutSession(): boolean {
    if (localStorage.getItem(LocalStorageFlags.punchoutSession)) {
      return JSON.parse(localStorage.getItem(LocalStorageFlags.punchoutSession).toLowerCase());
    }
    return false;
  }

  setPunchoutSession(punchoutSession: boolean): void {
    localStorage.setItem(LocalStorageFlags.punchoutSession, String(punchoutSession));
  }

  getPunchOutMode(): PunchOutModeEnum {
    const punchOutMode = localStorage.getItem(LocalStorageFlags.punchOutMode) as PunchOutModeEnum;
    return punchOutMode;
  }

  setPunchOutMode(punchOutMode: PunchOutModeEnum): void {
    localStorage.setItem(LocalStorageFlags.punchOutMode, punchOutMode);
  }

  removePunchOutMode(): void {
    localStorage.removeItem(LocalStorageFlags.punchOutMode);
  }

  getPunchOutRequestId(): string {
    return localStorage.getItem(LocalStorageFlags.punchOutRequestId);
  }

  setPunchOutRequestId(requestId: string): void {
    localStorage.setItem(LocalStorageFlags.punchOutRequestId, requestId);
  }

  removePunchOutRequestId(): void {
    localStorage.removeItem(LocalStorageFlags.punchOutRequestId);
  }

  getShortcutsConfiguration() {
    if (localStorage.getItem('shortcutsConfiguration')) {
      return JSON.parse(localStorage.getItem('shortcutsConfiguration'));
    }
    return null;
  }

  getIsReseller() {
    const impersonationMode = this.getImpersonationMode();
    const resellerImpersonationMode = this.getResellerImpersonationMode();

    if (impersonationMode !== null && resellerImpersonationMode == null) {
      return null;
    }

    if (localStorage.getItem('isReseller')) {
      return JSON.parse(localStorage.getItem('isReseller'));
    }
    return null;
  }

  removeAuthToken() {
    localStorage.removeItem('userToken');
  }

  getAuthToken() {
    if (localStorage.getItem('userToken')) {
      return localStorage.getItem('userToken');
    }
    return null;
  }

  getResellerId(): number {
    return JSON.parse(localStorage.getItem(LocalStorageFlags.resellerId));
  }

  getRefreshToken() {
    if (localStorage.getItem('refreshtoken')) {
      return localStorage.getItem('refreshtoken');
    }
    return null;
  }

  getParentAuthToken() {
    if (localStorage.getItem(LocalStorageFlags.parentAuthToken)) {
      return localStorage.getItem(LocalStorageFlags.parentAuthToken);
    }
    return null;
  }

  getParentRefreshToken() {
    if (localStorage.getItem(LocalStorageFlags.parentRefreshToken)) {
      return localStorage.getItem(LocalStorageFlags.parentRefreshToken);
    }
    return null;
  }

  removeParentRefreshToken() {
    localStorage.removeItem(LocalStorageFlags.parentRefreshToken);
  }

  removeParentAuthToken() {
    localStorage.removeItem(LocalStorageFlags.parentAuthToken);
  }

  clearParentTokens() {
    this.removeParentRefreshToken();
    this.removeParentAuthToken();
  }

  setCurrentUser(firstname: string, lastname: string) {
    localStorage.setItem('currentUser', `${firstname} ${lastname}`);
  }

  getCurrentUser(): string {
    if (localStorage.getItem('currentUser')) {
      return localStorage.getItem('currentUser');
    }
    return null;
  }

  setTenantName(tenant: string) {
    localStorage.setItem('tenantName', `${tenant}`);
  }

  getTenantName() {
    const impersonationMode = this.getImpersonationMode();
    const resellerImpersonationMode = this.getResellerImpersonationMode();

    if (impersonationMode !== null) {
      return impersonationMode.accountName;
    }

    if (resellerImpersonationMode !== null) {
      return resellerImpersonationMode.accountName;
    }

    if (localStorage.getItem('tenantName')) {
      return localStorage.getItem('tenantName');
    }

    return '';
  }

  impersonateAccount(accountId: number, accountName: string, accountCode: string) {
    let parentAccountId = this.getCurrentUserAccountId(true);
    let parentUserId = this.getCurrentUserEmail();
    this.setImpersonationMode(accountId, accountName, accountCode, parentAccountId, parentUserId);
  }

  impersonateReseller(accountId: number, accountName: string, accountCode: string, modules?: any) {
    let parentCode = this.getTenantCode();
    this.setResellerImpersonationMode(accountId, accountName, accountCode, modules, parentCode);
  }

  exitImpersonationMode() {
    this.clearImpersonationMode();
  }

  exitResellerImpersonationMode() {
    this.clearResellerImpersonationMode();
  }

  storeResellerImpersonationModules(modules: any) {
    const impersonationMode = this.getResellerImpersonationMode();
    if (impersonationMode !== null) {
      this.setResellerImpersonationMode(impersonationMode.accountId, impersonationMode.accountName, impersonationMode.accountCode, modules, impersonationMode.parentCode);
      this.impersonationMode.next(true);
    }
  }

  toggleImpersonationMode(value: boolean) {
    this.impersonationMode.next(value);
  }

  setResellerImpersonationMode(accountId: number, accountName: string, accountCode: string, modules?: any, parentCode?: string) {
    const value = JSON.stringify({
      accountId: accountId,
      accountName: accountName,
      accountCode: accountCode,
      modules: modules,
      parentCode: parentCode
    });
    localStorage.setItem('resellerImpersonationMode', value);
    this.setIsReseller(true);
  }

  setImpersonationMode(accountId: number, accountName: string, accountCode: string, parentAccountId?: number, parentUserId?: string) {
    const value = JSON.stringify({
      accountId: accountId,
      accountName: accountName,
      accountCode: accountCode,
      impersonatorAccountId: parentAccountId,
      impersonatorUserId: parentUserId
    });
    localStorage.setItem('impersonationMode', value);
    this.setIsReseller(false);
  }

  getResellerImpersonationMode() {
    return localStorage.getItem('resellerImpersonationMode') !== null ? JSON.parse(localStorage.getItem('resellerImpersonationMode')) : null;
  }

  getImpersonationMode() {
    return localStorage.getItem('impersonationMode') !== null ? JSON.parse(localStorage.getItem('impersonationMode')) : null;
  }

  getModules(): TokenModule[] {
    const resellerImpersonationModeData = this.getResellerImpersonationMode();

    if (defined(resellerImpersonationModeData)) {
      return resellerImpersonationModeData.modules;
    } else {
      return this.getCurrentUserModules();
    }
  }

  clearResellerImpersonationMode() {
    localStorage.removeItem('resellerImpersonationMode');
    this.setIsReseller(true);
    this.setTempActivationConfig(false);
    this.setSenseActivationConfig(false);
    this.setSenseConfActivationConfig(false);
    this.setTempConfActivationConfig(false);
    this.setChecklistActivationConfig(false);
    this.setChecklistReportsActivated(false);
    this.setRecipeManagementSupportedFlagInLocalStorage(false);
    this.setTimersActivationConfig(false);
    this.setLocalKey(false, LocalStorageFlags.hasMultiStoreCodeActivated);
    this.setLocalKey(false, LocalStorageFlags.hasAddons);
    localStorage.removeItem(LocalStorageFlags.ImpersonatedCanSetPasswords);
    setTempUoMId(+this.getResellerUomId());
    localStorage.removeItem('zyToken');
    this.clearKnowledgeBaseToken();
    this.impersonationMode.next(false);
  }

  clearImpersonationMode() {
    localStorage.removeItem('impersonationMode');
    this.setIsReseller(true);
    this.setLabelingActivationConfig(true);
    this.setTempActivationConfig(false);
    this.setSenseActivationConfig(false);
    this.setSenseConfActivationConfig(false);
    this.setTempConfActivationConfig(false);
    this.setChecklistActivationConfig(false);
    this.setChecklistReportsActivated(false);
    this.setInvoicesActivationConfig(false);
    this.setOrderGuidesActivationConfig(false);
    this.setMessageCenterActivationConfig(false);
    this.setOrdersActivationConfig(false);
    this.setRecipeManagementSupportedFlagInLocalStorage(false);
    this.setHasAdvancedChecklistActivatedFlagInLocalStorage(false);
    this.setTimersActivationConfig(false);
    this.setLocalKey(false, LocalStorageFlags.hasMultiStoreCodeActivated);
    this.setLocalKey(false, LocalStorageFlags.hasAddons);
    this.setLocalKey(false, LocalStorageFlags.hasDistributionCentersActivated);
    this.setLocalKey(false, LocalStorageFlags.hasDistributionUsersActivated);
    this.setLocalKey(false, LocalStorageFlags.hasOrderManagementActivated);
    this.setLocalKey(false, LocalStorageFlags.HasAutomaticUserMLCGeneration);
    this.setLocalKey(false, LocalStorageFlags.hasInventoryReportingActivated);
    this.setDailyProductionFlag(false);
    this.setDisplayItemThumbnail(false);
    setTempUoMId(+this.getResellerUomId());
    UserPreferenceEnum;
    for (const value in UserPreferenceEnum) {
      localStorage.removeItem(value);
    }
    localStorage.removeItem('zyToken');
    localStorage.removeItem('accountScaleUoMId');
    localStorage.removeItem(LocalStorageFlags.ImpersonatedCanSetPasswords);
    localStorage.removeItem(LocalStorageFlags.CurrentBrand);
    localStorage.removeItem(LocalStorageFlags.SupportId);
    this.clearKnowledgeBaseToken();
    this.setHasDualNutrionalPanelsInLocalStorage(false);
  }

  getCurrentUserAccountId(forceActualAccount?: boolean): number {
    let token: string;
    if (forceActualAccount) {
      // parent if impersonating or current if not impersonating
      token = defined(this.getParentAuthToken()) ? this.getParentAuthToken() : this.getAuthToken();
    } else {
      const resellerImpersonationMode = this.getResellerImpersonationMode();
      if (resellerImpersonationMode !== null) {
        return resellerImpersonationMode.accountId;
      }
      token = this.getAuthToken();
    }
    return parseInt(jwt_decode(token).accountId, 10);
  }

  getParentUserAccountId(): number {
    const parentToken = this.getParentAuthToken();
    if (!parentToken) return;

    const tokenDecoded = jwt_decode(parentToken);
    return parseInt(tokenDecoded.accountId, 10);
  }

  getCurrentUserEmail(): string {
    const token = this.getAuthToken();
    if (!token) return;

    const tokenDecoded = jwt_decode(token);
    return tokenDecoded['userName'];
  }

  setCurrentUserModules(modules: TokenModule[]) {
    const modulesStr = JSON.stringify(modules);
    localStorage.setItem('modules', modulesStr);
  }

  getCurrentUserModules(): TokenModule[] {
    const modules = localStorage.getItem('modules') ? JSON.parse(localStorage.getItem('modules')) : null;
    if (modules) return modules;
    /* Log out the user the modules are missing */
    this.logout();
  }

  getUserId(): number {
    const token = this.getAuthToken();
    if (!token) return;

    let tokenDecoded = jwt_decode(token);
    return parseInt(tokenDecoded.userId, 10);
  }

  clearLocalStorage() {
    //Clear local storage keys while skipping the below array of keys
    let keysToSkip: string[] = [
      'help',
      'appVersion',
      'initSteps',
      'defaultLang',
      LocalStorageFlags.resellerLoginId,
      LocalStorageFlags.authenticateUsingKeycloakOIDC,
      LocalStorageFlags.resellerSubRoute,
      LocalStorageFlags.resellerAlias,
      LocalStorageFlags.identityServerURL,
      LocalStorageFlags.supportChatEnabled
    ];

    //Clearing local storage
    Object.entries(localStorage).forEach(([key, val]) => {
      if (!keysToSkip.includes(key)) delete localStorage[key];
    });
  }

  //   clearData() {
  //     // remove user from local storage to log user out
  //     this.clearLocalStorage();
  //     this.navigationService.clearPath();
  //     this.brandService.clearBrand();
  //     this.nomeBreadcrumbsService.clearBreadcrumbs();
  // }

  logout(name?: string, preservedURL?: string, redirectWithHref: boolean = false): void {
    if (!this.getAuthenticateUsingKeycloakOIDC()) {
      //Check if we have an available token first
      const token = this.getAuthToken();
      if (token) {
        forkJoin([this.deleteRefreshToken(), from(this.getResellerConfigData())]).subscribe(([deleteRes, resellerConfig]) => {
          this.clearStorageAndFirstLoad();
          onGetSubroute(resellerConfig);
          this.addOrRemoveTheSupportChat();

          //Now we need to redirect the user to the login page
          if (redirectWithHref) location.href = '/login/account';
          else this.redirectToAccountSelection(name, preservedURL);
        });
      } else {
        from(this.getResellerConfigData()).subscribe((resellerConfig) => {
          this.clearStorageAndFirstLoad();
          onGetSubroute(resellerConfig);
          this.addOrRemoveTheSupportChat();

          //Now we need to redirect the user to the login page
          if (redirectWithHref) location.href = '/login/account';
          else this.redirectToAccountSelection(name, preservedURL);
        });
      }
    } else {
      this.keycloakService.isLoggedIn().then((isLoggedIn) => {
        //this.clearStorageAndFirstLoad();
        if (!isLoggedIn) {
          this.redirectToEmailValidation();
        } else {
          this.keycloakService.logout();
        }
      });
    }
  }

  clearStorageAndFirstLoad(): void {
    this.clearLocalStorage();
    this.tokenBeingRefreshed.next(false);
    this.firstload = true;
  }

  redirectToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  redirectToAccountSelection(name?: string, preservedURL?: string) {
    this.router.navigate(['/login/account'], {});
  }
  redirectToEmailValidation() {
    this.router.navigate(['/login/email'], {});
  }

  redirectToLogin(name?: string, preservedURL?: string) {
    // this.clearLocalStorage();
    // this.tokenBeingRefreshed.next(false);
    // this.firstload = true;
    var queryParams = Object.assign({}, name ? { accountInfo: name } : {});
    if (definedAndNotEmptyString(preservedURL)) {
      const pair = { url: preservedURL };
      queryParams = { ...queryParams, ...pair };
    }
    this.router.navigate(['/login'], { queryParams });
  }

  openLoginWarningDialog() {
    const resellerName = this.getResellerName();
    if (!this.loginWarningDialog.value) {
      this.loginWarningDialog.next(true);
      this.dialog
        .open(LoginWarningDialogComponent, {
          data: { resellerTenantName: resellerName },
          disableClose: true,
          panelClass: 'update-app-dialog'
        })
        .afterClosed()
        .subscribe((ok) => {
          this.loginWarningDialog.next(false);
        });
    }
  }

  redirectToLoginFromActivate(name?: string, preservedURL?: string) {
    var queryParams = Object.assign({}, name ? { account: btoa(name) } : {});
    if (definedAndNotEmptyString(preservedURL)) {
      const pair = { url: preservedURL };
      queryParams = { ...queryParams, ...pair };
    }
    this.router.navigate(['/login'], { queryParams });
  }

  redirectToUnAuthorized() {
    this.router.navigate(['/unauthorized']);
  }

  redirectToPageNotFound() {
    this.router.navigate(['/pagenotfound']);
  }

  redirectToFeatureRequiresPhysicalLocation() {
    this.router.navigate(['/featurequiresphysicallocation']);
  }

  redirectToInvalidLink(errorCode: number) {
    this.router.navigate(['/invalidlink'], { queryParams: { reason: btoa(errorCode.toString()) } });
  }
  isTokenGood(): boolean {
    const token = this.getAuthToken();
    if (!token && this.firstload) {
      return true;
    }

    const impersonationMode = this.getImpersonationMode();
    if (impersonationMode || !this.getAuthenticateUsingKeycloakOIDC()) {
      if (token && !this.firstload) {
        if (this.jwtHelper.isTokenExpired(token)) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      let isTokenExpired = true;
      if (token && !this.firstload) {
        isTokenExpired = this.jwtHelper.isTokenExpired(token);
      }
      return !isTokenExpired && !this.keycloakService.isTokenExpired();
    }
  }

  isTokenExpired(): boolean {
    const token = this.getAuthToken();
    const impersonationMode = this.getImpersonationMode();
    if (impersonationMode || !this.getAuthenticateUsingKeycloakOIDC()) {
      if (this.jwtHelper.isTokenExpired(token)) {
        return true;
      } else {
        return false;
      }
    } else {
      let isTokenExpired = this.jwtHelper.isTokenExpired(token);
      return isTokenExpired || this.keycloakService.isTokenExpired();
    }
  }

  setNewToken(results: any) {
    this.setAuthToken(results.token);
    this.setRefreshToken(results.refreshToken);
    this.tokenBeingRefreshed.next(false);
  }

  refreshToken(): Observable<APIResponseModel<any>> {
    if (this.tokenBeingRefreshed.value) {
      return this.refreshTokenSubject.asObservable();
    }

    return this.getNewRefreshToken();
  }

  refreshKeycloakToken(): Observable<APIResponseModel<any>> {
    if (!this.keycloakTokenBeingRefreshed.value) {
      this.keycloakTokenBeingRefreshed.next(true);
      return from(this.keycloakService.updateToken())
        .pipe(
          switchMap((refreshed) => {
            this.keycloakTokenBeingRefreshed.next(false);
            if (refreshed) {
              return from(this.keycloakService.getToken()).pipe(
                switchMap((token) => {
                  const impersonationMode = this.getImpersonationMode();
                  if (impersonationMode) {
                    // if it's impersonation mode, set the new token for the parent (reseller) which was authenticated in keycloak
                    this.setParentAuthToken(token);
                  } else {
                    this.setAuthToken(token);
                  }

                  this.keycloakTokenBeingRefreshed.next(false);
                  return new Observable<APIResponseModel<any>>((observer) => {
                    observer.next({ message: '', status: '202', statusCode: APIHttpResponseCode.accepted, results: { token } });
                  });
                })
              );
            } else {
              // token could not be refreshed, max idle session timeout reached or max session timeout reached. User will be logged out
              return new Observable<APIResponseModel<any>>((observer) => {
                observer.next({ message: '', status: '400', statusCode: APIHttpResponseCode.badrequest, results: null });
              });
            }
          })
        )
        .pipe(
          catchError((error) => {
            // token could not be refreshed, max idle session timeout reached or max session timeout reached. User will be logged out
            return new Observable<APIResponseModel<any>>((observer) => {
              observer.next({ message: '', status: '400', statusCode: APIHttpResponseCode.badrequest, results: null });
            });
          })
        );
    }
  }

  getNewRefreshToken(): Observable<APIResponseModel<any>> | any {
    const url = `${environment.baseAPIUrl}/${environment.version}/login/token/refresh`;
    const body = { refreshToken: `${this.getRefreshToken()}` };

    this.loaderService.showLoader();
    if (!this.tokenBeingRefreshed.value) {
      this.tokenBeingRefreshed.next(true);
      return this.httpClient
        .post(url, body)
        .pipe(
          map((response: APIResponseModel<any>) => {
            this.tokenBeingRefreshed.next(false);
            // save the response for other subscribers while the token is being refreshed...
            this.setNewToken(response.results);
            this.loaderService.hideLoader();
            this.refreshTokenSubject.next(response);
            this.refreshTokenSubject.complete();
            return response;
          })
        )
        .pipe(
          catchError((error) => {
            if (this.bottomSheet) {
              this.bottomSheet.dismiss();
            }
            if (this.dialog) {
              this.dialog.closeAll();
            }

            this.logout();
            throw error;
          })
        );
    }
  }

  deleteRefreshToken() {
    const url = `${environment.baseAPIUrl}/${environment.version}/logout`;
    const body = {
      refreshToken: `${this.getRefreshToken()}`
    };
    return this.httpClient.delete(url, { headers: body }).pipe(map((response: APIResponseModel<any>) => response.results));
  }

  isModuleVisible(moduleName: string): boolean {
    const currentModules = this.getCurrentUserModules();
    return currentModules.find((_module) => _module.Name.toLowerCase() === moduleName.toLowerCase())?.IsVisible;
  }

  /**
   *
   * @param moduleName the name of the module to check weither the user has permissions
   * @param permissionId the permission id, based on permissions.enum.ts
   */
  hasPermission(moduleName: string, permissionId: number, checkVisibility?: boolean, currentUserPermissionsUsed?: boolean): boolean {
    // check which are the modules to be loaded, if the user is in impersonation mode, we should be checikng the impersonated mode modules
    // not the ones we get from the login token.
    // therefore we ensure checking the right modules when checking for permissions.
    let currentModules = [];
    if (currentUserPermissionsUsed) {
      currentModules = this.getCurrentUserModules();
    } else {
      currentModules = this.getResellerImpersonationMode() ? this.getResellerImpersonationMode().modules : this.getCurrentUserModules();
    }
    const desiredModule = currentModules.find((_module) => _module.Name.toLowerCase() === moduleName.toLowerCase() && ((checkVisibility && _module.IsVisible) || !checkVisibility));
    let hasRequiredPermission: boolean;
    if (desiredModule && desiredModule.Permissions) {
      hasRequiredPermission = desiredModule.Permissions.indexOf(permissionId) !== -1;
    } else {
      hasRequiredPermission = false;
    }

    // Leaving this code to be able to easily debug permissions.
    // const myString = hasRequiredPermission ? 'HAS the required' : 'DOES NOT HAVE the required';
    // let permissionString = '';
    // switch (permissionId) {
    //   case Permissions.ADD:
    //     permissionString = 'Add';
    //     break;
    //   case Permissions.DELETE:
    //     permissionString = 'Delete';
    //     break;
    //   case Permissions.EDIT:
    //     permissionString = 'Edit';
    //     break;
    //   case Permissions.IMPORT:
    //     permissionString = 'Import';
    //     break;
    //   case Permissions.VIEW:
    //     permissionString = 'View';
    //     break;
    // }
    // console.log(
    //   `%c Button Role is to (${permissionId}) ${permissionString} ${moduleName} and this user
    // ${myString} permission.`, 'background: #222; color: #bada55');
    return hasRequiredPermission;
  }

  getPermissions(moduleName: string) {
    const currentModules = this.getResellerImpersonationMode() ? this.getResellerImpersonationMode().modules : this.getCurrentUserModules();
    const desiredModule = currentModules.find((_module) => _module.Name.toLowerCase() === moduleName.toLowerCase());
    return desiredModule ? desiredModule.Permissions : null;
  }

  doesAccountSupportSecondLanguage(): boolean {
    return this.getSecondaryLanguageConfig();
  }

  _showUpdateAppDialog(force?: boolean) {
    const appVersion = this.getCurrentStoredAppVersion();
    if (!defined(appVersion)) {
      localStorage.setItem('appVersion', this.apiAppVersion);
      this.postponeRequest = false;
      this.appRequiresUpdate = false;
      this.appRequiresLoginAfterUpdate = false;
    } else if (this.isTokenGood()) {
      if ((appVersion && appVersion == this.apiAppVersion) || !this.apiAppVersion) {
        return;
      }
      if (this.postponeRequest && this.requests < 10) {
        this.requests++;
      } else {
        this.requests = 0;
        this.postponeRequest = false;
      }
      if (this.dialogOpen) {
        return;
      }
      if ((!this.postponeRequest || (this.postponeRequest && force && this.apiAppVersion)) && !this.router.url.includes('releasemanager/release-notes-template')) {
        this.updateAppDialog = this.dialog.open(UpdateAppComponent, {
          data: {
            appVersion: this.apiAppVersion,
            accountId: this.getCurrentUserAccountId(),
            isReseller: this.getIsReseller(),
            showReleaseNotes: this.getAuthToken()
          },
          disableClose: true,
          panelClass: 'update-app-dialog'
        });
        this.dialogOpen = true;
      }
      this.updateAppDialog.afterClosed().subscribe((result: boolean) => {
        this.dialogOpen = false;
        if (result === true) {
          // update NOW
          this.appRequiresUpdate = false;
          localStorage.setItem('appVersion', this.apiAppVersion);
          if (this.appRequiresLoginAfterUpdate) this.logout(null, null, true);
          //location.href = '/login';
          // redirect to login screen
          else location.reload(); // true is to force reload(clear cache)
        } else if (result === false) {
          // update later
          this.postponeRequest = true;
          this.appRequiresUpdate = true;
        }
      });
    }
  }

  shouldShowCookieConsentDialog() {
    return this.getAuthenticateUsingKeycloakOIDC() && this.cookieService.getCookie(CookiesFlags.cookieConsent) !== CookiesValues.allow;
  }

  showCookieConsentDialog() {
    return this.dialog
      .open(CookieConsentComponent, {
        data: {},
        disableClose: true,
        panelClass: 'cookie-consent-dialog'
      })
      .afterClosed();
  }

  getCurrentStoredAppVersion() {
    return localStorage.getItem('appVersion');
  }

  setTenantCode(code: string) {
    localStorage.setItem('tenantCode', code);
  }

  getTenantCode() {
    const impersonationMode = this.getImpersonationMode();
    const resellerImpersonationMode = this.getResellerImpersonationMode();

    if (impersonationMode !== null) {
      return impersonationMode.accountCode;
    }

    if (resellerImpersonationMode !== null) {
      return resellerImpersonationMode.accountCode;
    }

    if (localStorage.getItem('tenantCode')) {
      return localStorage.getItem('tenantCode');
    }

    return '';
  }

  setSelectedBrand(brand: BrandModel) {
    this.selectedBrand = brand;
  }

  getSelectedBrand(): BrandModel {
    return this.selectedBrand;
  }

  getItemByKey(key: string) {
    if (localStorage.getItem(key)) {
      return localStorage.getItem(key);
    }
    return null;
  }

  getBooleanItemByKey(key: string): boolean {
    return this.getItemByKey(key) === 'true';
  }

  getNumberOfColumnsOfNutritionalFact(): number {
    var columnsNumber = this.getItemByKey(LocalStorageFlags.nutritionalPanelCount);
    return defined(columnsNumber) ? parseInt(columnsNumber) : 1;
  }

  setRecipeManagementSupportedFlagInLocalStorage(supported: boolean): void {
    localStorage.setItem(LocalStorageFlags.RecipeManagementSupported, `${supported}`);
  }

  isRecipeManagementSupported(): boolean {
    return localStorage.getItem(LocalStorageFlags.RecipeManagementSupported) === 'true';
  }

  setHasAdvancedChecklistActivatedFlagInLocalStorage(activated: boolean): void {
    localStorage.setItem(LocalStorageFlags.HasAdvancedChecklistActivated, `${activated}`);
  }

  isAdvancedChecklistActivated(): boolean {
    return localStorage.getItem(LocalStorageFlags.HasAdvancedChecklistActivated) === 'true';
  }

  getResellerAllowedBusinessTypes(): number[] {
    const serializedBusinessTypes = this.getItemByKey('resellerAllowedBusinessTypes');
    if (serializedBusinessTypes) {
      return JSON.parse(serializedBusinessTypes);
    } else {
      return [];
    }
  }

  setResellerAllowedBusinessTypes(businessTypes: ResellerTenantType[]): void {
    // Serialize the list to JSON
    const serializedBusinessTypes = JSON.stringify(businessTypes.map((type) => type.tenantTypeId));
    localStorage.setItem('resellerAllowedBusinessTypes', serializedBusinessTypes);
  }

  setResellerUomId(resellerUomId): void {
    localStorage.setItem(`resellerUoMId`, `${resellerUomId}`);
  }
  getResellerUomId(): string {
    return this.getItemByKey(`resellerUoMId`);
  }

  setAllowRefreshLocationCode(flagStatus: boolean): void {
    localStorage.setItem('allowRefreshLocationCode', `${flagStatus.toString()}`);
  }
  setWorkflowPermissions(flagStatus: boolean): void {
    localStorage.setItem('hasWorkflowActivated', `${flagStatus.toString()}`);
  }
  setHasPriceOptions(flagStatus: boolean): void {
    localStorage.setItem('hasPriceOptions', `${flagStatus.toString()}`);
  }
  setHasMultiLabeling(flagStatus: boolean): void {
    localStorage.setItem('hasMultiLabeling', `${flagStatus.toString()}`);
  }
  setHasExternalAuthentication(flagStatus: boolean): void {
    localStorage.setItem('hasExternalAuthentication', `${flagStatus.toString()}`);
  }
  setHasExternalInterface(flagStatus: boolean) {
    localStorage.setItem(LocalStorageFlags.HasExternalInterface, `${flagStatus.toString()}`);
  }
  setHasManualPriceEntry(flagStatus: boolean): void {
    localStorage.setItem('hasManualPriceEntry', `${flagStatus.toString()}`);
  }
  getAllowRefreshLocationCode(): boolean {
    if (localStorage.getItem('allowRefreshLocationCode')) {
      return JSON.parse(localStorage.getItem('allowRefreshLocationCode'));
    }
    return false;
  }
  getWorkflowPermissions(): boolean {
    if (localStorage.getItem('hasWorkflowActivated')) {
      return JSON.parse(localStorage.getItem('hasWorkflowActivated'));
    }
    return false;
  }
  getHasPriceOptions(): boolean {
    if (localStorage.getItem('hasPriceOptions')) {
      return JSON.parse(localStorage.getItem('hasPriceOptions'));
    }
    return false;
  }
  getHasMultiLabeling(): boolean {
    if (localStorage.getItem('hasMultiLabeling')) {
      return JSON.parse(localStorage.getItem('hasMultiLabeling'));
    }
    return false;
  }
  getHasManualPriceEntry(): boolean {
    if (localStorage.getItem('hasManualPriceEntry')) {
      return JSON.parse(localStorage.getItem('hasManualPriceEntry'));
    }
    return false;
  }
  hasModulePermission(module: string, permission: number) {
    const modules = this.getCurrentUserModules();
    const findPermisison = modules?.find((m) => m.Name.toLowerCase() == module.toLocaleLowerCase())?.Permissions?.indexOf(permission);
    if (findPermisison && findPermisison > -1) return true;
    return false;
  }

  setDailyProductionFlag(hasDailyProduction: boolean): void {
    if (defined(hasDailyProduction)) {
      localStorage.setItem(tenantModuleOptionsGroup.get(TenantModuleOptions.DAILY_PRODUCTION), hasDailyProduction.toString());
    }
  }

  hasDailyProductionFlagEnabled(): boolean {
    return localStorage.getItem(tenantModuleOptionsGroup.get(TenantModuleOptions.DAILY_PRODUCTION)) === 'true';
  }
  setKnowledgeBaseFlag(knowledgeBaseEnabled: boolean) {
    localStorage.setItem(LocalStorageFlags.KnowledgeBaseEnabled, knowledgeBaseEnabled.toString());
  }
  getKnowledgeBaseFlag(): boolean {
    if (localStorage.getItem(LocalStorageFlags.KnowledgeBaseEnabled)) {
      return JSON.parse(localStorage.getItem(LocalStorageFlags.KnowledgeBaseEnabled));
    }
    return false;
  }
  setKnowledgeBaseURL(knowledgeBaseUrl: string) {
    localStorage.setItem(LocalStorageFlags.KnowledgeBaseURL, knowledgeBaseUrl);
  }
  getKnowledgeBaseUrl() {
    return localStorage.getItem(LocalStorageFlags.KnowledgeBaseURL);
  }
  setSupportChatFlag(supportChatEnabled: boolean) {
    localStorage.setItem(LocalStorageFlags.supportChatEnabled, supportChatEnabled.toString());
  }
  getSupportChatFlag(): boolean {
    if (localStorage.getItem(LocalStorageFlags.supportChatEnabled)) {
      return JSON.parse(localStorage.getItem(LocalStorageFlags.supportChatEnabled));
    }
    return false;
  }
  setRemotePrintingFlag(hasRemotePrinting: boolean = false) {
    localStorage.setItem(LocalStorageFlags.hasRemotePrinting, hasRemotePrinting.toString());
  }
  getRemotePrintingFlag(): boolean {
    let hasRemotePrinting = localStorage.getItem(LocalStorageFlags.hasRemotePrinting);
    return hasRemotePrinting && hasRemotePrinting === 'true' ? true : false;
  }

  setScaleModelsFlag(hasScaleModels: boolean = false) {
    localStorage.setItem(LocalStorageFlags.hasScaleModels, hasScaleModels.toString());
  }

  getScaleModelsFlag(): boolean {
    let hasScaleModels = localStorage.getItem(LocalStorageFlags.hasScaleModels);
    return hasScaleModels && hasScaleModels === 'true' ? true : false;
  }

  setRFIDSupportFlag(hasRfidSupport: boolean = false) {
    localStorage.setItem(LocalStorageFlags.hasRFIDSupport, hasRfidSupport.toString());
  }

  hasRFIDSupportEnabled(): boolean {
    let hasRFIDSupport = localStorage.getItem(LocalStorageFlags.hasRFIDSupport);
    return hasRFIDSupport && hasRFIDSupport === 'true' ? true : false;
  }

  getAccountHasExternalAuthentication(): boolean {
    if (localStorage.getItem(LocalStorageFlags.HasExternalAuthentication)) {
      return JSON.parse(localStorage.getItem(LocalStorageFlags.HasExternalAuthentication));
    }
    return false;
  }
  getAccountHasExternalInterface(): boolean {
    if (localStorage.getItem(LocalStorageFlags.HasExternalInterface)) {
      return JSON.parse(localStorage.getItem(LocalStorageFlags.HasExternalInterface));
    }
    return false;
  }

  saveAccountConfigurations(accountInfo: any, calledAfterLogin: boolean = false) {
    //checking if the impersonated account's reseller can set passwords
    this.setImpersonatedCanSetPasswordsFlag(accountInfo.canSetPasswords);

    this.setOrderGuidesActivationConfig(accountInfo.hasOrderGuidesActivated);
    this.setInvoicesActivationConfig(accountInfo.hasInvoicesActivated);
    this.setOrdersActivationConfig(accountInfo.hasOrdersActivated);
    this.setMessageCenterActivationConfig(accountInfo.hasMessageCenterActivated);
    this.setSubLocationConfig(accountInfo.hasSubLocation);
    this.setZYToken(accountInfo.zyToken);
    this.setRecipeManagementSupportedFlagInLocalStorage(accountInfo.hasRecipes);
    this.setHasAdvancedChecklistActivatedFlagInLocalStorage(accountInfo.hasAdvancedChecklistActivated);
    this.setShortcutsConfiguration(accountInfo.presetsConfiguration);
    this.setLabelingActivationConfig(accountInfo.hasLabeling);
    this.setMediaActivationConfig(accountInfo.hasMediaManager);
    this.setAllowRefreshLocationCode(accountInfo.allowRefreshLocationCode);
    this.setWorkflowPermissions(accountInfo.hasWorkflowActivated);
    this.setIsPublisher(accountInfo.isPublisher);
    this.setTempActivationConfig(accountInfo.hasTempActivated);
    this.setDailyProductionFlag(accountInfo.hasDailyProduction);
    this.setSenseActivationConfig(accountInfo.hasSenseActivated);
    this.setSenseConfActivationConfig(accountInfo.hasSenseConfActivated);
    this.setLocalKey(accountInfo.allowMultiCode, LocalStorageFlags.hasMultiStoreCodeActivated);
    this.setChecklistActivationConfig(accountInfo.hasChecklistActivated);
    this.setChecklistReportsActivated(accountInfo.hasChecklistActivated || accountInfo.hasAdvancedChecklistActivated);
    this.setAccountScaleUoMId(accountInfo.accountScaleUoMId);
    this.setTimersActivationConfig(accountInfo.hasTimersActivated);
    this.setTempConfActivationConfig(accountInfo.hasTempConfActivated);
    this.setLocalKey(accountInfo.hasAddons, LocalStorageFlags.hasAddons);
    this.setHasDualNutrionalPanelsInLocalStorage(accountInfo.hasDualNutrionalPanels);
    this.setLocalKey(accountInfo.nutritionalPanelCount, LocalStorageFlags.nutritionalPanelCount);
    this.setRequirePinForUsers(accountInfo.requirePinForUsers);
    this.setKnowledgeBaseFlag(accountInfo.knowledgeBaseEnabled);
    this.setRemotePrintingFlag(accountInfo.hasRemotePrinting);
    this.setScaleModelsFlag(accountInfo.hasScaleModels);
    this.setRFIDSupportFlag(accountInfo.hasRFIDSupport);
    this.setLocalKey(accountInfo.showSubscriberTempAnalytics, LocalStorageFlags.ShowSubscriberTempAnalytics);
    this.setLocalKey(accountInfo.showSubscriberSenseAnalytics, LocalStorageFlags.ShowSubscriberSenseAnalytics);
    this.setLocalKey(accountInfo.showSubscriberDevices, LocalStorageFlags.ShowSubscriberDevices);
    this.setLocalKey(accountInfo.hasDistributionCentersActivated, LocalStorageFlags.hasDistributionCentersActivated);
    this.setLocalKey(accountInfo.hasDistributionUsersActivated, LocalStorageFlags.hasDistributionUsersActivated);
    this.setLocalKey(accountInfo.hasOrderManagementActivated, LocalStorageFlags.hasOrderManagementActivated);
    this.setLocalKey(accountInfo.hasInventoryReportingActivated, LocalStorageFlags.hasInventoryReportingActivated);
    if (accountInfo.knowledgeBaseEnabled) {
      this.setKnowledgeBaseURL(accountInfo.knowledgeBaseURL);
    }
    this.setSupportChatFlag(accountInfo.supportChatEnabled);
    this.setSupportId(accountInfo.supportId);
    setTempUoMId(accountInfo.tempUoMId);
    //Set Account Info
    this.setSecondaryLanguageConfig(accountInfo.hasSecondaryLanguage);
    this.setAllowRefreshLocationCode(accountInfo.allowRefreshLocationCode);
    this.setHasPriceOptions(accountInfo.hasPriceOptions);
    this.setHasMultiLabeling(accountInfo.hasMultiLabeling);
    this.setDisplayItemThumbnail(accountInfo.displayItemThumbnail);
    // If Reseller (Validation)
    this.setLocalKey(accountInfo.resellerId, LocalStorageFlags.resellerId);
    this.setLocalKey(accountInfo.hasFixedReaders, LocalStorageFlags.hasFixedReaders);
    if (accountInfo.isReseller) {
      this.setResellerTempConfActivationConfig(accountInfo.hasTempConfActivated);
    } else {
      this.setTempConfActivationConfig(accountInfo.hasTempConfActivated);
    }

    this.setHasExternalAuthentication(accountInfo.hasExternalAuthentication);
    this.setHasExternalInterface(accountInfo.hasExternalInterface);
    this.setUseNewSubscriptionFlow(accountInfo.useNewSubscriptionFlow);

    this.setAccountPrivacyPolicyLink(accountInfo.privacyPolicyLink);
    this.setAccountCompanyNameInc(accountInfo.companyNameInc);
    this.setAccountTermsAndConditionsLink(accountInfo.termConditionLink);
    this.setAllowCustomCode(accountInfo.allowCustomCode);
    this.setAccountTenantTypeId(accountInfo.tenantTypeId);
    this.setHasManualPriceEntry(accountInfo.hasManualPriceEntry);
    this.setLocalKey(accountInfo.manageUnassignRestrictionForSubscribers, LocalStorageFlags.CanManageUnassignRestrictionForSubscribers);
    this.setLocalKey(accountInfo.hasAutomaticUserMLCGeneration, LocalStorageFlags.HasAutomaticUserMLCGeneration);
  }

  doesAccountBelongToTransact(): boolean {
    const accountCompanyName = this.getAccountCompanyNameInc();
    return accountCompanyName === COMPANY_NAME.transact;
  }

  getResellerConfigData(): Promise<Response> {
    const headers = new Headers();
    headers.append('portal-url', window.location.href); //The header that will be intercepted by the backend to split baseURL and subroute
    const request = new Request(`${environment.baseAPIUrl}${environment.version}/reseller/subroute`, {
      method: 'GET',
      headers: headers
    });
    return fetch(request);
  }

  getAuthenticateUsingKeycloakOIDC(): boolean {
    let authenticateUsingKeycloakOIDCFromStorage = localStorage.getItem(LocalStorageFlags.authenticateUsingKeycloakOIDC);
    let authenticateUsingKeycloakOIDC = authenticateUsingKeycloakOIDCFromStorage && authenticateUsingKeycloakOIDCFromStorage === 'true' ? true : false;
    return authenticateUsingKeycloakOIDC && !this.isPunchoutSession();
  }

  setResellerName(name: string) {
    localStorage.setItem(LocalStorageFlags.resellerName, `${name}`);
  }

  getResellerName() {
    return localStorage.getItem(LocalStorageFlags.resellerName);
  }

  shouldOpenLoginWarningDialog() {
    const resellerName = this.getResellerName();
    return resellerName && resellerName.toLowerCase() == 'dma' && !location.host.startsWith('dma') && !location.href.toLowerCase().includes('/' + resellerName.toLowerCase()) && environment.production;
  }

  setLinkedAccounts(accounts: any) {
    localStorage.setItem(LocalStorageFlags.LinkedAccounts, JSON.stringify(accounts));
  }

  getLinkedAccounts() {
    if (localStorage.getItem(LocalStorageFlags.LinkedAccounts)) {
      return JSON.parse(localStorage.getItem(LocalStorageFlags.LinkedAccounts));
    }
  }

  getBrandNameStorage(): string {
    if (localStorage.getItem(this.brandStorageKey)) {
      const strBrand = localStorage.getItem(this.brandStorageKey);
      const brand: BrandModel = JSON.parse(strBrand);
      return brand.name;
    }
    return null;
  }

  // support chat  after refactoring this service and adding local storage service these data will be moved to a seperated service
  async setZYSupportChatFeature() {
    if (this.resellerCheckService.isZYReseller()) {
      const supportChatEnabled = this.getSupportChatFlag();
      if (supportChatEnabled) {
        const supportId = this.getSupportId();
        const brandId = this.getBrandNameStorage();
        const accountName = this.getTenantName();
        const currentAppVersion = this.getCurrentStoredAppVersion();
        if (this._NgxZendeskWebwidgetService) {
          await this._NgxZendeskWebwidgetService.initZendesk();
          this._NgxZendeskWebwidgetService.zE('messenger', 'show');
          if (await this.isUserLoggedIn()) {
            this._NgxZendeskWebwidgetService.zE('messenger:set', 'conversationFields', [
              { id: '15986854638861', value: brandId ? brandId.toString() : '' },
              { id: '15952219870221', value: accountName },
              { id: '15904767008397', value: currentAppVersion }
            ]);
            supportId ? this._NgxZendeskWebwidgetService.zE('messenger:set', 'conversationFields', [{ id: '16076228162957', value: supportId }]) : null;
          }
        }
      }
    }
  }
  removeZYSupportChatFeature(): void {
    if (this.resellerCheckService.isZYReseller()) {
      if (this._NgxZendeskWebwidgetService && typeof this._NgxZendeskWebwidgetService.zE === 'function') {
        this._NgxZendeskWebwidgetService.zE('messenger', 'hide');
      }
    }
  }
  logoutZyChatUser(): void {
    if (this.resellerCheckService.isZYReseller()) {
      if (this._NgxZendeskWebwidgetService && typeof this._NgxZendeskWebwidgetService.zE === 'function') {
        this._NgxZendeskWebwidgetService.zE('messenger', 'logoutUser');
      }
    }
  }

  addOrRemoveTheSupportChat(): void {
    if (this.getSupportChatFlag()) {
      this.logoutZyChatUser(); // in case moving from an account that support to another that supports
      this.setZYSupportChatFeature();
    } else {
      this.logoutZyChatUser();
      this.removeZYSupportChatFeature();
    }
  }

  setReloadPage() {
    this.reloadPage.next(true);
  }

  async checkClientAndServerTimeDiff(): Promise<boolean> {
    let hours = 0;
    let invalid = false;
    const url = `${environment.baseAPIUrl}${environment.version}/util/serverTime`;
    const response: any = await this.httpClient.get(url).toPromise();
    if (response?.results?.serverTime) {
      const now = new Date();
      const serverDate = new Date(response.results.serverTime);
      const msBetweenDates = Math.abs(serverDate.getTime() - now.getTime());
      hours = Math.floor(msBetweenDates / (1000 * 60 * 60));
      if (hours > 24) {
        invalid = true;
      } else {
        invalid = false;
      }
    } else {
      invalid = true;
    }
    return new Promise((resolve, reject) => {
      if (invalid) {
        this.snackbarService.openWarningSnackBarWithDuration('invalid_time', 'Ok');
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
  async isUserLoggedIn() {
    if (this.getAuthenticateUsingKeycloakOIDC()) {
      return await this.keycloakService.isLoggedIn();
    } else {
      const token = this.getAuthToken();
      if (token) {
        const tokenExpired = this.isTokenExpired();
        if (tokenExpired) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  canManageUnassignRestrictionForSubscribers(): boolean {
    return this.getBooleanItemByKey(LocalStorageFlags.CanManageUnassignRestrictionForSubscribers);
  }
}
