<div class="container login-container" *ngIf="showForm">
  <div class="login-form">
    <div class="text-center"><img id="logo-container" [src]="brandImagesAssets + 'FullLogo.svg'" /></div>
    <div class="col">
      <form class="rounded" [formGroup]="formGroup" (ngSubmit)="submitForm()">
        <div>
          <mat-form-field class="login-full-width">
            <nome-password-visibility-toggle #passwordEyeIcon matSuffix></nome-password-visibility-toggle>
            <input matInput [type]="passwordEyeIcon.inputType" formControlName="password" [placeholder]="'change_password_page.password' | translate | titlecase" autocomplete="off" />
            <mat-error>
              {{ formGroup.get('password').errors | formErrors : translateService.currentLang : 'password' : validationParams }}
            </mat-error>
          </mat-form-field>
          <mat-password-strength #passwordStrength [password]="formGroup.get('password').value"></mat-password-strength>
          <nome-password-strength-info [passwordStrengthComponent]="passwordStrength"></nome-password-strength-info>
        </div>
        <mat-form-field class="login-full-width">
          <nome-password-visibility-toggle #confirmPasswordEyeIcon matSuffix></nome-password-visibility-toggle>
          <input matInput [type]="confirmPasswordEyeIcon.inputType" formControlName="confirmPassword" [placeholder]="'change_password_page.confirm-password' | translate" autocomplete="off" />
          <mat-error>
            {{ formGroup.get('confirmPassword').errors | formErrors : translateService.currentLang : 'confirm-password' : validationParams }}
          </mat-error>
        </mat-form-field>
        <button mat-flat-button color="accent" [disabled]="!formGroup.valid || passwordStrength.strength < 100" type="submit" id="login-button" class="btn btn-def btn-block">
          {{ 'change_password_page.submit_button' | translate }}
        </button>
      </form>
    </div>
  </div>
</div>
