<div
  class="tree-wrapper"
  *ngIf="treeCmp"
  [ngClass]="{
    dark: treeCmp.darkTheme,
    light: !treeCmp.darkTheme,
    'selected-node-background-highlighted': treeCmp.selectedNodeBackgroundHighlighted,
    'hovered-node-background-highlighted': treeCmp.hoveredNodeBackgroundHighlighted
  }"
>
  <div class="tree-display">
    <div *ngIf="treeCmp.showTitle" class="row no-margin">
      <div class="col no-padding fix-height">
        <div class="tree-title-wrapper mt-4">
          <h2>{{ treetitle | titlecase }}</h2>
          <h6 matTooltip="{{ 'account_manager.account-info.account-code' | translate | titlecase }}">{{ treeSubtitle | titlecase }}</h6>
        </div>
      </div>
    </div>
    <div class="row no-margin mt-2 searchBar">
      <div [formGroup]="searchForm" [ngClass]="{ 'search-field-left-margin lightSearchfield': treeCmp.darkTheme }" class="no-padding col-10" *ngIf="!treeCmp.hideSearch">
        <mat-form-field class="w-90 search">
          <input (keydown)="$event.stopPropagation()" id="locationTreeSearch" #searchInput formControlName="searchField" matInput placeholder="{{ 'search' | translate | titlecase }}" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>
    <ng-content select="[content-after-search]"></ng-content>
    <div class="row no-margin content" [ngClass]="{ 'border-top': treeCmp.showTitle }">
      <div class="col no-padding" [ngClass]="isInsideDropDown ? '' : 'zero-height'" [ngStyle]="treeCmp.maxHeight ? { 'max-height': treeCmp.maxHeight + 'px', 'overflow-y': 'auto', 'overflow-x': 'hidden' } : ''">
        <div *ngIf="!dataSource || !dataSource.data || dataSource.data.length == 0" class="notification">
          <mat-icon class="notification_icon">error_outline</mat-icon>
          <span class="ml-1">{{ 'no_results_found' | translate }}!</span>
        </div>

        <mat-tree class="treecustomstyle" #mat_tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node
            *matTreeNodeDef="let node"
            [matTreeNodePaddingIndent]="10"
            matTreeNodeToggle
            matTreeNodePadding
            class="pointer treenode-padding"
            [ngClass]="{ 'd-none': onlyShowSelected && !isNodeChecked(node) && !checkedLocations.includes(node.id), 'accent-50-bg-color': treeCmp.selectedNodeBackgroundHighlighted && node.selected }"
            (click)="treeCmp.selectedNodeBackgroundHighlighted && highlightNode(node)"
          >
            <ng-container *ngIf="!contextMenuItems; else leafNodeContentWithContextMenu">
              <ng-container *ngTemplateOutlet="leafNodeContent; context: { $implicit: node }"></ng-container>
            </ng-container>
            <ng-template #leafNodeContentWithContextMenu>
              <div
                [contextMenuTrigger]="contextMenuComponent"
                [menuContext]="idsThatDoesntHaveContextMenu && idsThatDoesntHaveContextMenu.includes(node.id) ? [] : contextMenuItems"
                (menuAction)="triggerContextMenuItemAction($event, node)"
                [ngStyle]="{ 'margin-left.px': -10 * node.level, 'padding-left.px': 10 * node.level }"
              >
                <ng-container *ngTemplateOutlet="leafNodeContent; context: { $implicit: node }"></ng-container>
              </div>
            </ng-template>
          </mat-tree-node>

          <mat-tree-node
            [matTreeNodePaddingIndent]="10"
            *matTreeNodeDef="let node; when: hasChild"
            matTreeNodePadding
            class="pointer treenode-padding haschild"
            [ngClass]="{ 'accent-50-bg-color': treeCmp.selectedNodeBackgroundHighlighted && node.selected }"
            (click)="treeCmp.selectedNodeBackgroundHighlighted && highlightNode(node)"
          >
            <ng-container *ngIf="!contextMenuItems; else nonLeafNodeContentWithContextMenu">
              <ng-container *ngTemplateOutlet="nonLeafNodeContent; context: { $implicit: node }"></ng-container>
            </ng-container>
            <ng-template #nonLeafNodeContentWithContextMenu>
              <div
                [contextMenuTrigger]="contextMenuComponent"
                [menuContext]="idsThatDoesntHaveContextMenu && idsThatDoesntHaveContextMenu.includes(node.id) ? [] : contextMenuItems"
                (menuAction)="triggerContextMenuItemAction($event, node)"
                [ngStyle]="{ 'margin-left.px': -10 * node.level, 'padding-left.px': 10 * node.level }"
              >
                <ng-container *ngTemplateOutlet="nonLeafNodeContent; context: { $implicit: node }"></ng-container>
              </div>
            </ng-template>
          </mat-tree-node>
        </mat-tree>
      </div>
    </div>
  </div>
</div>

<ng-template #leafNodeContent let-node>
  <button mat-icon-button [disableRipple]="true" [style.visibility]="'hidden'" (click)="!treeCmp.selectedNodeBackgroundHighlighted && highlightNode(node)"></button>
  <table [ngClass]="{ 'fixed-layout-table': fixedTableLayout, highlighted: node.selected, expanded: node.expanded, 'in-dropdown': isInsideDropDown }">
    <tr>
      <td *ngIf="treeCmp.showCheckbox && treeCmp.folderSelectionType && treeCmp.folderSelectionType.indexOf(node.type) >= 0" class="checkbox-container">
        <mat-checkbox
          class="checkbox checklist-leaf-node"
          [checked]="checkedLocationsUsed ? checkedLocations.includes(node.id) : isNodeChecked(node)"
          (change)="toggleLeafNodeCheckedState(node); checkboxSelection($event, node)"
        ></mat-checkbox>
      </td>
      <td class="radio-button-container" *ngIf="(selectionType === 'radio' || treeCmp.selectionType === 'radio') && ((showSelectionForStoreLevelOnly && node.type == 2) || !showSelectionForStoreLevelOnly)">
        <mat-radio-button [checked]="node.selected" (click)="!treeCmp.selectedNodeBackgroundHighlighted && highlightNode(node)" [value]="node.id" class="radio-pos"></mat-radio-button>
      </td>

      <td
        (click)="!treeCmp.selectedNodeBackgroundHighlighted && highlightNode(node)"
        class="folder-icon"
        [ngClass]="{ highlighted: node.selected, expanded: node.expanded, 'in-dropdown': isInsideDropDown, highlighted_search: highlightBackground(node), 'additional-icon-space': node.icon }"
      >
        <mat-icon color="accent" *ngIf="node.type == nodeTypeEnum.Location || node.type == nodeTypeEnum.Area || node.type == nodeTypeEnum.Media" class="tree-item-icon" svgIcon="folder"></mat-icon>
        <mat-icon color="accent" *ngIf="node.type == nodeTypeEnum.Store" class="tree-item-icon" svgIcon="store"></mat-icon>
        <mat-icon color="accent" *ngIf="node.type == nodeTypeEnum.Product && isPopulatedInMenuItem" class="tree-item-icon" svgIcon="folder"></mat-icon>
        <mat-icon color="accent" *ngIf="node.type == nodeTypeEnum.Product && !isPopulatedInMenuItem" class="tree-item-icon" svgIcon="products"></mat-icon>
        <mat-icon color="accent" *ngIf="node.type == nodeTypeEnum.Ingredient && isPopulatedInMenuItem" class="tree-item-icon" svgIcon="folder"></mat-icon>
        <mat-icon color="accent" *ngIf="node.type == nodeTypeEnum.Ingredient && !isPopulatedInMenuItem" class="tree-item-icon" svgIcon="ingredients"></mat-icon>
        <mat-icon color="accent" class="tree-item-icon icon" [svgIcon]="node.icon" *ngIf="node.icon"></mat-icon>
      </td>
      <td (click)="!treeCmp.selectedNodeBackgroundHighlighted && highlightNode(node)">
        <div class="d-flex align-items-center">
          <span class="node-display node-name" [ngClass]="{ highlighted_search: highlightBackground(node) }" [matTooltip]="node.name">{{ node.name }}</span>
          <span
            [ngClass]="{ highlighted_search: highlightBackground(node) }"
            class="suffix"
            [ngClass]="{
              'light-accent-text-color font-weight-bold': node.level === 0,
              'dark-grey-color': node.level > 0 && !treeCmp.darkTheme,
              'light-grey-color': node.level > 0 && treeCmp.darkTheme
            }"
            *ngIf="node.suffix"
            >{{ node.suffix ? node.suffix : '' }}</span
          >
          <mat-icon color="accent" class="tree-item-icon icon" [class.mr-5px]="lastIcon" [svgIcon]="icon.name" [matTooltip]="icon.tooltip | translate" *ngFor="let icon of node.rightIcons; last as lastIcon"></mat-icon>
        </div>
      </td>
    </tr>
  </table>
</ng-template>

<ng-template #nonLeafNodeContent let-node>
  <button mat-icon-button type="button" [style.visibility]="showExpansionArrow(node) ? 'visible' : 'hidden'" (click)="toggleNodeExpansion(node, $event)">
    <mat-icon color="accent" class="mat-icon-rtl-mirror chevron-icon">
      {{ node.expanded ? 'arrow_drop_down' : 'arrow_right' }}
    </mat-icon>
  </button>
  <table [ngClass]="{ 'fixed-layout-table': fixedTableLayout, highlighted: node.selected, expanded: node.expanded, 'in-dropdown': isInsideDropDown }">
    <tr>
      <td *ngIf="treeCmp.showCheckbox && treeCmp.folderSelectionType && treeCmp.folderSelectionType.indexOf(node.type) >= 0" class="checkbox-container">
        <mat-checkbox
          class="checkbox"
          [checked]="checkedLocationsUsed ? checkedLocations.includes(node.id) : descendantsAllSelected(node)"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="toggleNonLeafNodeCheckedState(node); checkboxSelection($event, node)"
        >
        </mat-checkbox>
      </td>
      <td class="radio-button-container" *ngIf="(selectionType === 'radio' || treeCmp.selectionType === 'radio') && ((showSelectionForStoreLevelOnly && node.type == 2) || !showSelectionForStoreLevelOnly)">
        <mat-radio-button
          [checked]="node.selected"
          (click)="!treeCmp.selectedNodeBackgroundHighlighted && highlightNode(node); onExpandableNodeRadioButtonClick(node)"
          [value]="node.id"
          class="radio-pos"
        ></mat-radio-button>
      </td>
      <td
        (click)="!treeCmp.selectedNodeBackgroundHighlighted && highlightNode(node); onExpandableNodeIconClick(node)"
        class="folder-icon"
        [ngClass]="{ highlighted: node.selected, expanded: node.expanded, 'in-dropdown': isInsideDropDown, highlighted_search: highlightBackground(node), 'additional-icon-space': node.icon }"
      >
        <ng-container *ngIf="!node.loading; else spinner">
          <mat-icon
            color="accent"
            *ngIf="node.type == nodeTypeEnum.Location || node.type == nodeTypeEnum.Area || node.type == nodeTypeEnum.Media"
            class="tree-item-icon"
            [svgIcon]="node.expanded ? 'folder-open' : 'folder'"
          ></mat-icon>
          <mat-icon color="accent" *ngIf="node.type == nodeTypeEnum.Store" class="tree-item-icon" svgIcon="store"></mat-icon>
          <mat-icon color="accent" *ngIf="node.type == nodeTypeEnum.Product && isPopulatedInMenuItem" class="tree-item-icon" [svgIcon]="node.expanded ? 'folder-open' : 'folder'"></mat-icon>
          <mat-icon color="accent" *ngIf="node.type == nodeTypeEnum.Product && !isPopulatedInMenuItem" class="tree-item-icon" svgIcon="products"></mat-icon>
          <mat-icon color="accent" *ngIf="node.type == nodeTypeEnum.Ingredient && isPopulatedInMenuItem" class="tree-item-icon" [svgIcon]="node.expanded ? 'folder-open' : 'folder'"></mat-icon>
          <mat-icon color="accent" *ngIf="node.type == nodeTypeEnum.Ingredient && !isPopulatedInMenuItem" class="tree-item-icon" svgIcon="ingredients"></mat-icon>
          <mat-icon color="accent" class="tree-item-icon icon" [svgIcon]="node.icon" *ngIf="node.icon"></mat-icon>
        </ng-container>
        <ng-template #spinner>
          <mat-spinner *ngIf="node.loading" [diameter]="20"></mat-spinner>
        </ng-template>
      </td>
      <td class="parent-folder" (click)="!treeCmp.selectedNodeBackgroundHighlighted && highlightNode(node); onExpandableNodeTextClick(node)">
        <div class="d-flex align-items-center">
          <span [ngClass]="{ highlighted_search: highlightBackground(node) }" class="node-display node-name" [matTooltip]="node.name">{{ node.name }} </span>
          <span
            class="suffix"
            [ngClass]="{
              highlighted_search: highlightBackground(node),
              'light-accent-text-color font-weight-bold': node.level === 0,
              'dark-grey-color': node.level > 0 && !treeCmp.darkTheme,
              'light-grey-color': node.level > 0 && treeCmp.darkTheme
            }"
            *ngIf="node.suffix"
            >{{ node.suffix ? node.suffix : '' }}</span
          >
          <mat-icon color="accent" class="tree-item-icon icon" [class.mr-5px]="lastIcon" [svgIcon]="icon.name" [matTooltip]="icon.tooltip | translate" *ngFor="let icon of node.rightIcons; last as lastIcon"></mat-icon>
          <ng-container [ngTemplateOutlet]="nonLeafNodeRightContentTemplate" [ngTemplateOutletContext]="{ $implicit: node }"></ng-container>
        </div>
      </td>
    </tr>
  </table>
</ng-template>

<ng-template #noitems>
  <div class="col text-center mt-4">
    <h6 class="no-content">{{ treeCmp?.errorMessage | translate }}</h6>
  </div>
</ng-template>
