export enum LabelItemProperties {
  ALIAS = 'alias',
  TYPE = 'type',
  PLACEHOLDER = 'placeholder',
  OPTION = 'option',
  DATE_ADDITIONAL_FORMAT = 'date_additional_format',
  DATE_FORMAT_SEPERATOR = 'dateFormatSeperator',
  SECTION = 'section',
  ASK_LATER = 'askLater',
  FORMAT = 'format',
  SECOND_FORMAT = 'secondFormat',
  SIZE = 'size',
  TYPE_ID = 'typeId',
  SHRINK_TO_FIT = 'shrinkToFit',
  AUTO_HEIGHT_ADJUSTMENT = 'autoHeightAdjustment',
  MIN_FONT_SIZE = 'minFontSize',
  DISPLAY_CODE = 'displayCode',
  REQUIRE_USER_INPUT = 'require_user_input',
  REQUIRE_REMOTE_PRINTING = 'requireRemotePrinting',
  USER_INPUT = 'user_input',
  FILL_FOR_ALL = 'fillForAll',
  REQUIRED = 'required',
  INVALID = 'invalid',
  ERROR_MESSAGE = 'errorMessage',
  PRE_TEXT = 'preText',
  POST_TEXT = 'postText',
  PRE_TEXT_WITH_TARE = 'preTextWithTare',
  SEPARATOR_TEXT = 'separatorText',
  ITEM_NAME = 'itemName',
  DISCARD_DATE_TYPE = 'discardDateType',
  DISCARD_DATE_REFERENCE = 'discardDateReference',
  HAS_REFERENCE_SHELF_LIFE = 'hasReferenceShelfLife',
  REFERENCE_SHELF_LIFE_PROMPT = 'referenceShelfLifePromptMessage',
  LOCALE_ID = 'localeId',
  FIELD = 'field',
  SECOND_FIELD = 'secondField',
  ROUNDING_ENABLED = 'roundingEnabled',
  ROUNDING = 'rounding',
  DISCOUNT_PERCENTAGE = 'discountPercentage',
  SHOW_UNIT_OF_CURRENCY = 'showUnitOfCurrency',
  REQUIRED_TO_PRINT_LABEL = 'requiredToPrintLabel',
  OPERATION = 'operation',
  OPERATOR = 'operator',
  DECIMAL_COUNT = 'decimalCount',
  REFERENCE_DATE = 'referenceDate',
  FORMATTER = 'formatter',
  IS_CUSTOM_DATE_FORMAT = 'isCustomDateFormat',
  CUSTOM_DATE_FORMAT_OPTIONS = 'customDateFormatOptions',
  INCLUDE_TOTAL_BATCH_COUNT = 'includeTotalBatchCount',
  CHARACTER_COUNT_TYPE = 'characterCountType',
  POSITION = 'position',
  STARTING_POSITION = 'startingPosition',
  // widgets
  KEY = 'key',
  NF_ID = 'id',
  UOM = 'uom',
  FACT_ITERATIONS = 'selectedIteration',
  SHOW_UOM = 'showUom',
  UOM_FORMAT = 'uomFormat',
  VALUE = 'value',
  VALUE_1 = 'value1',
  VALUE_2 = 'value2',
  VALUE_3 = 'value3',
  VALUE_4 = 'value4',
  VALUE_5 = 'value5',
  VALUE_6 = 'value6',
  VALUE_7 = 'value7',
  FACT_NAME = 'factName',
  DAILY_VALUE = 'dailyValue',
  DAILY_VALUE_2 = 'dailyValue2',
  DAILY_VALUE_3 = 'dailyValue3',
  DAILY_VALUE_4 = 'dailyValue4',
  DAILY_VALUE_5 = 'dailyValue5',
  DAILY_VALUE_6 = 'dailyValue6',
  DAILY_VALUE_7 = 'dailyValue7',
  GROUP_ID = 'groupId',
  GROUP_NAME = 'groupName',
  WIDGET_TYPE = 'widgetType',
  PRECISION = 'precision',
  GROUP_WIDTH = 'groupWidth',
  GROUP_HEIGHT = 'groupHeight',
  GROUP_ROTATION = 'groupRotation',
  COMPOSITE_FIELDS = 'compositeFields',
  ITEM_IDENTIFIER = 'itemIdentifier',
  CENTER_CHECK_DIGIT = 'centerCheckDigit',
  VARIABLE_FIELD = 'variableField',
  COMPOSITE_SHRINK_TO_FIT = 'compostie_stf',
  COMPOSITE_MIN_FONT_SIZE = 'compostie_mfs',
  COMPOSITE_SHOW_LAST_FIELD_VALUE = 'composit_slfv',
  WIDGETS = 'widgets',
  PRE_CONDITION = 'preCondition',
  OPERAND = 'operand',
  POST_CONDITION = 'postCondition',
  INFO_DATA = 'infoData',
  TIMER_TEMPLATE_NAME = 'timer_template_name',
  IS_REMOTE_PRINTING = 'isRemotePrinting',
  FROM_TIME = 'fromTime',
  TO_TIME = 'toTime',
  GROUP_BY_RECIPE = 'groupByRecipe',
  HIDE_PRECISION_FOR_WHOLE_NUMBERS = 'hidePrecisionForWholeNumbers',
  EXTRA_TYPE = 'extraType',
  UPC_DATA = 'upcData',
  VOICE_PICK_DATA = 'voicePickData',
  CUSTOM_CODE = 'customCode',
  CUSTOM_CODE_EDITOR_VALUE = 'customCodeEditorValue',
  CUSTOM_CODE_DESCRIPTION = 'customCodeDescription',
  IS_DYNAMIC_DOW = 'isDynamicDOW',
  UUID = 'uuid',
  USE_SUB_LOCATION = 'useSublocation',
  NAME_FORMAT = 'nameFormat'
}

export enum OperatorEnum {
  multiplication,
  plus,
  minus,
  division
}

export enum RoundingEnum {
  UP = 'up',
  DOWN = 'down',
  HALF_UP = 'halfUp'
}
