import { ComponentTypes } from './models/enums/component-types.enum';
import { FontSettings } from './models/font.model';
import { LOCALES } from '../../shared/constants.config';
import { WidgetTypes } from './models/enums/widget-types.enum';
import { truncate } from 'fs';
import { DateFormat } from './models/additional-properties.model';
import { TenantTypeEnum } from '../../shared/models/tenant-type.model';
import { DiscardShelfLifeEnum } from './models/enums/discard-shelf-life.enum';
import { LabelPropertyOptions } from './models/label-model';
import { PartitionSelector } from './models/enums/partition-selector.enum';
import { EncodingTypesEnum } from './models/enums/encoding-types.enum';
import { SerialNumberSourceEnum } from './models/enums/serial-number-source.enum';
import { validationParams } from '../../pipes/form-errors/error-model';
import { FormErrosEnum } from '../../pipes/form-errors/error-mapper';
import { FieldType } from './models/atma-fields.model';
import { CustomField } from '../settings/enums/custom-field.enum';
import { ProductComponentTypes } from './models/enums/product-componenet-types';
import { CustomFieldTypeEnum } from '../../shared/models/custom-field.model';
import { DropdownOption } from '../../shared/models/dropdown-option.model';
import { NameFormat } from './models/enums/name-format.enum';

// Japanese Date format
export const JapaneseDateFormat1 = 'yyyy {{Year}} MM {{Month}} dd {{Day}}';
export const JapaneseDateFormat2 = 'yy {{Year}} MM {{Month}} dd {{Day}}';
// import { JulianDateFormat } from './models/enums/julian-dates.enum';
export enum BarCodeId {
  gs1 = 83,
  qrCode = 41,
  code39 = 3,
  code128 = 5,
  ean8 = 7,
  ean13 = 8,
  interleaved = 10,
  upca = 17
}

export enum UpcTypes {
  type2 = 2,
  gs1128 = 1,
  code39 = 3,
  code128 = 5,
  upca = 17,
  ean8 = 7,
  ean13 = 8,
  interleaved = 10
}
export interface BarCodeIdentifiers {
  name: string;
  value: string;
  relatedMapValues?: string[];
  editable?: boolean;
  defaultValue?: any;
  hint?: string;
  min?: number;
  max?: number;
  type?: string;
  supportedTenantTypes?: TenantTypeEnum[];
}

export interface BarcodeType {
  id: number;
  name: string;
  defaultValue: string;
  supportedSizes: any[];
  extraType?: string;
  authorizedBylocalKey?: string[];
  hidden?: boolean;
  upcType?: UpcTypes;
}
export interface SizeConstraint {
  size: string;
  count: number;
}

export interface BarcodeConstraints {
  name: string;
  constraints: TranslationBlock[];
  sizeConstraints?: SizeConstraint[];
}
export interface TranslationBlock {
  destination: string;
  variable?: any;
}

export const SUPPORTED_DATE_SEPERATORS: string[] = ['/', '-', '.'];

export const SUPPORTED_DATE_FROMATS: DateFormat[] = [
  { value: 'MM/dd/yy', viewValue: 'MM/DD/YY', isDateOnly: true, isTimeOnly: false, isMilitary: false, hasCustomSeperator: true, defaultSeperator: '/' },
  { value: 'MM/dd', viewValue: 'MM/DD', isDateOnly: true, isTimeOnly: false, isMilitary: false, hasCustomSeperator: true, defaultSeperator: '/' },
  { value: 'dd/MM/yy', viewValue: 'DD/MM/YY', isDateOnly: true, isTimeOnly: false, isMilitary: false, hasCustomSeperator: true, defaultSeperator: '/' },
  { value: 'dd/MM', viewValue: 'DD/MM', isDateOnly: true, isTimeOnly: false, isMilitary: false, hasCustomSeperator: true, defaultSeperator: '/' },
  { value: 'MM/dd/yy hh:mm a', viewValue: 'MM/DD/YY 12H Time AM/PM', isDateOnly: false, isTimeOnly: false, isMilitary: false, hasCustomSeperator: true, defaultSeperator: '/' },
  { value: 'yMMdd', viewValue: 'YMMDD', isDateOnly: true, isTimeOnly: false, isMilitary: false },
  { value: 'yyMMdd', viewValue: 'YYMMDD', isDateOnly: true, isTimeOnly: false, isMilitary: false },
  { value: 'yyyy-MM-dd', viewValue: 'YYYY-MM-DD', isDateOnly: true, isTimeOnly: false, isMilitary: false, hasCustomSeperator: true, defaultSeperator: '-' },
  { value: 'yyyy-MM-dd hh:mm a', viewValue: 'YYYY-MM-DD 12H Time AM/PM', isDateOnly: false, isTimeOnly: false, isMilitary: false, hasCustomSeperator: true, defaultSeperator: '-' },
  { value: 'MM/dd hh:mm a', viewValue: 'MM/DD 12H Time AM/PM', isDateOnly: false, isTimeOnly: false, isMilitary: false, hasCustomSeperator: true, defaultSeperator: '/' },
  { value: 'MM/dd/yy HH:mm', viewValue: 'MM/DD/YY 24H Time', isDateOnly: false, isTimeOnly: false, isMilitary: true, hasCustomSeperator: true, defaultSeperator: '/' },
  { value: 'MM/dd HH:mm', viewValue: 'MM/DD 24H Time', isDateOnly: false, isTimeOnly: false, isMilitary: true, hasCustomSeperator: true, defaultSeperator: '/' },
  { value: 'dd/MM/yy hh:mm a', viewValue: 'DD/MM/YY 12H Time AM/PM', isDateOnly: false, isTimeOnly: false, isMilitary: false, hasCustomSeperator: true, defaultSeperator: '/' },
  { value: 'dd/MM hh:mm a', viewValue: 'DD/MM 12H Time AM/PM', isDateOnly: false, isTimeOnly: false, isMilitary: false, hasCustomSeperator: true, defaultSeperator: '/' },
  { value: 'dd/MM/yy HH:mm', viewValue: 'DD/MM/YY 24H Time', isDateOnly: false, isTimeOnly: false, isMilitary: true, hasCustomSeperator: true, defaultSeperator: '/' },
  { value: 'dd/MM HH:mm', viewValue: 'DD/MM 24H Time', isDateOnly: false, isTimeOnly: false, isMilitary: true, hasCustomSeperator: true, defaultSeperator: '/' },
  { value: 'EEE', viewValue: 'Day of Week (Short)', isDateOnly: true, isTimeOnly: false, isMilitary: false },
  { value: 'EEEE', viewValue: 'Day of Week', isDateOnly: true, isTimeOnly: false, isMilitary: false },
  { value: 'EEEE hh:mm a', viewValue: 'Day of Week 12H Time' },
  { value: 'EEEE HH:mm', viewValue: 'Day of Week 24H Time' },
  { value: 'julian.YYDDD', viewValue: 'YYDDD (Julian Format)', isDateOnly: true },
  { value: 'julian.YYDDD hh:mm a', viewValue: 'YYDDD 12H Time AM/PM (Julian Format)' },
  { value: 'julian.YYDDD HH:mm', viewValue: 'YYDDD 24H Time (Julian Format)' },
  { value: 'julian.DDDYY', viewValue: 'DDDYY (Julian Format)', isDateOnly: true },
  { value: 'julian.DDDY', viewValue: 'DDDY (Julian Format)', isDateOnly: true },
  { value: 'julian.DDDYY hh:mm a', viewValue: 'DDDYY 12H Time AM/PM (Julian Format)' },
  { value: 'julian.DDDYY HH:mm', viewValue: 'DDDYY 24H Time (Julian Format)' },
  { value: 'hh:mm a', viewValue: 'TIME 12H AM/PM', isDateOnly: false, isTimeOnly: true, isMilitary: false },
  { value: 'HH:mm', viewValue: 'TIME 24H', isDateOnly: false, isTimeOnly: true, isMilitary: true },
  { value: JapaneseDateFormat1, viewValue: 'YYYY Year MM Month DD Day', isDateOnly: true, isCustomFormatForJapaneseLanguage: true },
  { value: JapaneseDateFormat2, viewValue: 'YY Year MM Month DD Day', isDateOnly: true, isCustomFormatForJapaneseLanguage: true }
];
export const DEFAULT_DATE_FORMAT = SUPPORTED_DATE_FROMATS[1];
export const TIME_ONLY_24H_FORMAT = SUPPORTED_DATE_FROMATS[SUPPORTED_DATE_FROMATS.length - 1];
export const TIME_ONLY_12H_FORMAT = SUPPORTED_DATE_FROMATS[SUPPORTED_DATE_FROMATS.length - 2];
export const DEFAULT_LIST_ITEMS_COUNT = 3;
export const MIN_LINE_LENGTH = 20;
export const PPI = 96;

//Suppose 1 inch corresponds to 300 pixels on the label designer

export const DEFAULT_BARCODE_SIZES = [
  {
    name: 'size_small',
    width: 300,
    height: 75 // width: 1 inch - height: 0.25 inches
  },
  {
    name: 'size_medium',
    width: 450,
    height: 90 // width: 1.5 inches - height: 0.2 inches
  },
  {
    name: 'size_large',
    width: 600,
    height: 90 // width: 2 inches - height: 0.15 inches
  },
  {
    name: 'size_custom',
    width: 600,
    height: 90 // width: 2 inches - height: 0.15 inches
  }
];

export const GS1_128_SIZES = [
  {
    name: 'size_large',
    width: 600,
    height: 90 // width: 2 inches - height: 0.15 inches
  },
  {
    name: 'size_custom',
    width: 600,
    height: 90 // width: 2 inches - height: 0.15 inches
  }
];

export const EAN_8_BARCODE_SIZES = [
  {
    name: 'size_default',
    width: 315.70866,
    height: 251.692914 // width: 1.0523622 inches - aspect ratio = width/height = 1.254340685
  },
  {
    name: 'size_small',
    width: 252.519684,
    height: 201.316665 // width: 0.84173228 inches - aspect ratio = width/height = 1.254340685
  },
  {
    name: 'size_medium',
    width: 473.622,
    height: 377.5864130565134 // width: 1.57874 inches - aspect ratio = width/height = 1.254340685
  },
  {
    name: 'size_large',
    width: 631.41732,
    height: 503.38582456169 // width: 2.1047244 inches - aspect ratio = width/height = 1.254340685
  },
  {
    name: 'size_custom',
    width: 631.41732,
    height: 503.38582456169 // width: 2.1047244 inches - aspect ratio = width/height = 1.254340685
  }
];

export const EAN_13_BARCODE_SIZES = [
  {
    name: 'size_default',
    width: 440.433072,
    height: 290.445936 // width: 1.46811024 inches - aspect ratio = width/height = 1.516402933
  },
  {
    name: 'size_small',
    width: 370.2756,
    height: 244.1802188205079 // width: 1.234252 inches - aspect ratio = width/height = 1.516402933
  },
  {
    name: 'size_medium',
    width: 555.47244,
    height: 366.3092624735777 // width: 1.8515748 inches - aspect ratio = width/height = 1.516402933
  },
  {
    name: 'size_large',
    width: 740.5512,
    height: 488.3604376410158 // width: 2.468504 inches - aspect ratio = width/height = 1.516402933
  },
  {
    name: 'size_custom',
    width: 740.5512,
    height: 488.3604376410158 // width: 2.468504 inches - aspect ratio = width/height = 1.516402933
  }
];

export const UPCA_BARCODE_SIZES = [
  {
    name: 'size_default',
    width: 440.433071,
    height: 290.445937 // width: 1.46811024  inches - aspect ratio = width/height = 1.516402933
  },
  {
    name: 'size_small',
    width: 360,
    height: 237.4039196084831 // width: 1.2 inches - aspect ratio = width/height = 1.516402933
  },
  {
    name: 'size_medium',
    width: 502.5,
    height: 331.3763044535077 // width: 1.675 inches - aspect ratio = width/height = 1.516402933
  },
  {
    name: 'size_large',
    width: 675.375,
    height: 445.379645015498 // width: 2.25125 inches - aspect ratio = width/height = 1.516402933
  },
  {
    name: 'size_custom',
    width: 675.375,
    height: 445.379645015498 // width: 2.25125 inches - aspect ratio = width/height = 1.516402933
  }
];

export const UPCA_BARCODE_SIZES_OLD = [
  {
    name: 'size_small',
    width: 337.5,
    height: 90 // width and height in pixels
  },
  {
    name: 'size_medium',
    width: 450,
    height: 90
  },
  {
    name: 'size_large',
    width: 675,
    height: 90
  }
];

// QR IS ALWAYS SQUARE.
export const QR_BARCODE_SIZES = [
  {
    name: 'size_small',
    width: 180,
    height: 180 // width and height in pixels
  },
  {
    name: 'size_medium',
    width: 250,
    height: 250
  },
  {
    name: 'size_large',
    width: 300,
    height: 300
  },
  {
    name: 'size_custom',
    width: 300,
    height: 300
  }
];

export const UPC_TYPES = [
  { name: 'Code39', value: UpcTypes.code39 },
  { name: 'Code128', value: UpcTypes.code128 },
  { name: 'Ean8', value: UpcTypes.ean8 },
  { name: 'Ean13', value: UpcTypes.ean13 },
  { name: 'Interleaved2of5', value: UpcTypes.interleaved },
  { name: 'UPC-A', value: UpcTypes.upca },
  { name: 'UPC Type 2', value: UpcTypes.type2 },
  { name: 'GS1-128', value: UpcTypes.gs1128 }
];

export const CODE_39_CONSTRAINTS: TranslationBlock[] = [{ destination: 'numeric' }, { destination: 'alphabet_upper_case' }, { destination: 'all_symbols' }, { destination: 'start_stop_character' }];
export const CODE_128_CONSTRAINTS: TranslationBlock[] = [{ destination: 'all_asci_codes' }, { destination: 'numeric' }, { destination: 'alphabet_upper_lowe_case' }];
export const EAN_8_CONSTRAINTS: TranslationBlock[] = [
  {
    destination: 'characters_check_digit',
    variable: { count: 8 }
  },
  { destination: 'numeric' }
];
export const EAN_13_CONSTRAINTS: TranslationBlock[] = [{ destination: 'characters_check_digit', variable: { count: 13 } }, { destination: 'numeric' }];
export const INTERLEAVED_2_OF_5_CONSTRAINTS: TranslationBlock[] = [{ destination: 'numeric' }];
export const UPC_A_CONSTRAINTS: TranslationBlock[] = [
  {
    destination: 'characters_check_digit',
    variable: { count: 12 }
  },
  { destination: 'numeric' }
];

export const BARCODES_CONSTRAINTS: BarcodeConstraints[] = [
  {
    name: 'Code39',
    constraints: CODE_39_CONSTRAINTS,
    sizeConstraints: [
      { size: 'size_small', count: 8 },
      { size: 'size_medium', count: 12 },
      { size: 'size_large', count: 16 }
    ]
  },
  {
    name: 'Code128',
    constraints: CODE_128_CONSTRAINTS,
    sizeConstraints: [
      { size: 'size_small', count: 8 },
      { size: 'size_medium', count: 12 },
      { size: 'size_large', count: 18 }
    ]
  },
  { name: 'Ean8', constraints: EAN_8_CONSTRAINTS },
  { name: 'Ean13', constraints: EAN_13_CONSTRAINTS },
  {
    name: 'Interleaved2of5',
    constraints: INTERLEAVED_2_OF_5_CONSTRAINTS,
    sizeConstraints: [
      { size: 'size_small', count: 12 },
      { size: 'size_medium', count: 20 },
      { size: 'size_large', count: 32 }
    ]
  },
  { name: 'UPC-A', constraints: UPC_A_CONSTRAINTS },
  { name: 'Dynamic', constraints: UPC_A_CONSTRAINTS }
];

export const BARCODE_TYPES_OLD: BarcodeType[] = [
  // Code39 barcode should have larger dimensions.
  {
    id: 3,
    name: 'Code39',
    defaultValue: '123456789',
    supportedSizes: DEFAULT_BARCODE_SIZES
  },
  { id: 5, name: 'Code128', defaultValue: '1234567890', supportedSizes: DEFAULT_BARCODE_SIZES },
  { id: 7, name: 'Ean8', defaultValue: '12345670', supportedSizes: EAN_8_BARCODE_SIZES },
  { id: 8, name: 'Ean13', defaultValue: '7501031311309', supportedSizes: EAN_13_BARCODE_SIZES },
  { id: 10, name: 'Interleaved2of5', defaultValue: '1234567890', supportedSizes: DEFAULT_BARCODE_SIZES },
  { id: 17, name: 'UPC-A', defaultValue: '12345678901', supportedSizes: UPCA_BARCODE_SIZES_OLD },
  { id: 41, name: 'QRCode', defaultValue: '1234567890', supportedSizes: QR_BARCODE_SIZES }
];

export const BARCODE_TYPES: BarcodeType[] = [
  // Code39 barcode should have larger dimensions.
  {
    id: BarCodeId.code39,
    name: 'Code39',
    defaultValue: '123456789',
    supportedSizes: DEFAULT_BARCODE_SIZES
  },
  { id: BarCodeId.code128, name: 'Code128', defaultValue: '1234567890', supportedSizes: DEFAULT_BARCODE_SIZES },
  { id: BarCodeId.ean8, name: 'Ean8', defaultValue: '12345670', supportedSizes: EAN_8_BARCODE_SIZES },
  { id: BarCodeId.ean13, name: 'Ean13', defaultValue: '7501031311309', supportedSizes: EAN_13_BARCODE_SIZES },
  { id: BarCodeId.interleaved, name: 'Interleaved2of5', defaultValue: '1234567890', supportedSizes: DEFAULT_BARCODE_SIZES },
  { id: BarCodeId.upca, name: 'UPC-A', defaultValue: '12345678901', supportedSizes: UPCA_BARCODE_SIZES },
  { id: BarCodeId.upca, name: 'Dynamic', defaultValue: '00012310199', supportedSizes: UPCA_BARCODE_SIZES, extraType: 'dynamic', upcType: UpcTypes.type2 },
  { id: BarCodeId.code128, name: 'Dynamic', defaultValue: '010000000001234511230101101234', supportedSizes: GS1_128_SIZES, extraType: 'dynamic', hidden: true, upcType: UpcTypes.gs1128 },
  { id: BarCodeId.upca, name: 'Dynamic', defaultValue: '00012310199', supportedSizes: UPCA_BARCODE_SIZES, extraType: 'dynamic', hidden: true, upcType: UpcTypes.upca },
  { id: BarCodeId.code39, name: 'Dynamic', defaultValue: '123456789', supportedSizes: DEFAULT_BARCODE_SIZES, extraType: 'dynamic', hidden: true, upcType: UpcTypes.code39 },
  { id: BarCodeId.code128, name: 'Dynamic', defaultValue: '010000000001234511230101101234', supportedSizes: DEFAULT_BARCODE_SIZES, extraType: 'dynamic', hidden: true, upcType: UpcTypes.code128 },
  { id: BarCodeId.ean8, name: 'Dynamic', defaultValue: '12345670', supportedSizes: EAN_8_BARCODE_SIZES, extraType: 'dynamic', hidden: true, upcType: UpcTypes.ean8 },
  { id: BarCodeId.ean13, name: 'Dynamic', defaultValue: '7501031311309', supportedSizes: EAN_13_BARCODE_SIZES, extraType: 'dynamic', hidden: true, upcType: UpcTypes.ean13 },
  { id: BarCodeId.interleaved, name: 'Dynamic', defaultValue: '1234567890', supportedSizes: DEFAULT_BARCODE_SIZES, extraType: 'dynamic', hidden: true, upcType: UpcTypes.interleaved },
  { id: BarCodeId.qrCode, name: 'QRCode', defaultValue: '1234567890', supportedSizes: QR_BARCODE_SIZES },
  { id: BarCodeId.gs1, name: 'GS1BarStacked', defaultValue: '01000415730851861622080530001', supportedSizes: EAN_8_BARCODE_SIZES }
];

export const GS1_IDENTIFIERS: BarCodeIdentifiers[] = [
  { name: 'label_designer.barcode_identifiers.gtin', value: '01', defaultValue: '14 digits', type: 'text', relatedMapValues: ['label', 'NomeItem.barcode', 'RetailProduct.barcodeValue', 'NomeItem.CustomField'] },
  { name: 'label_designer.barcode_identifiers.batch', value: '10', defaultValue: 20, editable: true, hint: '1 to 20 digits', type: 'number', min: 1, max: 20, relatedMapValues: ['label', 'NomeItem.CustomField'] },
  { name: 'label_designer.barcode_identifiers.production_date', value: '11', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['label', 'discardDate', 'NomeItem.CustomField'] },
  { name: 'label_designer.barcode_identifiers.due_date', value: '12', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['label', 'discardDate', 'NomeItem.CustomField'] },
  { name: 'label_designer.barcode_identifiers.packaging_date', value: '13', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['label', 'discardDate', 'NomeItem.CustomField'] },
  { name: 'label_designer.barcode_identifiers.best_befor_date', value: '15', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['label', 'discardDate', 'NomeItem.CustomField'] },
  { name: 'label_designer.barcode_identifiers.sell_date', value: '16', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['label', 'discardDate', 'NomeItem.CustomField'] },
  { name: 'label_designer.barcode_identifiers.expiration_date', value: '17', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['label', 'discardDate', 'NomeItem.CustomField'] },
  {
    name: 'label_designer.barcode_identifiers.variable_count',
    value: '30',
    defaultValue: 3,
    editable: true,
    hint: '1 to 8 digits',
    type: 'number',
    min: 1,
    max: 8,
    relatedMapValues: ['label', 'NomeItem.barcode', 'NomeItem.CustomField']
  }
];

export const GS1_128_IDENTIFIERS: BarCodeIdentifiers[] = [
  { name: 'label_designer.barcode_identifiers.gtin', value: '01', defaultValue: '14 digits', type: 'text', relatedMapValues: ['label', 'barcode', 'RetailProduct.barcodeValue', 'NomeItem.CustomField'] },
  { name: 'label_designer.barcode_identifiers.production_date', value: '11', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'], supportedTenantTypes: [TenantTypeEnum.FOOD] },
  { name: 'label_designer.barcode_identifiers.due_date', value: '12', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'], supportedTenantTypes: [TenantTypeEnum.FOOD] },
  { name: 'label_designer.barcode_identifiers.packaging_date', value: '13', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'] },
  { name: 'label_designer.barcode_identifiers.best_befor_date', value: '15', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'], supportedTenantTypes: [TenantTypeEnum.FOOD] },
  { name: 'label_designer.barcode_identifiers.sell_date', value: '16', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'], supportedTenantTypes: [TenantTypeEnum.FOOD] },
  { name: 'label_designer.barcode_identifiers.expiration_date', value: '17', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'], supportedTenantTypes: [TenantTypeEnum.FOOD] }
];

export const GS1_128_BATCH_IDENTIFIERS: BarCodeIdentifiers[] = [
  {
    name: 'label_designer.barcode_identifiers.batch',
    value: '10',
    defaultValue: 20,
    editable: true,
    hint: '1 to 20 digits',
    type: 'number',
    min: 1,
    max: 20,
    relatedMapValues: ['label', 'NomeItem.CustomField', 'compositeField']
  },
  {
    name: 'label_designer.barcode_identifiers.serial_number',
    value: '21',
    relatedMapValues: ['label', 'NomeItem.CustomField', 'compositeField']
  },
  {
    name: 'label_designer.barcode_identifiers.net_weight_kg',
    value: '310n',
    relatedMapValues: ['label', 'NomeItem.CustomField', 'compositeField']
  },
  {
    name: 'label_designer.barcode_identifiers.net_weight_lb',
    value: '320n',
    relatedMapValues: ['label', 'NomeItem.CustomField', 'compositeField']
  }
];

export const EPC_DATE_IDENTIFIERS: BarCodeIdentifiers[] = [
  { name: 'label_designer.barcode_identifiers.production_date', value: '11', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'] },
  { name: 'label_designer.barcode_identifiers.packaging_date', value: '13', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'] },
  { name: 'label_designer.barcode_identifiers.best_befor_date', value: '15', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'] },
  { name: 'label_designer.barcode_identifiers.sell_date', value: '16', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'] },
  { name: 'label_designer.barcode_identifiers.expiration_date', value: '17', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'] },
  { name: 'label_designer.barcode_identifiers.first_freeze_date', value: '7006', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'] },
  { name: 'label_designer.barcode_identifiers.harvest_date', value: '7007', defaultValue: 'YYMMDD', type: 'text', relatedMapValues: ['discardDate', 'currentDate'] }
];

export const EPC_BATCH_IDENTIFIERS: BarCodeIdentifiers[] = [{ name: 'label_designer.barcode_identifiers.batch', value: '10' }];
export const EPC_NET_WEIGHT_IDENTIFIERS: BarCodeIdentifiers[] = [
  { name: 'label_designer.barcode_identifiers.net_weight_kg', value: '(310n)' },
  { name: 'label_designer.barcode_identifiers.net_weight_lb', value: '(320n)' }
];
export const SUPPORTED_NUTRITION_FACTS = [
  {
    name: 'Vertical',
    type: 'nutritionFacts',
    url: '/assets/images/label-designer/NutritionFact.png',
    dimensions: { width: 2.075, height: 4.174 }
  },
  {
    name: 'Linear',
    type: 'nutritionFactsLinear',
    url: '/assets/images/label-designer/NutritionFacts_LinearDisplay.png',
    dimensions: { width: 2, height: 0.69 }
  },
  {
    name: 'Tabular',
    type: 'nutritionFactsTabular',
    url: '/assets/images/label-designer/NutritionFacts_TabularDisplay.png',
    dimensions: { width: 4.9, height: 1.335 }
  },
  //Simplified Nutrition facts
  {
    name: 'Vertical (Simplified)',
    type: 'nutritionFactsSimplified',
    url: '/assets/images/label-designer/NutritionFact-Simplified.png',
    dimensions: { width: 2.075, height: 3.0825 }
  }
];

export const LABEL_PADDING = 0.0625;
export const SUPPORTED_DISCARD_DATE_TYPES = [
  { id: DiscardShelfLifeEnum.preppingDate, name: 'discard_date_types.prepping' },
  { id: DiscardShelfLifeEnum.killDate, name: 'discard_date_types.kill' },
  { id: DiscardShelfLifeEnum.defrostDate, name: 'discard_date_types.defrost' },
  { id: DiscardShelfLifeEnum.thawingDate, name: 'discard_date_types.thawed' },
  { id: DiscardShelfLifeEnum.heatingDate, name: 'discard_date_types.heated' },
  { id: DiscardShelfLifeEnum.customDate, name: 'discard_date_types.custom' }
];
export enum preCondition {
  groupTag = 'groupTag',
  printTime = 'printTime',
  discardDate = 'discardDate'
}

export enum operands {
  like = 'like',
  equals = 'equals',
  between = 'between'
}

export enum characterCountPositionEnum {
  left = 'left',
  right = 'right',
  middle = 'middle'
}

export const SUPPORTED_CHARACTER_COUNT_POSITIONS = [
  { value: characterCountPositionEnum.left, label: 'label_designer.left' },
  { value: characterCountPositionEnum.right, label: 'label_designer.right' },
  { value: characterCountPositionEnum.middle, label: 'label_designer.middle' }
];

export enum characterCountTypeEnum {
  partialText = 'partialText',
  padding = 'padding'
}

export const SUPPORTED_CHARACTER_COUNT_TYPES = [
  { value: characterCountTypeEnum.padding, label: 'label_designer.padding' },
  { value: characterCountTypeEnum.partialText, label: 'label_designer.partial_text' }
];

export const SUPPORTED_PRECONDITIONS = [
  { id: preCondition.discardDate, name: 'label_designer.usable_objects.section_items.discard_date' },
  { id: preCondition.groupTag, name: 'label_designer.group_tag' },
  { id: preCondition.printTime, name: 'label_designer.print_time' }
];

export const SUPPORTED_OPERANDS = [
  { id: operands.like, name: 'label_designer.contains', relatePrecondition: preCondition.groupTag },
  { id: operands.equals, name: 'label_designer.equals', relatePrecondition: preCondition.groupTag },
  { id: operands.between, name: 'label_designer.between', relatePrecondition: preCondition.printTime },
  { id: operands.between, name: 'label_designer.between', relatePrecondition: preCondition.discardDate }
];

export const DATE_LOCALES = [
  { translationKey: LOCALES.get('en').translationKey, value: LOCALES.get('en').code, jsonFileCode: 'en' },
  { translationKey: LOCALES.get('zh').translationKey, value: LOCALES.get('zh').code, jsonFileCode: 'zh' },
  { translationKey: LOCALES.get('cs').translationKey, value: LOCALES.get('cs').code, jsonFileCode: 'cs' },
  { translationKey: LOCALES.get('nl').translationKey, value: LOCALES.get('nl').code, jsonFileCode: 'nl' },
  { translationKey: LOCALES.get('fr-CA').translationKey, value: LOCALES.get('fr-CA').code, jsonFileCode: 'fr-CA' },
  { translationKey: LOCALES.get('fr-FR').translationKey, value: LOCALES.get('fr-FR').code, jsonFileCode: 'fr-FR' },
  { translationKey: LOCALES.get('de').translationKey, value: LOCALES.get('de').code, jsonFileCode: 'de' },
  { translationKey: LOCALES.get('it').translationKey, value: LOCALES.get('it').code, jsonFileCode: 'it' },
  { translationKey: LOCALES.get('ja').translationKey, value: LOCALES.get('ja').code, jsonFileCode: 'ja' },
  { translationKey: LOCALES.get('pl').translationKey, value: LOCALES.get('pl').code, jsonFileCode: 'pl' },
  { translationKey: LOCALES.get('pt').translationKey, value: LOCALES.get('pt').code, jsonFileCode: 'pt' },
  { translationKey: LOCALES.get('sk').translationKey, value: LOCALES.get('sk').code, jsonFileCode: 'sk' },
  { translationKey: LOCALES.get('es-MX').translationKey, value: LOCALES.get('es-MX').code, jsonFileCode: 'es-MX' },
  { translationKey: LOCALES.get('es-ES').translationKey, value: LOCALES.get('es-ES').code, jsonFileCode: 'es-ES' }
];

export const UOM_FORMATS = [
  { label: 'label_designer.grams_only', value: 'g' },
  { label: 'label_designer.ounces_only', value: 'oz' },
  { label: 'label_designer.pounds_only', value: 'lb' },
  { label: 'label_designer.pounds_ounces', value: 'lb,oz' },
  { label: 'label_designer.automatic', value: 'automatic' }
];

export const UOM_FORMATS_WITH_KG = [
  { label: 'label_designer.grams_only', value: 'g' },
  { label: 'label_designer.ounces_only', value: 'oz' },
  { label: 'label_designer.pounds_only', value: 'lb' },
  { label: 'label_designer.kilograms_only', value: 'kg' },
  { label: 'label_designer.pounds_ounces', value: 'lb,oz' },
  { label: 'label_designer.automatic', value: 'automatic' }
];

export const UPC_UOM_FORMATS = [
  { label: 'label_designer.grams', value: 'g' },
  { label: 'label_designer.kilograms', value: 'kg' },
  { label: 'label_designer.ounces', value: 'oz' },
  { label: 'label_designer.pounds', value: 'lb' }
];

export const WIDGET_TYPES = [
  { id: WidgetTypes.NUTRITION_FACT, name: 'label_designer.new_widget_dialog.nutrition_fact', map_value: 'NutritionalPanel' } //,
  //{id: WidgetTypes.CUSTOM, name: 'label_designer.new_widget_dialog.custom', map_value: 'Custom'}
];

export const FONT_FAMILIES: FontSettings[] = [
  {
    viewName: 'HelveticaRegular-v3',
    regularFont: {
      name: 'HelveticaLTPro-Roman',
      file: null,
      designerSettings: { boldCss: false, italicCss: false, fontPadding: 0.008 }
    },
    boldFont: {
      name: 'HelveticaLTPro-Black',
      file: null,
      designerSettings: { boldCss: false, italicCss: false, fontPadding: 0.008 }
    },
    italicFont: {
      name: 'HelveticaLTPro-Oblique',
      file: null,
      designerSettings: { boldCss: false, italicCss: false, fontPadding: 0.008 }
    },
    boldItalicFont: {
      name: 'HelveticaLTPro-BlackOblique',
      file: null,
      designerSettings: { boldCss: false, italicCss: false, fontPadding: 0.008 }
    },
    fallbackFont: 'HelveticaRegular-v2',
    format: 'otf',
    enabledForLabel: true,
    enabledForWidget: true,
    showForWidget: true,
    showForLabel: true,
    defaultForLabel: false,
    defaultForWidget: true,
    accountIds: [],
    isUsedForFallBackFonts: true
  },
  {
    viewName: 'Neue-v3',
    regularFont: {
      name: 'NHaasGroteskDSPro-65Md',
      file: null,
      designerSettings: { boldCss: false, italicCss: false, fontPadding: 0 }
    },
    boldFont: {
      name: 'NHaasGroteskDSPro-95Blk',
      file: null,
      designerSettings: { boldCss: false, italicCss: false, fontPadding: 0 }
    },
    italicFont: {
      name: 'NHaasGroteskDSPro-66MdIt',
      file: null,
      designerSettings: { boldCss: false, italicCss: false, fontPadding: 0 }
    },
    boldItalicFont: {
      name: 'NHaasGroteskDSPro-96BlkIt',
      file: null,
      designerSettings: { boldCss: false, italicCss: false, fontPadding: 0 }
    },
    fallbackFont: 'NeueRegular',
    format: 'otf',
    enabledForLabel: true,
    enabledForWidget: true,
    showForWidget: true,
    showForLabel: true,
    defaultForLabel: true,
    defaultForWidget: false,
    accountIds: [],
    isUsedForFallBackFonts: true
  },
  {
    viewName: 'Bright-Sight-v2',
    regularFont: {
      name: 'BRIGHTSIGHT02',
      file: null,
      designerSettings: { boldCss: false, italicCss: false, fontPadding: 0 }
    },
    boldFont: {
      name: 'BRIGHTSIGHT02',
      file: null,
      designerSettings: { boldCss: false, italicCss: false, fontPadding: 0 }
    },
    italicFont: {
      name: 'BRIGHTSIGHT02',
      file: null,
      designerSettings: { boldCss: false, italicCss: false, fontPadding: 0 }
    },
    boldItalicFont: {
      name: 'BRIGHTSIGHT02',
      file: null,
      designerSettings: { boldCss: false, italicCss: false, fontPadding: 0 }
    },
    fallbackFont: null,
    format: 'otf',
    enabledForLabel: true,
    enabledForWidget: false,
    showForWidget: true,
    showForLabel: true,
    defaultForLabel: false,
    defaultForWidget: false,
    disableCssButtons: true,
    accountIds: [],
    isUsedForFallBackFonts: true
  },
  {
    viewName: 'HelveticaRegular-v2',
    regularFont: {
      name: 'HelveticaRegular-v2',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    boldFont: {
      name: 'HelveticaBold',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    italicFont: {
      name: 'HelveticaRegular-v2',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    boldItalicFont: {
      name: 'HelveticaRegular-v2',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    fallbackFont: null,
    format: 'ttf',
    enabledForLabel: false,
    enabledForWidget: false,
    showForWidget: true,
    showForLabel: true,
    defaultForLabel: false,
    defaultForWidget: false,
    accountIds: []
  },
  {
    viewName: 'Neue',
    regularFont: {
      name: 'NeueRegular',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    boldFont: {
      name: 'NeueBold',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    italicFont: {
      name: 'NeueRegular',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    boldItalicFont: {
      name: 'NeueRegular',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    fallbackFont: null,
    format: 'ttf',
    enabledForLabel: false,
    enabledForWidget: false,
    showForWidget: true,
    showForLabel: true,
    defaultForLabel: false,
    defaultForWidget: false,
    accountIds: []
  },
  {
    viewName: 'Helvetica-Light',
    regularFont: {
      name: 'Helvetica-Light',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    boldFont: {
      name: 'Helvetica-Light',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    italicFont: {
      name: 'Helvetica-Light',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    boldItalicFont: {
      name: 'Helvetica-Light',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    fallbackFont: null,
    format: 'ttf',
    enabledForLabel: false,
    enabledForWidget: false,
    showForWidget: true,
    showForLabel: true,
    defaultForLabel: false,
    defaultForWidget: false,
    accountIds: []
  },
  {
    viewName: 'Helvetica Regular',
    regularFont: {
      name: 'HelveticaRegular',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    boldFont: {
      name: 'HelveticaRegular',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    italicFont: {
      name: 'HelveticaRegular',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    boldItalicFont: {
      name: 'HelveticaRegular',
      file: null,
      designerSettings: { boldCss: true, italicCss: true, fontPadding: 0 }
    },
    fallbackFont: null,
    format: 'ttf',
    enabledForLabel: false,
    enabledForWidget: false,
    showForWidget: true,
    showForLabel: true,
    defaultForLabel: false,
    defaultForWidget: false,
    accountIds: []
  }
];
export const fALL_BACK_FONTS = [
  { name: 'HelveticaLTPro-Roman', fallback: 'HelveticaRegular-v2' },
  { name: 'HelveticaLTPro-Black', fallback: 'HelveticaBold' },
  { name: 'HelveticaLTPro-Oblique', fallback: 'HelveticaBold' },
  { name: 'HelveticaLTPro-BlackOblique', fallback: 'HelveticaBold' },
  { name: 'NHaasGroteskDSPro-65Md', fallback: 'NeueRegular' },
  { name: 'NHaasGroteskDSPro-95Blk', fallback: 'NeueBold' },
  { name: 'NHaasGroteskDSPro-66MdIt', fallback: 'NeueRegular' },
  { name: 'NHaasGroteskDSPro-96BlkIt', fallback: 'NeueRegular' }
];

// ComponentTypes.DATE = currentDate not 'date'
export const OBJECTS_ALLOWED_FOR_REMOTE_PRINTING = [ComponentTypes.LABEL, ComponentTypes.BARCODE, ComponentTypes.NEWBARCODE, ComponentTypes.DATE, 'date'];

export const OBJECT_TYPE_ICON = [
  { key: ComponentTypes.ADDON_BARCODE_VALUE, value: 'addons' },
  { key: ComponentTypes.STORE_ADDRESS, value: 'address' },
  { key: ComponentTypes.STORE_ADDRESS_2, value: 'address' },
  { key: ComponentTypes.ALLERGENS, value: 'allergens' },
  { key: ComponentTypes.BARCODE_VALUE, value: 'barcode-value' },
  { key: ComponentTypes.ADDON_BARCODE_VALUE, value: 'barcode-value' },
  { key: ComponentTypes.PRODUCT_BARCODE_VALUE, value: 'barcode-value' },
  { key: ComponentTypes.BARCODE, value: 'barcode' },
  { key: ComponentTypes.NEWBARCODE, value: 'barcode' },
  { key: ComponentTypes.NOME_ITEM_BARCODE, value: 'barcode' },
  { key: ComponentTypes.BATCH_COUNT, value: 'batch-count' },
  { key: ComponentTypes.CALCULATED_FIELD, value: 'calculated-field' },
  { key: ComponentTypes.NEW_CALCULATED_FIELD, value: 'calculated-field' },
  { key: ComponentTypes.ADDON_CALCULATED_FIELD, value: 'calculated-field' },
  { key: ComponentTypes.PRODUCT_CALCULATED_FIELD, value: 'calculated-field' },
  { key: ComponentTypes.NEW_ADDON_CALCULATED_FIELD, value: 'calculated-field' },
  { key: ComponentTypes.NEW_PRODUCT_CALCULATED_FIELD, value: 'calculated-field' },
  { key: ComponentTypes.CONDITIONAL_LABEL, value: 'conditional-text' },
  { key: ComponentTypes.MENUITEM_CALORIES, value: 'calories' },
  { key: ComponentTypes.PRODUCT_CAPTION, value: 'caption' },
  { key: ComponentTypes.ITEM_SEC_PRINT_NAME, value: 'caption' },
  { key: ComponentTypes.ADDON_COMPOSITE_FIELD_CAPTION, value: 'caption' },
  { key: ComponentTypes.ITEM_PRINT_NAME, value: 'caption' },
  { key: ComponentTypes.CATEGORY, value: 'category' },
  { key: ComponentTypes.SEC_CATEGORY, value: 'category' },
  { key: ComponentTypes.STORE_CITY, value: 'city' },
  { key: ComponentTypes.PRODUCT_COLOR, value: 'color' },
  { key: ComponentTypes.COMPOSITE_FIELD, value: 'composite-field' },
  { key: ComponentTypes.ADDON_COMPOSITE_FIELD, value: 'addons' },
  { key: ComponentTypes.PRODUCT_CURRENT_PRICE, value: 'current-price' },
  { key: ComponentTypes.ITEM_PRICE, value: 'price' },
  { key: ComponentTypes.PRODUCT_ORIGINAL_PRICE, value: 'price' },
  { key: ComponentTypes.PRICE_PER_WEIGHT, value: 'price-per-weight' },

  { key: ComponentTypes.DATE, value: 'date' },
  { key: ComponentTypes.DISCARD_DATE, value: 'discard-date' },
  { key: ComponentTypes.REFERENCE_DISCARD_DATE, value: 'discard-date' },
  { key: ComponentTypes.END_DATE, value: 'end-date' },
  { key: ComponentTypes.PRODUCT_END_DATE, value: 'end-date' },
  { key: ComponentTypes.ITEM_SHELF_LIFE, value: 'end-date' },
  { key: ComponentTypes.ITEM_SHELF_LIFE_HOURS, value: 'expiration-date' },
  { key: ComponentTypes.ADDON_SHELF_LIFE_HOURS, value: 'expiration-date' },
  { key: ComponentTypes.START_DATE, value: 'start-date' },
  { key: ComponentTypes.PRODUCT_START_DATE, value: 'start-date' },
  { key: ComponentTypes.CURRENT_DAY, value: 'date' },
  { key: ComponentTypes.KILL_DATE, value: 'date' },

  { key: ComponentTypes.CUSTOM_FIELDS, value: 'text-cf', typeId: CustomFieldTypeEnum.String },
  { key: ComponentTypes.CUSTOM_FIELDS, value: 'date-shelf-life-cf', typeId: CustomFieldTypeEnum.Int },
  { key: ComponentTypes.CUSTOM_FIELDS, value: 'decimal-cf', typeId: CustomFieldTypeEnum.Double },
  { key: ComponentTypes.CUSTOM_FIELDS, value: 'image-cf', typeId: CustomFieldTypeEnum.Image },
  { key: ComponentTypes.CUSTOM_FIELDS, value: 'list-cf', typeId: CustomFieldTypeEnum.List },
  { key: ComponentTypes.CUSTOM_FIELDS, value: 'serial-cf', typeId: CustomFieldTypeEnum.SerialNumber },
  { key: ComponentTypes.CUSTOM_FIELDS, value: 'price', typeId: CustomFieldTypeEnum.Price },
  { key: ComponentTypes.CUSTOM_FIELDS, value: 'list-image-cf', typeId: CustomFieldTypeEnum.ImageList },

  { key: ComponentTypes.PRODUCT_DEPARTMENT, value: 'department' },
  { key: ComponentTypes.ITEM_DESCRIPTION, value: 'description' },
  { key: ComponentTypes.PRODUCT_DESCRIPTION, value: 'description' },
  { key: ComponentTypes.ITEM_SEC_DESCRIPTION, value: 'description' },
  { key: ComponentTypes.ITEM_PREP_DESCRIPTION, value: 'prep-description' },
  { key: ComponentTypes.ITEM_USE_BY_DESCRIPTION, value: 'use-by-description' },

  { key: ComponentTypes.PRODUCT_DISCOUNT_RATE, value: 'discount-rate' },
  { key: ComponentTypes.PRODUCT_DISCOUNTED_CALCULATED_FIELD, value: 'discounted-calculated-field' },
  { key: ComponentTypes.DAY_OF_WEEK, value: 'dow' },
  { key: ComponentTypes.DYNAMIC_DAY_OF_WEEK, value: 'dow' },
  { key: ComponentTypes.STORE_EMAIL, value: 'email' },
  { key: ComponentTypes.EMPLOYEE_ID, value: 'employee-id' },
  { key: ComponentTypes.EMPLOYEE_NAME, value: 'employee-name' },
  { key: ComponentTypes.GROUP_TAGS, value: 'group-tags' },
  { key: ComponentTypes.IMAGE, value: 'image' },
  { key: ComponentTypes.INGREDIENTS, value: 'ingredients' },
  { key: ComponentTypes.STORE_LOGO_1, value: 'labeling-logo' },
  { key: ComponentTypes.STORE_LOGO_2, value: 'labeling-logo' },
  { key: ComponentTypes.LINE, value: 'line' },
  { key: ComponentTypes.STORE_NAME, value: 'name' },
  { key: ComponentTypes.ITEM_NET_WEIGHT, value: 'net-weight' },
  { key: ComponentTypes.ADDON_NET_WEIGHT, value: 'net-weight' },
  { key: ComponentTypes.STORE_NUMBER, value: 'number' },
  { key: ComponentTypes.WIDGET, value: 'nutritional-panel-widget' },
  { key: ComponentTypes.WIDGET_INFO_HOLDER, value: 'nutritional-panel-widget' },
  { key: ComponentTypes.WIDGET_NF, value: 'nutritional-panel-widget' },
  { key: ComponentTypes.STORE_PHONE, value: 'phone' },
  { key: ComponentTypes.RECIPES_FIELD, value: 'recipe-composition' },
  { key: ComponentTypes.RECTANGLE, value: 'rectangle' },
  { key: ComponentTypes.PRODUCT_SIZE, value: 'size' },
  { key: ComponentTypes.PRODUCT_SKU, value: 'sku' },
  { key: ComponentTypes.STORE_REGION, value: 'state' },
  { key: ComponentTypes.ITEM_TARE_WEIGHT, value: 'tare-weight' },
  { key: ComponentTypes.LABEL, value: 'text' },
  { key: ComponentTypes.TIMER_NAME, value: 'timer-name' },
  { key: ComponentTypes.VOICE_PICK, value: 'voice-pick-code' },
  { key: ComponentTypes.ITEM_SCALE_WEIGHT, value: 'weight-scale' },
  { key: ComponentTypes.STORE_ZIP, value: 'zip' }
];

export const DEFAULT_OBJECT_CUSTOM_CODE = `=f();

function f() {
    return "Hello World";
}`;

export const DEFAULT_OBJECT_CUSTOM_CODE_FOR_DATE = `=f();

function f() {
    return new Date();
}`;

export const DEFAULT_OBJECT_CUSTOM_CODE_FOR_BARCODE = `=f();

function f() {
    return "12345678901";
}`;

export const DEFAULT_OBJECT_CUSTOM_CODE_FOR_IMAGE = `=f();

function f() {
    return "{Field Name}";
}`;

export const BARCODE_TYPES_SUPPORTED_CUSTOM_CODE = ['Code39', 'Code128', 'Ean8', 'Ean13', 'Interleaved2of5', 'UPC-A'];
export let DEFAULT_LABEL_DATA: LabelPropertyOptions = {
  isContinuous: false,
  labelHeight: 0,
  labelWidth: 0,
  labelDisplayHeight: 0,
  labelDisplayWidth: 0,
  labelSizeId: 0,
  isContinuousPropInvalid: false,
  isContinuousNeedToChange: false,
  isLabelSizeChanged: false,
  autoRemoveAdditionalWhiteSpace: false,
  commissionRFID: false,
  labelSizeMetaData: {},
  isDesignerUOMChange: false,
  rfidSettings: {
    generateEPC: false,
    encodingType: EncodingTypesEnum.SGTIN96,
    serialNumber: SerialNumberSourceEnum.tag,
    filter: 0,
    partition: {
      selector: PartitionSelector.auto_calculate,
      value: null
    },
    companyPrefix: {
      selectedField: ComponentTypes.BARCODE,
      field: ComponentTypes.BARCODE,
      fieldFormat: '14 digits',
      format: null,
      value: null,
      referralId: null
    },
    atmaFieldMap: [],
    dateField: {
      type: ComponentTypes.DATE,
      epcIdentifier: EPC_DATE_IDENTIFIERS[0].value,
      format: 'YYMMDD',
      date_additional_format: 'YYMMDD',
      discardDate: {
        type: DiscardShelfLifeEnum.preppingDate
      }
    },
    serialNumberField: {
      encodingIndicator: 0,
      lengthIndicator: 0,
      serialNumberSource: SerialNumberSourceEnum.atma
    },
    optionalFields: []
  }
};

export const useSublocationFieldsType = [
  ComponentTypes.STORE_NAME,
  ComponentTypes.STORE_NUMBER,
  ComponentTypes.STORE_ADDRESS,
  ComponentTypes.STORE_ADDRESS_2,
  ComponentTypes.STORE_CITY,
  ComponentTypes.STORE_REGION,
  ComponentTypes.STORE_ZIP,
  ComponentTypes.STORE_EMAIL,
  ComponentTypes.STORE_PHONE
];

export const aliasValidationParams: validationParams[] = [{ key: { error: FormErrosEnum.maxLength }, overrideErrorMessage: 'form_validation.max_length' }];
export const fieldValidationParams: validationParams[] = [{ key: { name: 'type', error: FormErrosEnum.required }, overrideErrorMessage: 'form_validation.field_not_empty' }];
// for now this restriction file is not 100% complete these are initial settings that will change and each ttype will have different restriction from other types
// in case that was not provided the restriction data will have reformulated to combine common restriction together
export const atmaRestrictedData: { fieldType: FieldType; requiredComponentTypes?: any[]; excludedComponentTypes?: any[] }[] = [
  {
    fieldType: FieldType.Gtin,
    requiredComponentTypes: [ComponentTypes.LABEL, CustomField.Text, ComponentTypes.NOME_ITEM_BARCODE, ComponentTypes.BARCODE_VALUE, ComponentTypes.COMPOSITE_FIELD, ProductComponentTypes.BARCODE_VALUE]
  },
  { fieldType: FieldType.DateTime, requiredComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.DateTimeOffset, requiredComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.String, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.Boolean, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.Byte, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.Color, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.Decimal, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.Double, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.FileUpload, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.Float, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.ImageUrl, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.Int16, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.Int32, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.Int64, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.Text, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.UInt16, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.UInt32, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.UInt64, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] },
  { fieldType: FieldType.Url, excludedComponentTypes: [ComponentTypes.DATE, ComponentTypes.DISCARD_DATE, CustomField.DateShelfLife] }
];

export const catalogAtmaRestrictedData: { fieldType: FieldType; requiredComponentTypes?: any[]; excludedComponentTypes?: any[] }[] = [
  {
    fieldType: FieldType.Gtin,
    requiredComponentTypes: [ComponentTypes.LABEL, ComponentTypes.NOME_ITEM_BARCODE, CustomField.Text]
  },
  { fieldType: FieldType.DateTime, requiredComponentTypes: [ComponentTypes.DATE, ComponentTypes.PRODUCT_START_DATE, ComponentTypes.PRODUCT_END_DATE] },
  { fieldType: FieldType.DateTimeOffset, requiredComponentTypes: [ComponentTypes.DATE, ComponentTypes.PRODUCT_START_DATE, ComponentTypes.PRODUCT_END_DATE] },
  { fieldType: FieldType.String, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.Boolean, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.Byte, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.Color, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.Decimal, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.Double, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.FileUpload, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.Float, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.ImageUrl, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.Int16, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.Int32, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.Int64, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.Text, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.UInt16, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.UInt32, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.UInt64, excludedComponentTypes: [ComponentTypes.DATE] },
  { fieldType: FieldType.Url, excludedComponentTypes: [ComponentTypes.DATE] }
];

export const nameFormats = [
  new DropdownOption(NameFormat.FullName, 'name_format.full_name'),
  new DropdownOption(NameFormat.FirstName, 'name_format.first_name'),
  new DropdownOption(NameFormat.EmployeeInitials, 'name_format.employee_initials'),
  new DropdownOption(NameFormat.FirstNameLastInitial, 'name_format.first_name_last_initial'),
  new DropdownOption(NameFormat.FirstInitialLastName, 'name_format.first_initial_last_name'),
  new DropdownOption(NameFormat.LastNameFirstName, 'name_format.last_name_first_name'),
  new DropdownOption(NameFormat.LastNameFirstInitial, 'name_format.last_name_first_initial')
];
