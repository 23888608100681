<div class="container login-container" *ngIf="showContent">
  <div class="login-form">
    <div class="text-center"><img id="logo-container" [src]="brandImagesAssets + 'FullLogo.svg'" /></div>
    <div class="col" *ngIf="showForm; else confirmationMessage">
      <p *ngIf="!userLinked">{{ 'activate_page.instruction_message' | translate }}</p>
      <p *ngIf="userLinked">{{ 'activate_page.instruction_message_linked_user' | translate }}</p>
      <form class="rounded" [formGroup]="formGroup" (ngSubmit)="submitForm()">
        <p class="alert-message text-center" *ngIf="message">{{ message | translate }}</p>
        <div>
          <mat-form-field class="login-full-width" *ngIf="!userLinked">
            <nome-password-visibility-toggle #passwordEyeIcon matSuffix></nome-password-visibility-toggle>
            <input matInput [type]="passwordEyeIcon.inputType" formControlName="password" [placeholder]="'activate_page.password' | translate | titlecase" autocomplete="off" />
            <mat-error>
              {{ formGroup.get('password').errors | formErrors : translateService.currentLang : 'password' : validationParams }}
            </mat-error>
          </mat-form-field>
          <mat-password-strength #passwordStrength [password]="formGroup.get('password').value"></mat-password-strength>
          <nome-password-strength-info [passwordStrengthComponent]="passwordStrength"></nome-password-strength-info>
        </div>

        <mat-form-field class="login-full-width" *ngIf="!userLinked">
          <nome-password-visibility-toggle #confirmPasswordEyeIcon matSuffix></nome-password-visibility-toggle>
          <input matInput [type]="confirmPasswordEyeIcon.inputType" formControlName="confirmPassword" [placeholder]="'activate_page.confirm_password' | translate" autocomplete="off" />
          <mat-error>
            {{ formGroup.get('confirmPassword').errors | formErrors : translateService.currentLang : 'confirm-password' : validationParams }}
          </mat-error>
        </mat-form-field>
        <button mat-flat-button color="accent" [disabled]="!formGroup.valid || passwordStrength.strength < 100" type="submit" id="login-button" class="btn btn-def btn-block">
          {{ 'activate_page.activate_button' | translate }}
        </button>
      </form>
    </div>
    <ng-template #confirmationMessage>
      <p class="m-0">{{ 'activate_page.success_you_have_been_registered' | translate }}</p>
      <p>{{ 'activate_page.user_email_address' | translate }}</p>
      <button id="login-button" class="btn btn-def btn-block accent-text-color" (click)="goToLoginPage()">{{ 'activate_page.go_to_login_page' | translate }}</button>
    </ng-template>
  </div>
</div>
