import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { AuthService } from '../../core/auth/auth.service';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../shared/services/api/api.service';
import { appendQueryParamIfDefined, definedAndNotEmptyString } from '../../shared/helpers/app.helpers';

import { BrandNode } from './models/node.model';
import { User } from '../../shared/models/user.model';
import { BrandModel } from '../../shared/models/brand-model';
import { ArrayResponse } from './models/array-response.model';
import { DivisionDetails } from './models/division-details.model';
import { APIResponseModel } from '../../shared/models/api-response';
import { DivisionBrand } from './models/division-brand-details.model';
import { DistributionCompany } from './models/distribution-company.model';
import { CountryCodesModel } from '../../shared/models/country-codes.model';
import { TimezoneCodesModel } from '../../shared/models/timezone-codes.model';
import { DistributionCompanyTree } from './models/distribution-company-tree.model';
import { PaginationResponse } from '../../shared/models/pagination-response.model';

@Injectable({
  providedIn: 'root'
})
export class DistributionManagerService {
  get baseServiceUrl(): string {
    return `${this.apiService.ocelotApi}DC/api/`;
  }

  get accountId(): number {
    return this.authService.getCurrentUserAccountId();
  }

  private _distributionCompanyId: string;

  countries: CountryCodesModel[];
  timezones: TimezoneCodesModel[];

  brands = new Map<number, any>();

  constructor(private apiService: ApiService, private authService: AuthService) {}

  // Used inside our DistributionCompanyResolverService
  getDistributionCompanyDetailsByAccountId(): Observable<DistributionCompany> {
    const url = `${this.baseServiceUrl}DistributionCompanies/Tenant/${this.accountId}`;

    return this.apiService.getRequest(url).pipe(
      map((res: APIResponseModel<DistributionCompany>) => {
        this._distributionCompanyId = res.results.id;
        return res.results as DistributionCompany;
      })
    );
  }

  //#region Lookups

  getCountriesList(): Observable<CountryCodesModel[]> {
    const url = `${this.baseServiceUrl}Lookups/Countries`;

    if (!this.countries || this.countries.length < 1) {
      return this.apiService.getRequest(url).pipe(
        map((response) => {
          this.countries = response.results;
          return response.results;
        })
      );
    } else {
      return of(this.countries);
    }
  }

  getTimeZones(): Observable<TimezoneCodesModel[]> {
    const url = `${this.baseServiceUrl}Lookups/Timezones`;

    if (!this.timezones || this.timezones.length < 1) {
      return this.apiService.getRequest(url).pipe(
        map((response) => {
          this.timezones = response.results;
          return response.results;
        })
      );
    } else {
      return of(this.timezones);
    }
  }

  //#endregion

  //#region Division Details Section

  getDistributionCompanyDivisions(startIndex?: number, itemsPerPage?: number, searchKeyword?: string): Observable<PaginationResponse<any[]>> {
    let url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Divisions?start=${startIndex}&limit=${itemsPerPage}`;

    if (definedAndNotEmptyString(searchKeyword)) {
      url = appendQueryParamIfDefined(url, 'SearchKeyword', searchKeyword);
      url = appendQueryParamIfDefined(url, 'SearchBy', 'name');
    }

    return this.apiService.getRequest<PaginationResponse<any[]>>(url).pipe(map((response) => response.results));
  }

  getDistributionCompanyBrands(): Observable<BrandModel[]> {
    const url = `${environment.baseAPIUrl}${environment.version}/accounts/${this.accountId}/brands`;

    return this.apiService.getRequest<APIResponseModel<BrandModel[]>>(url).pipe(
      map((response: any) => {
        response.results.records.brands.map((brand: BrandModel) => {
          this.brands.set(brand.brandId, { name: brand.name, logoURL: brand.logoURL });
        });

        return response.results.records.brands;
      })
    );
  }

  createDistributionCompanyDivision(division: DivisionDetails): Observable<DivisionDetails> {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Divisions`;

    return this.apiService.postRequest(url, division).pipe(
      map((response) => {
        return response.results;
      })
    );
  }

  updateDistributionCompanyDivision(division: DivisionDetails): Observable<DivisionDetails> {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Divisions/${division.id}`;

    return this.apiService.putRequest(url, division).pipe(
      map((response) => {
        return response.results;
      })
    );
  }

  getDivisionDetails(divisionId: string): Observable<DivisionDetails> {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Divisions/${divisionId}`;

    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  //#endregion

  //#region Brand Details Section

  createDistributionCompanyDivisionBrand(divisionId: string, brand: DivisionBrand) {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Divisions/${divisionId}/DivisionBrands`;

    return this.apiService.postRequest(url, brand).pipe(
      map((response) => {
        return response.results;
      })
    );
  }

  getBrandDetails(divisionId: string, brandId: number): Observable<DivisionBrand> {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Divisions/${divisionId}/DivisionBrands/${brandId}`;

    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  updateDistributionCompanyDivisionBrand(divisionId: string, brand: any) {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Divisions/${divisionId}/DivisionBrands/${brand.id}`;

    return this.apiService.putRequest(url, brand).pipe(
      map((response) => {
        return response.results;
      })
    );
  }

  updateBrandStatus(brand: BrandNode) {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Divisions/${brand['parentNodeId']}/DivisionBrands/${brand.id}/Status`;

    return this.apiService.putRequest(url, { isActive: !brand.isActive }).pipe(
      map((response) => {
        return response.results;
      })
    );
  }

  //#endregion

  //#region Users Page

  getDistributionCompanyTree(): Observable<DistributionCompanyTree> {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Tree`;

    return this.apiService.getRequest(url).pipe(
      map((res: APIResponseModel<DistributionCompanyTree>) => {
        return res.results;
      })
    );
  }

  getDistributionCompanyAllUsers(searchKeyword: string = null): Observable<User[]> {
    let url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Users`;
    url = appendQueryParamIfDefined(url, 'searchKeyword', searchKeyword);
    return this.apiService.getRequest<ArrayResponse<User[]>>(url).pipe(
      map((res) => {
        return res.results.result;
      })
    );
  }

  getDistributionCompanyAssignedUsers(): Observable<User[]> {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/AssignedUsers`;

    return this.apiService.getRequest<ArrayResponse<User[]>>(url).pipe(
      map((res) => {
        return res.results.result;
      })
    );
  }
  updateDistributionCompanyUsers(body: { assignedUserIds: number[]; unassignedUserIds: number[] }): Observable<any> {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/AssignedUsers`;

    return this.apiService.putRequest(url, body).pipe(
      map((res: APIResponseModel<any>) => {
        return res;
      })
    );
  }

  getDivisionAssignedUsers(divisionId: string): Observable<User[]> {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Divisions/${divisionId}/AssignedUsers`;

    return this.apiService.getRequest<ArrayResponse<User[]>>(url).pipe(
      map((res) => {
        return res.results.result;
      })
    );
  }
  updateDivisionUsers(divisionId: string, body: { assignedUserIds: number[]; unassignedUserIds: number[] }): Observable<any> {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Divisions/${divisionId}/AssignedUsers`;

    return this.apiService.putRequest(url, body).pipe(
      map((res: APIResponseModel<any>) => {
        return res;
      })
    );
  }

  getDivisionBrandAssignedUsers(divisionId: string, brandId: string): Observable<User[]> {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Divisions/${divisionId}/DivisionBrands/${brandId}/AssignedUsers`;

    return this.apiService.getRequest<ArrayResponse<User[]>>(url).pipe(
      map((res) => {
        return res.results.result;
      })
    );
  }
  updateDivisionBrandUsers(divisionId: string, brandId: string, body: { assignedUserIds: number[]; unassignedUserIds: number[] }): Observable<any> {
    const url = `${this.baseServiceUrl}DistributionCompanies/${this._distributionCompanyId}/Divisions/${divisionId}/DivisionBrands/${brandId}/AssignedUsers`;

    return this.apiService.putRequest(url, body).pipe(
      map((res: APIResponseModel<any>) => {
        return res;
      })
    );
  }

  //#endregion
}
