export enum CustomField { // The values are derived from the BE endpoint /params/customfieldtypes & are used for translation
  Decimal = 1,
  Text = 2,
  DateShelfLife = 3,
  Image = 4,
  List = 5,
  SerialNumber = 6,
  Price = 7,
  ImageList = 8
}
