import { MatPasswordStrengthComponent, MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TitleCaseModule } from '../../../../pipes/title-case/title-case.module';

@Component({
  selector: 'nome-password-strength-info',
  templateUrl: './password-strength-info.component.html',
  styleUrls: ['./password-strength-info.component.scss'],
  standalone: true,
  imports: [MatPasswordStrengthModule, TranslateModule, TitleCaseModule]
})
export class PasswordStrengthInfoComponent {
  @Input() passwordStrengthComponent: MatPasswordStrengthComponent;
}
