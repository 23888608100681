export enum LocalStorageFlags {
  RecipeManagementSupported = 'recipeManagementSupported',
  HasAdvancedChecklistActivated = 'hasAdvancedChecklistActivated',
  HasOrdersActivated = 'hasOrdersActivated',
  punchoutSession = 'isPunchoutLogin',
  punchOutMode = 'punchOutMode',
  punchOutRequestId = 'punchOutRequestId',
  CanSetPasswords = 'canSetPasswords',
  ImpersonatedCanSetPasswords = 'impersonatedCanSetPasswords',
  TempDashboardHierarchyId = 'temp_v2_hierarchy_value',
  KnowledgeBaseToken = 'kbToken',
  SenseTemperatureUOM = 'senseTemperatureUOM',
  TempTemperatureUOM = 'tempTemperatureUOM',
  hasAddons = 'hasAddons',
  hasDualNutrionalPanels = 'hasDualNutrionalPanels',
  KnowledgeBaseEnabled = 'kbEnabled',
  KnowledgeBaseURL = 'kbURL',
  supportChatEnabled = 'supportChatEnabled',
  defaultLang = 'defaultLang',
  hasLabelingActivated = 'hasLabelingActivated',
  hasMediaActivated = 'hasMediaActivated',
  hasTimersActivated = 'hasTimersActivated',
  hasTempConfActivated = 'hasTempConfActivated',
  hasSenseConfActivated = 'hasSenseConfActivated',
  hasChecklistActivated = 'hasChecklistActivated',
  hasDistributionCentersActivated = 'hasDistributionCentersActivated',
  hasDistributionUsersActivated = 'hasDistributionUsersActivated',
  hasOrderManagementActivated = 'hasOrderManagementActivated',
  hasInventoryReportingActivated = 'hasInventoryReportingActivated',
  requirePinForUsers = 'requirePinForUsers',
  accountUnitOfMeasure = 'temperatureUOM',
  parentAuthToken = 'parentUserToken',
  parentRefreshToken = 'parentRefreshToken',
  hasRemotePrinting = 'hasRemotePrinting',
  hasMultiStoreCodeActivated = 'hasMultiStoreCodeActivated',
  hasScaleModels = 'hasScaleModels',
  authenticateUsingKeycloakOIDC = 'authenticateUsingKeycloakOIDC',
  resellerSubRoute = 'resellerSubroute',
  resellerAlias = 'resellerAlias',
  SenseSubscriberDropdownValue = 'sense_subscriber_dropdown_value',
  SenseSubscriberStoreDropdownValue = 'sense_subscriber_store_dropdown_value',
  CurrentBrand = 'currentBrand',
  HasExternalAuthentication = 'hasExternalAuthentication',
  useNewSubscriptionFlow = 'useNewSubscriptionFlow',
  resellerName = 'resellerName',
  resellerId = 'resellerId',
  identityServerURL = 'identityServerURL',
  resellerLoginId = 'resellerLoginId',
  AccountCompanyNameInc = 'account-company-name-inc',
  AccountTermsAndConditionsLink = 'account-terms-and-conditions-link',
  AccountPrivacyPolicyLink = 'account-privacy-policy-link',
  LinkedAccounts = 'linkedAccounts',
  ShowSubscriberTempAnalytics = 'showSubscriberTempAnalytics',
  ShowSubscriberSenseAnalytics = 'showSubscriberSenseAnalytics',
  ShowSubscriberDevices = 'showSubscriberDevices',
  SupportId = 'supportId',
  AllowCustomCode = 'allowCustomCode',
  // hasRecipes = 'hasRecipes',
  hasDailyProduction = 'hasDailyProduction',
  hasSubLocation = 'hasSubLocation',
  hasSecondaryLanguage = 'hasSecondaryLanguage',
  // hasMenus = 'hasMenus',
  // hasProducts = 'hasProducts',
  hasBatchPrinting = 'hasBatchPrinting',
  hasRFIDSupport = 'hasRfidSupport',
  nutritionalPanelCount = 'nutritionalPanelCount',
  hasFixedReaders = 'hasFixedReaders',
  hasTempActivated = 'hasTempActivated',
  IsReseller = 'isReseller',
  HasExternalInterface = 'hasExternalInterface',
  CanManageUnassignRestrictionForSubscribers = 'manageUnassignRestrictionForSubscribers',
  HasAutomaticUserMLCGeneration = 'hasAutomaticUserMLCGeneration'
}
