import { AbstractControl, UntypedFormGroup, NgControl, Validators } from '@angular/forms';
import { NutritionFact } from './../../modules/nutrition-facts/models/nutrition-fact.model';
import { CommonTableRow } from './../models/common-table-row.model';
import { StackedColumn, TableDataItem } from './../models/table-data-item.model';
import { getMinutes, addMinutes, addSeconds, getSeconds, format } from 'date-fns';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { environment } from '../../../environments/environment';
import { Temperature, TemperatureUomId } from './../../modules/analytics/models/Temperature.enum';
import { TemperatureRule } from '../models/enums/TemperatureRule.enum';
import * as Enumerable from 'linq-es2015';
import { TreeNode } from '../models/Tree-Node.model';
import { KeyValue } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Operator, Operators, timeOperation, TimeOperator } from '../../shared/models/enums/operators';
import { EMAIL_REGEX, HOURS_COLOR_PALETTE } from '../constants.config';
import { AllowedCondition, Card, CardButton, CardButtonSelection, MenuButtons, ToolbarButton } from '../models/card.model';
import { CardActions } from '../models/cardActions.enum';
import { ItemDisplayOption } from '../models/item-display-option.model';
import { UnitOfMeasure } from '../models/UnitOfMeasure.model';
import { ElementRef } from '@angular/core';
const KolorWheel = require('../../../assets/scripts/kolorWheel.js');
import * as moment from 'moment';
import { AuthService } from './../../core/auth/auth.service';
import { ActionsData } from '../classes/cards-base.dictionary';
import { KeyValue as LocalKeyValue } from '../models/key-value.model';
import { LocalStorageFlags } from '../models/enums/local-storage-flags.enum';
import { Subject } from 'rxjs';
import { UnitsOfMeasureEnum } from './../../shared/models/enums/units-of-measure.enum';
import { ItemDisplayOptionsEnum } from './../../shared/models/enums/item-display-options.enum';
import { TempWorkflowTemplateInputTypes } from '../../modules/settings/models/input-types.enum';
import { InputTypeModel } from '../models/input-type.model';
import { SessionModel } from '../models/session.model';
import { SessionOption } from '../../modules/checklist/models/session-option.model';
import { Button as DialogButton } from '../modules/buttons-generator/models/button.model';
import { Button, ButtonName } from '../models/button.model';
import { ButtonType } from '../modules/buttons-generator/enums/button-type.enum';
import { Case } from '../models/enums/case.enum';
import { MaterialColor } from '../models/enums/material-color.enum';
import { secondsToHoursAndMinutes } from '../../modules/label-designer/helpers/date.helper';
import { unescape } from 'lodash';
import { RecurrenceType } from '../../modules/checklist/enums/recurrence-type.enum';
import { NomeCustomFloatButtonComponent } from './../../core/nome-custom-float-button/nome-custom-float-button.component';
import { ConfigData } from '../../core/config-data';

export function defined(entity: any): boolean {
  return entity !== null && entity !== undefined;
}

export function isNumber(entity: any): entity is Number {
  return defined(entity) && entity.constructor.toString().indexOf('Number') != -1;
}

export function isDefinedAndPositiveNumber(entity: number) {
  return defined(entity) && isNumber(entity) && entity > 0;
}

export function isDefinedAndNegativeNumber(entity: number): boolean {
  return defined(entity) && isNumber(entity) && entity < 0;
}

export function notDefined(entity: any): boolean {
  return entity === null || entity === undefined;
}

export function definedAndNotEmptyString(entity: any): boolean {
  return stringIsDefinedAndNotEmpty(entity);
}

export function stringIsDefinedAndNotEmpty(text: string): boolean {
  return defined(text) && text !== '';
}

export function notDefinedOrEmptyString(entity: any): boolean {
  return notDefined(entity) || emptyString(entity);
}

export function emptyString(entity: any): boolean {
  return entity === '';
}

export function isArray(potentialArray: any): potentialArray is any[] {
  const potentialArrayNotDefined = notDefined(potentialArray);
  if (potentialArrayNotDefined) return false;
  return potentialArray.constructor.toString().indexOf('Array') != -1;
}

export function isObject(potentialObject: any): potentialObject is { [prop: string]: any } {
  const potentialObjectNotDefined = notDefined(potentialObject);
  if (potentialObjectNotDefined) return false;
  return Object.prototype.toString.call(potentialObject).indexOf('Object') !== -1;
}

export function isString(potentialString: any): potentialString is string {
  const potentialStringNotDefined = notDefined(potentialString);
  if (potentialStringNotDefined) return false;
  return potentialString.constructor.toString().indexOf('String') != -1;
}

export function booleanIsDefinedAndNotEmpty(text: string): boolean {
  return text !== '' && (text === 'true' || text === 'false');
}

export function arrayIsNotDefinedOrEmpty(array: any[]): boolean {
  return !defined(array) || arrayEmpty(array);
}

export function arrayIsDefinedAndNotEmpty(array: any[]): boolean {
  return !arrayIsNotDefinedOrEmpty(array);
}

export function arrayEmpty(array: any[]): boolean {
  return array.length === 0;
}

export function isEmpty(entity: Array<any> | Object): boolean {
  if (isArray(entity)) {
    return entity.length === 0;
  } else if (isObject(entity)) {
    return Object.keys(entity).length === 0;
  } else {
    return false;
  }
}

export function addOrReplaceItemInArray(array: any[], targetItem: any, identifier?: string): any[] {
  const sourceItemIndex = array.findIndex((sourceItem) => (identifier ? sourceItem[identifier] === targetItem[identifier] : sourceItem === targetItem));
  if (sourceItemIndex === -1) {
    array.push(targetItem);
  } else {
    array.splice(sourceItemIndex, 1, targetItem);
  }
  return array;
}

export function sortNumbersOrStringsFunction(a: number | string, b: number | string, ascendingOrder: boolean): number {
  if (a > b) {
    return ascendingOrder ? 1 : -1;
  } else if (a < b) {
    return ascendingOrder ? -1 : 1;
  } else {
    return 0;
  }
}

export function highlightAndAnimateFabButton(floatingButton: NomeCustomFloatButtonComponent, index: number): void {
  const buttons: any[] = floatingButton.floatItemButtons.toArray();
  const button = buttons[index].elementref.nativeElement.lastChild;
  floatingButton.open.next(true);
  setTimeout(() => {
    animateElement(button);
  }, 200);
}

export function generateGradientColorsForGroups(startHex: string, midHex: string, endHex: string, groups: (string | number)[]): { name: string | number; value: string }[] {
  let colors: { name: string | number; value: string }[] = [];
  let firstRangeLength: number = groups.length > 6 ? parseInt((groups.length / 2).toString()) : groups.length;
  let secondRangeLength: number = groups.length > 6 && firstRangeLength > 0 ? parseInt((+(groups.length - firstRangeLength) + 1).toString()) : 0;

  if (groups && groups.length > 0) {
    let startColor = new KolorWheel(startHex);
    let midTargedColor = startColor.abs(midHex, firstRangeLength <= 1 ? 2 : firstRangeLength);
    for (var n = 0; n < firstRangeLength; n++) {
      colors.push({ name: groups[n], value: midTargedColor.get(n).getHex() });
    }

    if (secondRangeLength > 0) {
      let endTargetColor = new KolorWheel(midHex).abs(endHex, secondRangeLength);
      for (var n = 1; n < secondRangeLength; n++) {
        colors.push({ name: groups[+(firstRangeLength + (n - 1))], value: endTargetColor.get(n).getHex() });
      }
    }
  }
  return colors;
}

export function isDark(color: string): boolean {
  return new KolorWheel(color).isDark();
}

export function getForgroundColor(backgroundColor: string): string {
  return isDark(backgroundColor) ? '#fff' : '#000';
}

export function downloadFile(data: any, fileName: string, fileType: string = 'xlsx', fileExtension: string = 'xlsx') {
  const blob = new Blob([data], { type: fileType });
  const url = window.URL.createObjectURL(blob);
  const encodedUri = encodeURI(url);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName + '.' + fileExtension);
  document.body.appendChild(link); // Required for FF
  link.click();
}

export function getControl(group: UntypedFormGroup, name: string): AbstractControl {
  return group.get(name);
}

export function appendQueryParamIfDefined(url: string, queryParamKey: string, queryParamValue: any): string {
  const valueDefined = definedAndNotEmptyString(queryParamValue);
  if (!valueDefined) return url;

  const alreadyHasQueryParams = url.indexOf('?') !== -1;
  const encodedQueryParameter = encodeURIComponent(queryParamValue);
  if (alreadyHasQueryParams) {
    url = `${url}&${queryParamKey}=${encodedQueryParameter}`;
  } else {
    url = `${url}?${queryParamKey}=${encodedQueryParameter}`;
  }
  return url;
}

export function appendQueryParamIfDefinedAndPositive(url: string, queryParamKey: string, queryParamValue: any) {
  if (isDefinedAndPositiveNumber(queryParamValue)) return appendQueryParamIfDefined(url, queryParamKey, queryParamValue);
  return url;
}

export function capitalize(sentence: string): string {
  return sentence ? `${sentence[0].toUpperCase()}${sentence.substring(1)}` : '';
}

export function getObjectPropertyValue(obj: { [prop: string]: any }, propPath: string) {
  return defined(obj) && stringIsDefinedAndNotEmpty(propPath) ? propPath.split('.').reduce((value, prop) => (defined(value) && defined(value[prop]) ? value[prop] : undefined), obj) : undefined;
}

export function getObjectKeyByIndex(object: { [prop: string]: any }, index: number): string {
  return Object.keys(object)[index];
}

export function convertDateToQueryStringParameterFormat(date: Date, dateFormat = 'YYYY-MM-DD'): string {
  return format(date, dateFormat);
}

export function sort(array: any[]) {
  return array.sort((a, b) => {
    if (a.name && b.name) return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0;
    return a.id - b.id;
  });
}

export function sortById(array: any[]) {
  return array.sort((a, b) => {
    return a.id - b.id;
  });
}

export function sortByDate(array: any[], key: string) {
  array.sort(function (a, b) {
    return new Date(a[key]).getTime() - new Date(b[key]).getTime();
  });
}

export function sortList(list: NutritionFact[]) {
  list.sort((a, b) => {
    if (a.children && a.children.length > 0) {
      sortList(a.children);
    }
    return a.displayOrder - b.displayOrder;
  });
}

export function nestList(list: NutritionFact[]) {
  const nestedList = list.filter((fact) => !fact.parentId);
  const elementsThatHaveParents = list.filter((fact) => fact.parentId);

  for (let i = 0; i < elementsThatHaveParents.length; i++) {
    const element = elementsThatHaveParents[i];
    const parentElement = elementsThatHaveParents.find((e) => e.id === element.parentId);
    if (parentElement) {
      parentElement.children ? parentElement.children.push(element) : (parentElement.children = [element]);
    } else {
      const parentInNestedList = nestedList.find((e) => e.id === element.parentId);
      if (parentInNestedList) {
        parentInNestedList.children ? parentInNestedList.children.push(element) : (parentInNestedList.children = [element]);
      } else {
        // case when it is a nested element and the parent is not in the list
        nestedList.push(element);
      }
    }
  }
  sortList(nestedList);
  return nestedList;
}

export function deepClone<T>(arg: T): T {
  return JSON.parse(JSON.stringify(arg));
}

export function isEmailValid(email: string): boolean {
  return EMAIL_REGEX.test(String(email).toLowerCase());
}

export function checkIfIdentical(object_1, object_2, options?: { object1Replacer?: (key: string, value: any) => void; object2Replacer?: (key: string, value: any) => void }): boolean {
  return JSON.stringify(object_1, options?.object1Replacer) === JSON.stringify(object_2, options?.object2Replacer);
}

export function addCurrentLanguageQueryParamToUrl(url: string): string {
  const lang = localStorage.getItem('defaultLang');
  url = appendQueryParamIfDefined(url, 'language', lang);
  return url;
}

export function addPaginationParametersToUrl(url: string, start: number, limit: number, searchKeyword: string = null, sortBy?: string, sortDirection?: string) {
  if (!isNaN(start)) {
    if (url.includes('?')) {
      url = url + `&start=${start}`;
    } else {
      url = url + `?start=${start}`;
    }
  }
  if (!isNaN(limit)) {
    url = url + `&limit=${limit}`;
  }
  if (searchKeyword) {
    if (url.includes('?')) {
      url = url + `&Searchkeyword=${searchKeyword}`;
    } else {
      url = url + `?Searchkeyword=${searchKeyword}`;
    }
  }

  if (sortBy) {
    if (url.includes('?')) {
      url = url + `&sortBy=${sortBy}`;
    } else {
      url = url + `?sortBy=${sortBy}`;
    }

    if (!sortDirection) sortDirection = 'ASC';
    url = url + `&SortDirection=${sortDirection}`;
  }
  return url;
}

export function setNFHeaders(list: NutritionFact[]) {
  list.forEach((fact) => {
    fact.isHeader = true;
  });
}

export function addLinkedValidation(form: UntypedFormGroup, control_1: string, control_2: string) {
  const firstControl = form.get(control_1);
  const secondControl = form.get(control_2);

  secondControl.valueChanges.subscribe((change) => {
    if (change !== null) {
      firstControl.setValidators(Validators.required);
      firstControl.markAsTouched();
    } else {
      firstControl.clearValidators();
    }
    firstControl.updateValueAndValidity();
  });

  firstControl.valueChanges.subscribe((change) => {
    if (change == null || change === '') {
      if (secondControl.value) {
        firstControl.setValidators(Validators.required);
        firstControl.markAsTouched();
      } else {
        firstControl.clearValidators();
        secondControl.clearValidators();
      }
      firstControl.updateValueAndValidity({ emitEvent: false });
      secondControl.updateValueAndValidity({ emitEvent: false });
    } else {
      if (!secondControl.value) {
        secondControl.setValidators(Validators.required);
        secondControl.markAsTouched();
      } else {
        secondControl.clearValidators();
      }
      secondControl.updateValueAndValidity({ emitEvent: false });
    }
  });
}

/**
 * Uset this function if you need to decode uri recursively
 * @param uri
 */
export function _decodeURI(uri: string): string {
  uri = decodeURI(uri);
  if (uri.indexOf('%') !== -1) {
    return this.decodeUri(uri);
  } else {
    return uri;
  }
}

export function getDefaultTableColumns(): TableDataItem[] {
  return [
    new TableDataItem({ name: 'name', translationString: 'name', width: 20, trimmedWidth: 440 }),
    new TableDataItem({ name: 'description', translationString: 'description', width: 70, trimmedWidth: 1080 }),
    new TableDataItem({ name: 'action', translationString: '', isClickable: false, showNameInTableHeader: false, isActionItems: true, width: 10 })
  ];
}

export function getMediaTableColumns(): TableDataItem[] {
  return [
    new TableDataItem({ name: 'select', translationString: '', width: 30, trimmedWidth: 0, isCheckbox: true, isSortDisabled: true }),
    new TableDataItem({
      name: 'name',
      translationString: 'name',
      width: 200,
      trimmedWidth: 440,
      isStacked: true,
      stackedColumns: [new StackedColumn(true, 'mediaIcon', null, 'name', null, 20)]
    }),
    new TableDataItem({ name: 'description', translationString: 'description', width: 200, trimmedWidth: 1080 }),
    new TableDataItem({ name: 'action', translationString: '', isClickable: false, showNameInTableHeader: false, isActionItems: true, width: 10 })
  ];
}

export function getDefaultMenuItemsTableColumns(includeThummbnail = false): TableDataItem[] {
  const initialColumns = [
    new TableDataItem({ name: 'select', translationString: '', width: 5, trimmedWidth: 0, isCheckbox: true, isSortDisabled: true }),
    new TableDataItem({ name: 'name', translationString: 'name', width: 40, trimmedWidth: 700, forceClickable: true, preCustomIconVisible: true }),
    new TableDataItem({ name: 'inUse', translationString: 'in_use', width: 10, hasIcon: true, isSortDisabled: true }),
    new TableDataItem({ name: 'date', translationString: 'menu_manager.publishDate', width: 20 }),
    new TableDataItem({ name: 'syncStatusId', translationString: 'menu_manager.publishStatus', width: 20 }),
    new TableDataItem({ name: 'description', translationString: 'description', width: 30 }),
    new TableDataItem({ name: 'action', translationString: '', isClickable: false, showNameInTableHeader: false, isActionItems: true, width: 10 })
  ];
  const ThumbnailColumn = new TableDataItem({ name: 'imageUrl', translationString: '', showNameInTableHeader: false, width: 10, trimmedWidth: 100, isSortDisabled: true });
  includeThummbnail ? initialColumns.splice(1, 0, ThumbnailColumn) : null;
  return initialColumns;
}

export function getMenusDefaultTableColumns(locationId: number, isBrandPublisher: boolean, useNewSubscriptionFlow: boolean = false): TableDataItem[] {
  let columns = [
    new TableDataItem({ name: 'name', translationString: 'name', width: 30, trimmedWidth: 680 }),
    new TableDataItem({ name: 'menuAttributes', translationString: '', width: 5 }),
    new TableDataItem({ name: 'syncStatusId', translationString: 'menu_manager.publishStatus', width: 15 }),
    new TableDataItem({ name: 'description', translationString: 'description', width: 35, trimmedWidth: 500 }),
    new TableDataItem({ name: 'action', translationString: '', isClickable: false, showNameInTableHeader: false, isActionItems: true, width: 10 })
  ];

  if (isBrandPublisher && !useNewSubscriptionFlow) {
    columns.splice(4, 0, new TableDataItem({ name: 'subscriptionCode', translationString: 'menu_manager.subscription_code', width: 10 }));
  }

  const byLocation = locationId ? true : false;
  if (byLocation) {
    columns.splice(2, 0, new TableDataItem({ name: 'date', translationString: 'menu_manager.publishDate', width: 20 }));
    // columns.splice(2, 0, new TableDataItem('Customized', '', false, true, false, false, false, 5));
  }

  return columns;
}

export function createDefaultDataSource(cardsList: any[]): any[] {
  const dataSource = [];
  let row;
  cardsList.forEach((card) => {
    if (card.bottomItems && card.bottomItems.length === 2) {
      row = new CommonTableRow(
        card.id,
        card.name,
        card.description,
        card.menuButtons,
        card.bottomItems[1].value,
        card.bottomItems[1].className === 'date' ? card.bottomItems[1].dateIcon : null,
        card.isAssigned,
        card.isCustomized,
        card.isInherited,
        card.isCreatedAtLocation
      );
    } else {
      row = new CommonTableRow(card.id, card.name, card.description, card.menuButtons);
    }
    row.mediaIcon = card.svgIcon;
    row.checkboxDisabled = card.isSubscription;
    dataSource.push(row);
  });
  return dataSource;
}

export function createDefaultDataSourceFromCustomCard(cardsList: any[]): any[] {
  const dataSource = [];
  cardsList.forEach((card) => {
    const row = new CommonTableRow(card.id, card.title, card.subTitle, card.menuButtons);
    if (card.title_Secondary) row.name = `${row.name} | ${card.title_Secondary}`;

    dataSource.push(row);
  });
  return dataSource;
}

export function mapModuleNameToIcon(moduleName: string): string {
  switch (moduleName.toLowerCase()) {
    case 'accounts': {
      return 'roles-icons/RoleIcon-Account.svg';
    }
    case 'roles and permissions': {
      return 'roles-icons/RoleIcon-RolesPerm.svg';
    }
    case 'menus': {
      return 'roles-icons/RoleIcon-Menu.svg';
    }
    case 'menu items': {
      return 'roles-icons/RoleIcon-Product.svg';
    }
    case 'locations': {
      return 'roles-icons/RoleIcon-Locations.svg';
    }
    case 'brands': {
      return 'roles-icons/RoleIcon-Brands.svg';
    }
    case 'users': {
      return 'roles-icons/RoleIcon-Users.svg';
    }
    case 'devices': {
      return 'roles-icons/RoleIcon-Terminals.svg';
    }
    case 'ingredients': {
      return 'roles-icons/RoleIcon-Ingredients.svg';
    }
    case 'styles': {
      return 'roles-icons/RoleIcon-Styles.svg';
    }
    case 'label designers': {
      return 'roles-icons/RoleIcon-Label.svg';
    }
    case 'nutrition facts': {
      return 'roles-icons/RoleIcon-NutritionFacts.svg';
    }
    case 'media manager': {
      return 'roles-icons/RoleIcon-Media.svg';
    }
    case 'addons': {
      return 'roles-icons/RoleIcon-AddOns.svg';
    }
    case 'custom fields': {
      return 'roles-icons/RoleIcon-CustomFields.svg';
    }
    case 'analytics': {
      return 'roles-icons/RoleIcon-Analytics.svg';
    }
    case 'account info': {
      return 'roles-icons/RoleIcon-AccountInfo.svg';
    }
    case 'batch print': {
      return 'roles-icons/RoleIcon-AccountInfo.svg';
    }
    default: {
      return 'NewFolder.svg';
    }
  }
}

export function mapProfileValuesToDefaultProfile(defaultProfileFacts: NutritionFact[], flatFactsList: NutritionFact[]) {
  defaultProfileFacts.forEach((fact) => {
    const flatFact = flatFactsList.find((f) => f.id === fact.id);
    fact.nfValue = flatFact.nfValue;
    fact.dvValue = flatFact.dvValue;
    fact.isHeader = flatFact.isHeader;
    fact.nfValueText = flatFact.nfValueText;
    fact.valueType = flatFact.valueType;
    fact.style = flatFact.style;
    if (fact.children && fact.children.length > 0) {
      mapProfileValuesToDefaultProfile(fact.children, flatFactsList);
    }
    fact.nfValue2 = flatFact.nfValue2;
    fact.dvValue2 = flatFact.dvValue2;
    fact.nfValueText2 = flatFact.nfValueText2;
    fact.nfValue3 = flatFact.nfValue3;
    fact.dvValue3 = flatFact.dvValue3;
    fact.nfValueText3 = flatFact.nfValueText3;
    fact.nfValue4 = flatFact.nfValue4;
    fact.dvValue4 = flatFact.dvValue4;
    fact.nfValueText4 = flatFact.nfValueText4;
    fact.nfValue5 = flatFact.nfValue5;
    fact.dvValue5 = flatFact.dvValue5;
    fact.nfValueText5 = flatFact.nfValueText5;
    fact.nfValue6 = flatFact.nfValue6;
    fact.dvValue6 = flatFact.dvValue6;
    fact.nfValueText6 = flatFact.nfValueText6;
    fact.nfValue7 = flatFact.nfValue7;
    fact.dvValue7 = flatFact.dvValue7;
    fact.nfValueText7 = flatFact.nfValueText7;
  });
}

export function PreloadImages(sourceList: string[]) {
  const items = [];
  sourceList.forEach((source) => {
    const image = new Image();
    image.src = source;
    items.push(image);
  });
}

export function isNullOrEmpty(param: string) {
  return param == null || param == '';
}

export function getInfo(sectionName: string): string {
  let sectionInfo: string;
  switch (sectionName) {
    case 'menu.acct_mgmnt':
      sectionInfo = 'account_management_description';
      break;
    case 'menu.label_designer':
      sectionInfo = 'label_manager_description';
      break;
    case 'menu.workflow-designer':
      sectionInfo = 'workflow_manager_description';
      break;
    case 'menu.menu_mgr':
      sectionInfo = 'menu_manager_description';
      break;
    case 'menu.loc_mgr':
      sectionInfo = 'locations_manager_description';
      break;
    case 'menu.media_mgr':
      sectionInfo = 'media_manager_description';
      break;
    case 'menu.timer_manager':
      sectionInfo = 'timer_manager.description';
      break;
    case 'menu.analytics':
    case 'menu.label-analytics':
    case 'menu.temp-analytics':
    case 'menu.sense-analytics':
    case 'menu.media-analytics':
    case 'menu.retail_pricing_dashboard':
      sectionInfo = 'analytics_description';
      break;
    case 'menu.nutrition_facts':
      sectionInfo = 'nutition_facts_description';
      break;
    case 'menu.settings':
      sectionInfo = 'settings_description';
      break;
    case 'menu.checklist_manager':
      sectionInfo = 'checklists_description';
      break;
    case 'menu.checklist-analytics':
      sectionInfo = 'analytics_checklist_description';
      break;
    case 'menu.inventory_manager':
      sectionInfo = 'inventory_manager_description';
      break;
    case 'menu.distributor_manager':
      sectionInfo = 'distributor_manager_description';
      break;
    case 'menu.catalog_mgr':
      sectionInfo = 'catalog_manager_description';
    case 'menu.hardware_manager':
      sectionInfo = 'hardware_manager_description';
      break;
  }
  return sectionInfo;
}

export function getBlobFromBase64(base64Data: string, contentType: string, sliceSize = 512) {
  const byteCharacters = atob(base64Data.split('base64,')[1]);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function animateElement(element: HTMLElement) {
  const classList = element.classList;
  if (classList.contains('button-glow')) {
    classList.remove('button-glow');
  }
  setTimeout(() => {
    classList.add('button-glow');
  }, 300);
  setTimeout(() => {
    classList.remove('button-glow');
  }, 5000);
}

export function animateElementRef(elementRef: ElementRef): void {
  animateElement(elementRef['_elementRef'].nativeElement);
}

export function escapeForwardSlash(s: string) {
  return s.replace(/[\/]/g, '%2F');
}

export function encodeSpecialCharacters(s: string) {
  return encodeURIComponent(s);
}

export function convertSecondsToHoursMinutesAndSeconds(seconds: number): any {
  var momentSeconds = moment.duration(seconds, 's');
  var hours = Math.floor(momentSeconds.asHours());
  var minutes = Math.floor(momentSeconds.asMinutes()) - hours * 60;
  var seconds = Math.floor(momentSeconds.asSeconds()) - minutes * 60;

  return {
    hours,
    minutes,
    seconds
  };
}

export function transformToTopMin(element): Date {
  const _minutes: Date = addMinutes(element, -(getMinutes(element) % 10));
  const _seconds: Date = addSeconds(_minutes, -(getSeconds(element) % 60));
  return _seconds;
}

export function toDecimalPrecision(input: number, forceInteger = false, precision: number = 1) {
  // if input is null, error is thrown
  if (notDefined(input)) return '';
  if (forceInteger) {
    return `${Math.round(input)}`;
  }
  return `${RoundToNearestDecimal(input, precision)}`;
}

export function transformToTopMinBulk(input: any[], key: string) {
  const data = deepClone(input);
  return data.map((element) => {
    const newElement = { ...element };
    newElement[key + '_old'] = newElement[key];
    newElement[key] = transformToTopMin(newElement[key]);
    return newElement;
  });
}

export function RoundToNearestDecimal(value: number, decimalPoint: number = 1) {
  if (GetPlace(value, decimalPoint + 1) == 5) {
    return Math.trunc(value * Math.pow(10, decimalPoint)) / Math.pow(10, decimalPoint);
  }
  return Number.isInteger(_round(value, decimalPoint)) ? `${_round(value, decimalPoint)}.${fillZeros(decimalPoint)}` : _round(value, decimalPoint);
}

export function GetPlace(value: number, decimalPoint: number): number {
  var intValue = Math.floor(value * Math.pow(10, decimalPoint));
  return intValue % 10;
}

export function fillZeros(input: number) {
  let zeros = '';
  for (let i = 0; i < input; i++) {
    zeros += '0';
  }
  return zeros;
}

export function generateCsv(title: string, data: any[], columns: any[], _optons?) {
  const options = _optons
    ? _optons
    : {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        title: title,
        useBom: true,
        noDownload: false,
        headers: columns
      };
  new ngxCsv(data, title, options);
}

function _round(value: number, decimalPoint: number) {
  return parseFloat(parseFloat(value.toString()).toFixed(decimalPoint));
}

export function getNode(nodes: any[], id) {
  const stack = deepClone(nodes);
  while (stack.length) {
    const node = stack.pop();
    if (node.id === id) return node;
    node.children && stack.push(...node.children);
  }
  return stack.pop() || null;
}

export function showDefaultImage(event, defaultImage: string = environment.assets + 'images/NoImage.svg') {
  event.target.src = defaultImage;
}

export function checkPath(src: string, defaultImage: string = environment.assets + 'images/NoImage.svg') {
  return src && src !== '' ? src : defaultImage;
}

export function getSupportedTemperatureUnits() {
  return [
    { name: '°F', value: Temperature.Fahrenheit, id: TemperatureUomId.Fahrenheit },
    { name: '°C', value: Temperature.Celsius, id: TemperatureUomId.Celsius }
  ];
}

export function getTemperatureById(id: number) {
  return getSupportedTemperatureUnits().find((x) => x.id === id);
}

export function getTemperatureByString(id: string) {
  return getSupportedTemperatureUnits().find((x) => x.value === id);
}

export function getTemperatureRules() {
  return [
    {
      id: TemperatureRule.Range,
      title: 'menu_manager.temp_tasks.between',
      oldTitle: 'menu_manager.temp_tasks.range'
    },
    {
      id: TemperatureRule.Above,
      title: 'menu_manager.temp_tasks.above',
      oldTitle: 'menu_manager.temp_tasks.greater-than'
    },
    {
      id: TemperatureRule.Below,
      title: 'menu_manager.temp_tasks.below',
      oldTitle: 'menu_manager.temp_tasks.less-than' // TODO to be removed later, just to be consistent with workflow
    }
  ];
}
export function toggleBackdropColor(value: boolean, div) {
  const container = document.getElementById('main-container');
  if (div) {
    const hasBackdrop = div.classList.contains('backdrop');
    const hasNoPointer = container.classList.contains('noPointerEvent');
    if (value === false) {
      if (hasBackdrop) {
        div.classList.remove('backdrop');
      }
      if (hasNoPointer) {
        container.classList.remove('noPointerEvent');
      }
    }

    if (value === true) {
      if (!hasBackdrop) {
        div.classList.add('backdrop');
      }
      if (!hasNoPointer) {
        container.classList.add('noPointerEvent');
      }
    }
  }
}

export function orderArray<T = any>(input: T[], property: string, asc = true): T[] {
  return asc
    ? Enumerable.asEnumerable(input)
        .OrderBy((x) => x[property])
        .ToArray()
    : Enumerable.asEnumerable(input)
        .OrderByDescending((x) => x[property])
        .ToArray();
}

export function patchValue(control: AbstractControl, value: any): void {
  control.patchValue(value);
}

export function splitArray<T = any>(array: T[], n) {
  let [...arr] = array;
  var res = [];
  while (arr.length) {
    res.push(arr.splice(0, n));
  }
  return res;
}

export function getStepUom(inputTypes: InputTypeModel[], inputType: TempWorkflowTemplateInputTypes): string {
  const uom = inputTypes.find((it) => it.id === inputType).uom;
  if (inputType === TempWorkflowTemplateInputTypes.Temperature) {
    return getTemperatureByString(getTemperatureUOMString()).name;
  } else {
    return uom;
  }
}

export function getTemperatureUOM() {
  return localStorage.getItem('temperatureUOM') !== undefined && localStorage.getItem('temperatureUOM') !== null ? localStorage.getItem('temperatureUOM') : null;
}

export function getTemperatureUOMString(): string {
  return getTemperatureUOM() ? getTemperatureUOM() : Temperature.Fahrenheit;
}

export function getDashboardTemperatureUOM(flag: LocalStorageFlags) {
  return localStorage.getItem(flag) !== undefined && localStorage.getItem(flag) !== null ? localStorage.getItem(flag) : getTemperatureUOM();
}

export function getDashboardTemperatureUOMString(flag: LocalStorageFlags): string {
  return getDashboardTemperatureUOM(flag) ? getDashboardTemperatureUOM(flag) : Temperature.Fahrenheit;
}

export function getTemperatureUOMNumber(): number {
  if (getTemperatureUOM()) {
    switch (getTemperatureUOM()) {
      case Temperature.Fahrenheit:
        return TemperatureUomId.Fahrenheit;
      case Temperature.Celsius:
        return TemperatureUomId.Celsius;
    }
  }
  return TemperatureUomId.Fahrenheit;
}

export function setTemperatureUOM(uom: string) {
  localStorage.setItem('temperatureUOM', uom);
}
export function setdashboardTemperatureUOM(flag: LocalStorageFlags, uom: string) {
  localStorage.setItem(flag, uom);
}

export function setTempUoMId(input: number) {
  if (defined(input)) {
    switch (input) {
      case TemperatureUomId.Celsius:
        setTemperatureUOM(Temperature.Celsius);
        setdashboardTemperatureUOM(LocalStorageFlags.SenseTemperatureUOM, Temperature.Celsius);
        setdashboardTemperatureUOM(LocalStorageFlags.TempTemperatureUOM, Temperature.Celsius);
        break;
      case TemperatureUomId.Fahrenheit:
        setTemperatureUOM(Temperature.Fahrenheit);
        setdashboardTemperatureUOM(LocalStorageFlags.SenseTemperatureUOM, Temperature.Fahrenheit);
        setdashboardTemperatureUOM(LocalStorageFlags.TempTemperatureUOM, Temperature.Fahrenheit);
        break;
    }
    localStorage.setItem('tempUoMId', input.toString());
  }
}

export function getTempUoMId() {
  return stringIsDefinedAndNotEmpty(localStorage.getItem('tempUoMId')) ? Number.parseInt(localStorage.getItem('tempUoMId')) : TemperatureUomId.Fahrenheit;
}

export function getNumberRange(start: number, end: number, input: number) {
  return start <= input && input <= end;
}

export function hasUpperCase(str) {
  return /[A-Z]/.test(str);
}

export function expandNodeParents(nodes: TreeNode[], node: TreeNode, keepExpanding = true) {
  if (keepExpanding) {
    nodes.forEach((n) => {
      if (node.path.startsWith(n.path)) {
        if (n.path === node.path) {
          keepExpanding = false;
        } else {
          n.expanded = true;
          expandNodeParents(n.children, node, keepExpanding);
        }
      }
    });
  }
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function keyValuesToHttpHeaders(keyValuesForHeaders: KeyValue<string, string>[]): HttpHeaders {
  if (!keyValuesForHeaders) return null;
  let httpHeaders = new HttpHeaders();
  keyValuesForHeaders.forEach((keyValue) => {
    httpHeaders = httpHeaders.append(keyValue.key, keyValue.value);
  });
  return httpHeaders;
}

export function isAMorPM(hour: number): string {
  if (notDefined(hour)) return;

  return hour < 12 ? 'am' : 'pm';
}

export function convertDateTo12HourFormatTimeString(date: Date): string {
  return format(date, 'hh:mm a');
}

export function convert24HourTimeStringToDate(twentyFourHoursString: string): Date {
  const twentyFourHoursStringArray = twentyFourHoursString.split(':');

  const twentyFourHoursStringDate = new Date();
  twentyFourHoursStringDate.setHours(Number.parseInt(twentyFourHoursStringArray[0]));
  twentyFourHoursStringDate.setMinutes(Number.parseInt(twentyFourHoursStringArray[1]));
  twentyFourHoursStringDate.setSeconds(Number.parseInt(twentyFourHoursStringArray[2]));

  return twentyFourHoursStringDate;
}

export function convert24HoursStringTo12HoursString(twentyFourHoursString: string): string {
  if (notDefinedOrEmptyString(twentyFourHoursString) || isIn12HourFormat(twentyFourHoursString)) return twentyFourHoursString;

  const hours = twentyFourHoursString.slice(0, 2);
  const minutes = twentyFourHoursString.slice(3, 5);
  const hoursNumber = parseInt(hours, 10);
  const amOrPm = isAMorPM(hoursNumber);

  let twelveHoursString;

  if (hoursNumber === 0) {
    twelveHoursString = `${Math.abs(hoursNumber - 12)}:${minutes} ${amOrPm}`;
  } else if (hoursNumber <= 12) {
    twelveHoursString = `${hours}:${minutes} ${amOrPm}`;
  } else {
    twelveHoursString = `${hoursNumber < 22 ? '0' : ''}${Math.abs(hoursNumber - 12)}:${minutes} ${amOrPm}`;
  }

  return twelveHoursString;
}

export function convert12HoursStringTo24HoursString(twelveHoursString: string): string {
  if (notDefinedOrEmptyString(twelveHoursString) || !isIn12HourFormat(twelveHoursString)) return twelveHoursString;

  let operators = new Operators();
  let twentyFourHoursString = '';

  let timeStamp = twelveHoursString.split(' ')[0];
  let timeOperator = twelveHoursString.split(' ')[1];

  var hour = parseInt(timeStamp.split(':')[0]);
  var minute = parseInt(timeStamp.split(':')[1]);
  let newHour: number;
  if (timeOperator.toLowerCase() == TimeOperator.AM && operators.operands[Operator.Equals](hour, 12)) {
    newHour = 0;
  } else if (timeOperator.toLowerCase() == TimeOperator.PM && operators.operands[Operator.LessThan](hour, 12)) {
    newHour = operators.operands[Operator.Plus](hour, timeOperation.get(TimeOperator.PM));
  } else {
    newHour = hour;
  }

  twentyFourHoursString += newHour < 10 ? `0${newHour}:` : `${newHour}:`;
  twentyFourHoursString += minute < 10 ? `0${minute}` : `${minute}`;

  return twentyFourHoursString;
}

export function isIn12HourFormat(twelveHoursString: string): boolean {
  if (notDefinedOrEmptyString(twelveHoursString)) return false;
  let timeOperator = twelveHoursString.split(' ')[1];
  return timeOperator && (timeOperator.toLowerCase() == TimeOperator.AM || timeOperator.toLowerCase() == TimeOperator.PM);
}

export function mapAndOrderSessions(sessions: SessionModel[]): SessionOption[] {
  return sessions
    .sort((s1, s2) => {
      const s1StartDate = convert24HourTimeStringToDate(s1.startTime);
      const s2StartDate = convert24HourTimeStringToDate(s2.startTime);
      const s1StartsAtTheSameTimeAsS2 = moment(s1StartDate).isSame(s2StartDate);

      if (s1StartsAtTheSameTimeAsS2) {
        const formatDays = (occurrences) =>
          occurrences
            .filter((occurrence) => occurrence.isEnabled)
            .map((occurrence) => (occurrence.day === 0 ? 7 : occurrence.day))
            .sort();

        const s1Days = formatDays(s1.occurrences);
        const s2Days = formatDays(s2.occurrences);
        const s1IsExecutedTheSameDayAsS2 = s1Days[0] === s2Days[0];

        if (s1IsExecutedTheSameDayAsS2) {
          return s1.name.toLowerCase() < s2.name.toLowerCase() ? -1 : 1;
        } else {
          const s1IsExecutedOnADayBeforeS2 = s1Days[0] < s2Days[0];
          return s1IsExecutedOnADayBeforeS2 ? -1 : 1;
        }
      } else {
        const s1StartsBeforeS2 = moment(s1StartDate).isBefore(s2StartDate);
        return s1StartsBeforeS2 ? -1 : 1;
      }
    })
    .map((session) => {
      const startTime = convert24HourTimeStringToDate(session.startTime);
      const endTime = convert24HourTimeStringToDate(session.endTime);

      return new SessionOption(session.sessionId, session.name, startTime, endTime);
    });
}

/**
 *
 * @param col hex color (col.length === 4 or 7 chars including # sign)
 * @param amt decimal number (-1 <= amt >= 1)
 * @returns string (hex color)
 */
export function lightenDarkenColor(col: string, amt: number) {
  // validate hex string
  col = String(col).replace(/[^0-9a-f]/gi, '');
  if (col.length < 6) {
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];
  }
  amt = amt || 0;

  // convert to decimal and change luminosity
  var hexColor = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(col.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * amt), 255)).toString(16);
    hexColor += ('00' + c).substr(c.length);
  }

  return hexColor;
}

export function lightenDarkenHoursColorPalette(amt: number): Map<number, string[]> {
  const newHoursColorPalette = new Map<number, string[]>();

  Array.from(HOURS_COLOR_PALETTE).forEach((hourColorPalette) => {
    newHoursColorPalette.set(
      hourColorPalette[0],
      hourColorPalette[1].map((color) => lightenDarkenColor(color, setTheSignOfTheNumber(amt, isDark(color))))
    );
  });

  return newHoursColorPalette;
}

export function setTheSignOfTheNumber(nbr: number, toPositive: boolean): number {
  const nbrIsPositive = Math.sign(nbr) > 0;

  if (nbrIsPositive) {
    return toPositive ? nbr : -nbr;
  } else {
    return toPositive ? -nbr : nbr;
  }
}

export function daysMapper(days: string[]): string[] {
  const map = new Map([
    ['MON', 'Monday'],
    ['TUE', 'Tuesday'],
    ['WED', 'Wednesday'],
    ['THU', 'Thursday'],
    ['FRI', 'Friday'],
    ['SAT', 'Saturday'],
    ['SUN', 'Sunday']
  ]);

  return days.map((d) => map.get(d));
}

export function getDefaultMenuItemsToolbarButtons(hasDeletePermission: boolean): ToolbarButton[] {
  let toolbarButtons: ToolbarButton[] = [new ToolbarButton('move', CardActions.MOVE)];
  if (hasDeletePermission) {
    toolbarButtons.push(new ToolbarButton('delete', CardActions.DELETE));
  }
  return toolbarButtons;
}

export function getDefaultItemDisplayOptions(): ItemDisplayOption[] {
  return [
    new ItemDisplayOption(ItemDisplayOptionsEnum.FILL_TO_FIT, 'account_manager.account-info.fill-to-fit'),
    new ItemDisplayOption(ItemDisplayOptionsEnum.SHRINK_TO_FIT, 'account_manager.account-info.shrink-to-fit'),
    new ItemDisplayOption(ItemDisplayOptionsEnum.FIXED, 'account_manager.account-info.fixed')
  ];
}

export function getDefaultUnitsOfMeasure(): UnitOfMeasure[] {
  return [new UnitOfMeasure(UnitsOfMeasureEnum.INCHES, 'account_manager.account-info.inches'), new UnitOfMeasure(UnitsOfMeasureEnum.MILLIMETERS, 'account_manager.account-info.millimeters')];
}

export function alertTheUserOnWindowUnload($event: any, condition: boolean): void {
  if (condition) {
    $event.preventDefault();
    $event.returnValue = '';
  }
}

/**
 * Function that can receive custom cardsActions
 * @param moduleName the name of module to get permissions
 * @param card the card object where to add the buttons ( will reflect to the table also)
 * @param authService the auth service for us no to inject it here in the class.
 * @param customCardsActions this will set custom defined buttons using the buttons (defined in dictionary)
 */

export function addButtons(moduleName: string, card: Card, authService: AuthService, customCardsActions?: CardButtonSelection[]) {
  let relatedButtons: CardButton[] = [];
  const actions = JSON.parse(JSON.stringify(ActionsData));
  let relatedbuttonsActions: CardButtonSelection[];
  relatedbuttonsActions = JSON.parse(JSON.stringify(customCardsActions));
  relatedButtons = actions.filter((action) => relatedbuttonsActions.some((x) => x.action === action.cardAction));
  relatedButtons.sort((a, b) => {
    return relatedbuttonsActions.findIndex((item) => item.action === a.cardAction) - relatedbuttonsActions.findIndex((item) => item.action === b.cardAction);
  });

  relatedButtons.forEach((button) => {
    if (button.hasOwnProperty('permission')) {
      if (button.hasOwnProperty('revertPermission')) {
        button.allowed = button.revertPermission ? !authService.hasPermission(moduleName, button.permission) : authService.hasPermission(moduleName, button.permission);
      } else {
        button.allowed = authService.hasPermission(moduleName, button.permission);
      }
    }
  });

  relatedbuttonsActions.forEach((action) => {
    const index = relatedButtons.findIndex((x) => x.cardAction === action.action);
    if (action.hasOwnProperty('permissions')) {
      relatedButtons[index].allowed = getAllowedConditons(moduleName, authService, action.permissions, action.currentUserPermissionsUsed);
    }
    if (action.hasOwnProperty('title')) {
      relatedButtons[index].title = action.title;
    }
    if (action.hasOwnProperty('isDefaultAllowed')) {
      relatedButtons[index].isDefaultAllowed = action.isDefaultAllowed;
    }
  });

  addCardButtons(card, relatedButtons);
}

export function getAllowedConditons(moduleName: string, authService, data: AllowedCondition[], currentUserPermissionsUsed?: boolean): boolean {
  let allowed = true;

  data.forEach((elem) => {
    switch (typeof elem) {
      case 'number':
        if (!authService.hasPermission(moduleName, elem, null, currentUserPermissionsUsed)) {
          allowed = false;
        }
        break;
      case 'boolean':
        if (!elem) {
          allowed = false;
        }
        break;
      case 'object':
        let result = true;
        if (elem.hasOwnProperty('revertPermission')) {
          result = elem.revertPermission
            ? !authService.hasPermission(moduleName, elem.permission, null, currentUserPermissionsUsed)
            : authService.hasPermission(moduleName, elem.permission, null, currentUserPermissionsUsed);
        } else {
          result = authService.hasPermission(moduleName, elem.permission);
        }
        if (!result) {
          allowed = false;
        }
        break;
    }
  });
  return allowed;
}

export function addCardButtons(card: Card, buttons: CardButton[]) {
  buttons.forEach((element) => {
    if (element.allowed) {
      let isDefaultAllowed = true;
      if (element.hasOwnProperty('isDefaultAllowed')) {
        isDefaultAllowed = element.isDefaultAllowed;
      }
      card.menuButtons.push(new MenuButtons(element.title, element.cardAction, isDefaultAllowed));
    }
  });
}

export function setHTMLTagAttributes(tag: HTMLElement, attributes: LocalKeyValue<string, string>[]): void {
  attributes?.forEach((attribute) => {
    tag.setAttribute(attribute.key, attribute.value);
  });
}

export function setHTMLTagClasses(tag: HTMLElement, classes: string): void {
  tag.className = classes;
}

export function createHTMLTag(name: string, attributes?: LocalKeyValue<string, string>[], classes?: string, text?: string): HTMLElement {
  const tag = document.createElement(name);
  setHTMLTagAttributes(tag, attributes);
  if (definedAndNotEmptyString(classes)) setHTMLTagClasses(tag, classes);
  if (defined(text)) tag.textContent = text;
  return tag;
}

export function stopEventPropagation(event: Event): void {
  event.stopPropagation();
}

export function getIngredientNetweightUoms(ingredientUoms: UnitOfMeasure[]): UnitOfMeasure[] {
  return arrayIsDefinedAndNotEmpty(ingredientUoms) ? ingredientUoms.filter((x) => x.symbol == 'g') : [];
}

export function emitAndCompleteSubject(subject: Subject<void>): void {
  subject?.next();
  subject?.complete();
}

export function syncValidationOfChildAndParentFormControls(form: AbstractControl, ngControl: NgControl, emitEvent = false): void {
  const validator = (c: AbstractControl) => {
    return form.invalid && { invalid: true };
  };
  const control = ngControl.control;
  control.setValidators(validator);
  control.updateValueAndValidity({ emitEvent });
}

export function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

export function getPercentage(count: number, totalCount: number): number {
  const percent = (count / totalCount) * 100;

  return isNaN(percent) ? 0 : percent; // isNaN is used because `totalCount` can be "zero" or `count` can be "undefined"
}

export function replaceOrPushItem(array: any[], item: any, identifier: string): any[] {
  const itemIndex = array.findIndex((arrayItem) => arrayItem[identifier] === item[identifier]);
  if (itemIndex === -1) {
    array.push(item);
  } else {
    array[itemIndex] = item;
  }

  return array;
}

export function replaceOrPushItems(targetArray: any[], array: any[], identifier: string): any[] {
  array.forEach((item) => {
    replaceOrPushItem(targetArray, item, identifier);
  });

  return targetArray;
}

export function getDialogButtons(buttons: Button[]): DialogButton[] {
  return buttons.map((b) => {
    switch (b.name) {
      case ButtonName.Cancel:
        return {
          type: ButtonType.Stroked,
          cssClasses: 'main-button',
          label: 'cancel',
          case: Case.Title,
          action: b.action
        };
      case ButtonName.Apply:
        return {
          type: ButtonType.Flat,
          color: MaterialColor.Accent,
          cssClasses: 'main-button',
          label: 'apply',
          case: Case.Title,
          disabled: false,
          action: b.action
        };
    }
  });
}
export function getDurationText(duration: number): { key: string; object?: { [prop: string]: number } } {
  const hoursMinutes = secondsToHoursAndMinutes(duration);

  const hours = hoursMinutes[0];
  const minutes = hoursMinutes[1];

  const hoursPlural = hours > 1;
  const minutesPlural = minutes > 1;

  if (hours > 0 && minutes > 0) {
    if (hoursPlural && minutesPlural) {
      return { key: 'x_hrs_y_mins', object: { x: hours, y: minutes } };
    } else if (hoursPlural === false && minutesPlural === false) {
      return { key: 'one_hr_one_min' };
    } else if (hoursPlural && minutesPlural === false) {
      return { key: 'x_hrs_one_min', object: { x: hours } };
    } else {
      return { key: 'one_hr_x_mins', object: { x: minutes } };
    }
  } else if (hours > 0) {
    return { key: `${hoursPlural ? 'x_hrs' : 'one_hr'}`, object: { x: hoursPlural ? hours : null } };
  } else {
    return { key: `${minutesPlural ? 'x_mins' : 'one_min'}`, object: { x: minutesPlural ? minutes : null } };
  }
}

export function convertToNumberEvenIfNotDefined(number: number): number {
  return isNaN(parseInt(number?.toString())) ? 0 : number;
}

export function isFileValid(file: File, validFileTypes: string[]): boolean {
  return new RegExp('^(' + validFileTypes.join('|') + ')$').test(file.type);
}

export function cleanupRichEditorHtmlContent(text: string) {
  /// Remove control characters (Unicode code points U+0000 through U+001F)
  text = removeControlChars(text);

  /// cleanup non-supported html tags used in NF and custom fields html editors + backward compatibility support
  let cleanText = text
    .replace(/<br>|<br \/>|<br\/>|<p[^>]*>|<\/p>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '[') // replace < and > with [ and ] to avoid facing conflicts when rendering html tags
    .replace(/&gt;/g, ']');
  cleanText = unescape(cleanText);
  return cleanText;
}

export function removeControlChars(text: string): string {
  return text.replace(/[\u0000-\u001F]/g, '');
}

export function getDefaultCronExpression(recurrenceType: RecurrenceType, recurrenceFrequency: number): string {
  switch (recurrenceType) {
    case RecurrenceType.Daily:
      return `0 0 1 1/${recurrenceFrequency} * ? *`;
    case RecurrenceType.Weekly:
      return '0 0 0 ? * MON *';
    case RecurrenceType.Monthly:
      return `0 0 0 1 1/${recurrenceFrequency} ? *`;
  }
}

export function onGetSubroute(response: any): ConfigData {
  const results = response.results;
  const config = results
    ? new ConfigData(results.subRoute, results.resellerId, results.authenticateUsingKeycloakOIDC, results.alias, results.keycloakURL, results.supportChatEnabled)
    : new ConfigData(null, null, false, null, environment.keycloakURL, false);

  localStorage.setItem(LocalStorageFlags.resellerSubRoute, config.subroute);
  localStorage.setItem(LocalStorageFlags.resellerLoginId, config.resellerLoginId);
  localStorage.setItem(LocalStorageFlags.authenticateUsingKeycloakOIDC, config.authenticateUsingKeycloakOIDC ? 'true' : 'false');
  localStorage.setItem(LocalStorageFlags.resellerAlias, config.alias);
  localStorage.setItem(LocalStorageFlags.identityServerURL, config.keycloakURL);
  localStorage.setItem(LocalStorageFlags.supportChatEnabled, config.supportChatEnabled ? 'true' : 'false');

  return config;
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function checkVariableUntilNull(myVariable: any): Promise<void> {
  return new Promise((resolve) => {
    const interval = 1; // Interval in milliseconds

    const check = () => {
      if (!defined(myVariable)) {
        clearInterval(timer);
        resolve();
      }
    };

    const timer = setInterval(check, interval);
  });
}

export function convertMBtoByte(value: number) {
  return value * 1024 * 1024;
}
