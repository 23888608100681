export enum ApiResponseCodeEnum {
  // General Error and Success Codes 0 - 199
  SUCCESS = 0,
  NO_RECORDS_FOUND = 100,
  UNKNOWN_ERROR = 50,

  // Terminals Error Codes 200 - 399
  TERMINAL_ALREADY_ASSIGNED = 200,
  TERMINAL_INVALID = 201,
  TERMINAL_SERIAL_NUMBER_AMBIGUOUS = 203,
  TERMINAL_DOES_NOT_EXIST = 204,
  TERMINAL_NOT_ASSIGNED_TO_LOCATION = 205,
  TERMINAL_STORE_HIERARCHY_PROBLEM = 206,
  TERMINAL_INACTIVE = 207,
  LOCATION_CODE_NOT_FOUND = 212,
  LOCATION_NOT_FOUND = 226,

  EQUIPMENT_TYPE_UNASSIGN_FAILED_DUE_TO_UNPUBLISHED_CHECKLISTS = 431,
  EQUIPMENT_TYPE_UNASSIGN_FAILED_DUE_TO_CHECKLISTS_PUBLISH_FAILURE = 432,
  LOCATION_MOVE_SUCCESS_WITH_PUBLISH_CONFLICTS = 435,
  MOVE_LOCATION_VALIDATION_CHECKLIST_CONFLICTS = 436,
  MOVE_LOCATION_VALIDATION_CONFLICTS = 437,
  MOVE_LOCATION_NOT_CONFIRMED = 438,
  MOVE_LOCATION_VALIDATION_MENU_CONFLICTS = 439,
  MOVE_LOCATION_INHERITED_MENU_CONFLICTS = 452,
  MOVE_LOCATION_TIMER_CONFLICTS = 456,
  MOVE_LOCATION_BLOCKED = 471,

  //Location subscription move
  LOCATION_SUBSCRIPTION_MOVE_SOURCE_AND_DESTINATION_ARE_EQUAL = 464,
  LOCATION_SUBSCRIPTION_MOVE_DESTINATION_IS_NOT_A_FOLDER = 463,
  LOCATION_SUBSCRIPTION_MOVE_SOURCE_AND_DESTINATION_INCOMPATIBLE_TENANT_ID = 460,
  LOCATION_SUBSCRIPTION_MOVE_SOURCE_AND_DESTINATION_ARE_NOT_ACTIVE = 462,
  LOCATION_SUBSCRIPTION_MOVE_DESTINATION_FOLDER_DOES_NOT_HAVE_A_CODE_GENERATED = 461,

  //Invlaid old password
  INVALIDE_OLD_PASSWORD = 1216,

  // Menu Item is Locked
  MENU_ITEM_LOCKED = 1608,

  // Menu Export Error
  MENU_EXPORT_ERROR = 3220,

  // Menu Import Error
  MENU_IMPORT_ERROR = 3200,
  MENU_SECTION_IMPORT_ERROR = 3201,
  MENU_IMPORT_DUPLICATE_ITEMS_ERROR = 3204,
  MENU_CUSTOMIZATION_IMPORT_ERROR = 3202,
  MENU_CUSTOMIZATION_IMPORT_DUPLICATE_ITEMS_ERROR = 3203,

  // Locations and stores Error Codes 400 - 599
  STORE_NAME_ALREADY_EXISTS_UNDER_THIS_LOCATION = 409,
  FOLDER_NAME_ALREADY_EXISTS_UNDER_THIS_LOCATION = 410,
  STORE_NUMBER_ALREADY_EXISTS = 401,
  MASTER_NODE_EXISTS = 402,
  LOCATION_CODE_ALREADY_EXISTS = 403,
  STORE_CODE_ALREADY_EXISTS = 411,
  ANCESTOR_STORE_ALREADY_EXIST = 404,
  LOCATION_CODE_NOT_VALID = 405,
  LOCATION_CODE_NOT_EXISTS = 406,
  INVALID_LOCATION_TYPE = 407,
  STORE_INFO_UNDEFINED = 408,
  FOLDER_CODE_ALREADY_EXISTS = 412,
  LOCATION_DELETION_CONFLICT = 416,
  STORE_NUMBER_ALREADY_EXISTS_IN_OTHER_ACCOUNT = 419,
  UNASSIGN_EQUIPMENT_LINKED_TO_SENSOR = 421,
  UNASSIGN_EQUIPMENT_ASSIGNED_TO_TASKS = 422,
  TARGET_LOCATION_HAS_A_STORE_WITH_SAME_NAME = 400,
  TARGET_LOCATION_IS_THE_SAME_AS_THE_PARENT_LOCATION = 426,
  STORE_HAS_AREAS_UNDER_IT = 427,
  STORE_HAS_SUB_LOCATIONS_UNDER_IT = 428,
  FILE_IMPORT_LOCATION_STORE_NUMBER_ALREADY_EXISTS_In_OTHER_ACCOUNT = 81,
  STORE_NUMBER_INVALIDLENGTH = 418,
  LOCATION_ALREADY_HAS_ACTIVE_SUBSCRIPTIONS = 440,
  LOCATION_ALREADY_HAS_SUBSCRIPTION_CODE = 441,
  LOCATION_ALREADY_HAS_SUBSCRIPTION_CODE_AT_HIGHER_LEVEL = 442,
  LOCATION_CANNOT_GENERATE_CODE = 443,
  LOCATION_ALREADY_HAS_SUBSCRIPTION_CODE_AT_CHILD_LEVEL = 444,
  LOCATION_ALREADY_HAS_SUBSCRIPTIONS = 445,
  LOCATION_ALREADY_HAS_SUBSCRIPTIONS_AT_HIGHER_LEVEL = 446,
  LOCATION_ALREADY_HAS_SUBSCRIPTIONS_AT_LOWER_LEVEL = 447,
  LOCATION_CANNOT_SUBSCRIBE_TO_SUBSCRIPTION_CODE = 448,
  LOCATION_SUBSCRIPTION_CODE_INVALID = 449,
  LOCATION_SUBSCRIPTION_ALREADY_ACTIVE_FOR_DIFFERENT_ACCOUNT = 450,
  LOCATION_SUBSCRIPTION_SUBSCRIBER_AND_PUBLISHER_BRANDS_DO_NOT_MATCH = 451,
  LOCATION_SUBSCRIPTION_SUBSCRIBER_ALREADY_HAS_SCHEDULE_DEFINED = 452,
  LOCATION_ALREADY_HAS_REJECTED_SUBSCRIPTION_FOR_THE_SAME_CODE = 453,
  FOLDER_CANNOT_BE_DELETED_IT_HAS_ACTIVE_SUBSCRIPTIONS = 454,
  LOCATION_HAS_A_SUBSCRIPTION_CODE = 455,
  EQUIPMENT_SENSOR_LOCATION_NOT_QUEUED = 458,
  LOCATION_ALREADY_SUBSCRIBED_WITH_THE_SAME_CODE = 465,
  LOCATION_SUBSCRIPTION_INVALID_NUTRITIONAL_FACT_PROFILES = 466,
  LOCATION_BULK_MOVE_IN_PROCESS = 467,

  // Labels Error Codes 600 - 799
  LABEL_ADD_ERROR = 600,
  LABEL_NOT_EXISTS = 601,
  LABEL_TEMPLATE_CODE_ALREADY_EXIST = 602,
  LABEL_TEMPLATE_FILE_INCORRECT_FORMAT = 603,
  LABEL_TEMPLATE_FILE_IS_MISSING = 604,
  NO_UPDATE_ALLOWED = 608,
  LABEL_DELETION_CONFLICT = 611,
  LABEL_TEMPLATE_NAME_ALREADY_EXIST = 614,
  LABEL_FONT_FILE_NAME_REPEATED = 615,
  LABEL_FONT_NAME_ALREADY_EXISTS = 616,
  LABEL_PUBLISH_FAIL = 617,
  LABEL_FONT_FILE_UPLOAD_FAILED = 618,
  LABEL_FONT_DEFAULT_LABEL_ALREADY_ENABLED = 619,
  LABEL_FONT_DEFAULT_WIDGET_ALREADY_ENABLED = 620,
  LABEL_CONFLICTS = 621,
  LABEL_INVALID_OBJECTS = 622,

  // Brands Error Codes 800 - 999
  BRAND_NOT_EXISTS = 800,
  BRAND_NAME_ALREADY_EXIST = 801,
  BRAND_NAME_SHORTNAME_ALREADY_EXIST = 802,
  BRAND_NAME_SHORTNAME_RBrand_ALREADY_EXIST = 803,
  BRAND_SHORTNAME_ALREADY_EXIST = 804,
  BRAND_SHORTNAME_RBRAND_ALREADY_EXIST = 805,
  BRAND_NAME_RBRAND_ALREADY_EXIST = 806,
  BRAND_RBRAND_ALREADY_EXIST = 807,
  BRAND_IS_IN_USE = 808,
  BRAND_LOGO_WRONG_DIMENSIONS = 809,
  BRAND_BANNER_WRONG_DIMENSIONS = 810,
  MOBILE_BRAND_LOGO_WRONG_DIMENSIONS = 811,
  MOBILE_BRAND_BANNER_WRONG_DIMENSIONS = 812,
  BRAND_PREFIX_ALREADY_EXIST = 7003,
  BRAND_IMAGE_RECOGNITION_LABEL_KEY_ALREADY_EXISTS = 814,
  BRAND_IMAGE_RECOGNITION_LABEL_ASSSIGNED_TO_INGREDIENT = 815,
  BRAND_EXTERNAL_ID_ALREADY_EXIST = 816,
  BRAND_NAME_EXISTS_FOR_RESELLER = 817,
  BRAND_LOGO_MAX_FILE_SIZE_REACHED = 818,
  BRAND_BANNER_MAX_FILE_SIZE_REACHED = 819,
  MOBILE_BRAND_LOGO_MAX_FILE_SIZE_REACHED = 820,
  MOBILE_BRAND_BANNER_MAX_FILE_SIZE_REACHED = 821,

  // Tenants Error Codes 1000 - 1199
  TENANT_NOT_EXIST = 1000,
  TENANT_IS_NOT_A_RESELLER = 1001,
  ACCOUNT_HAS_NO_PERMISSION_TO_CREATE_OR_EDIT_ACCOUNT = 1002,
  TENANT_NAME_ALREADY_EXIST = 1003,
  TENANT_MODE_ORDER_NOT_RESPECTED = 1004,
  COULD_NOT_UPDATE_THE_MODE_FOR_NON_TRANSACT_USERS = 1005,
  CUSTOMER_EXTERNAL_ID_ALREADY_EXIST = 1010,
  LOCATION_HAS_CHILDREN = 1011,
  LOCATION_IS_A_STORE = 1012,
  TENANT_HAS_SUBLOCATIONS_CREATED = 1017,
  TENANT_CAN_NOT_TURN_RECIPE_OFF = 1018,
  TENANT_UPDATE_NOT_ALLOWED_TO_DISABLE_LABELING_FLAG = 1024,
  TENANT_UPDATE_NOT_ALLOWED_TO_DISABLE_MEDIA_MANAGER_FLAG = 1025,
  ORDER_GUIDES_REQUIRED_FOR_ENABLING_ORDERS = 1030,
  TENANT_NOT_ALLOWED_TO_HAVE_RECIPE_MANAGEMENT_ENABLED = 1031,
  ACCOUNT_INFORMATION_IS_NOT_VALID = 1032,
  TENANT_REFRESH_TOKEN_LESS_THAN_USER_TOKEN = 1033,
  TENANT_UPDATE_NOT_ALLOWED_TO_DISABLE_OPS_CHECKLIST_FLAG = 1034,
  TENANT_UPDATE_NOT_ALLOWED_TO_DISABLE_RFID_SUPPORT_FLAG = 1037,

  // Has no access to import devices Code 213
  HAS_IMPORT_TERMINAL_ACCESS = 213,

  // Users Error Codes 1200 - 1399
  LOGIN_INVALID_CREDENTIALS = 1200,
  USER_EMAIL_ALREADY_EXIST = 1201,
  USER_PIN_ALREADY_EXIST = 1202,
  USER_EMPLOYEE_ID_ALREADY_EXIST = 1203,
  USER_NAME_OR_ID_INVALID = 1204,
  GUID_INVALID = 1205,
  USER_NOT_ASSIGNED_TO_TERMINAL_LOCATION = 1206,
  SYSTEM_ADMIN_ROLE_DOES_NOT_EXIST = 1207,
  USER_NO_ACCESS = 1210,
  EMAIL_REQUIRED = 1211,
  ASSIGN_NON_RESELLER_ROLE_TO_RESELLER_USER = 1217,
  INVALID_USER_PIN_LENGTH = 1218,
  ACTIVATION_LINK_ALREADY_SENT = 1219,
  INVALID_LINK_TOKEN = 1220,
  LINK_TOKEN_EXPIRED = 1221,
  USER_DOES_NOT_EXIST = 1222,
  USER_ALREADY_ACTIVATED = 1223,
  USER_CHANGE_PASSWORD_LINK_EXPIRED = 1224,
  USER_INACTIVE = 1225,
  USER_ACTIVATION_FAILED = 1226,
  USER_IMPORT_LOCATION_CODE_ERROR = 1228,
  USER_MANAGE_LOCATION_INVALID_USER_ID = 1231,
  USER_MANAGE_LOCATION_INVALID_LOCATION_CODE = 1232,
  USER_PIN_IS_INVALID = 1234,
  USER_LOCATION_CODE_INVALID_LENGTH = 1227,
  USER_IMPORT_ALREADY_EXISTS = 1241,
  USER_BLOCKED_CAN_NOT_LOGIN = 1243,
  INVALID_ACCOUNT_NAME_LOGIN = 1244,
  USER_LIST_CANNOT_BE_EMPTY = 1246,
  USER_PASSWORD_SET = 1247,

  // Menus Error Codes 1400 - 1599
  MENU_ASSIGNED_TO_LOCATION = 1400,
  MENU_NOT_EXISTS = 1401,
  MENU_NOT_ASSIGNED_TO_LOCATION = 1402,
  MENU_INVALID_SUBSCRIPTION_CODE = 1404,
  TENANT_OWNS_MENU = 1405,
  TENANT_MENU_WRONG_BRAND = 1406,
  TENANT_MENU_ALREADY_SUBSCRIBED = 1407,
  TENANT_IS_NOT_A_PUBLISHER_ON_THIS_BRAND = 1408,
  MENU_IS_SUBSCRIPTION = 1410,
  MENU_HAS_SUBSCRIPTIONS = 1411,
  MENU_ASSIGNED_TO_NODE = 1412,
  MENU_HAS_SUBSCRIBERS = 1413,
  MENU_IS_CUSTOMIZED = 1416,
  MENU_HAS_SUBSCRIPTION_CODE = 1417,
  MENU_NAME_ALREADY_EXISTS = 1418,
  SUBSCRIBER_DOES_NOT_HAVE_RECIPE_FLAG_ENABLED = 1419,
  PUBLISHER_DOES_NOT_HAVE_RECIPE_FLAG_ENABLED = 1420,
  MENU_HAS_CUSTUMIZED_ITEMS = 1421,
  // Tenant not subscribed to menu
  TENANT_NOT_SUBSCRIBED_TO_MENU = 1414,
  MENU_CANNOT_BE_APPROVED_PENDING_RECIPE_MANAGEMENT_NOT_ENABLED = 1423,
  MENU_ALREADY_PUBLISHED_AT_LOCATION = 1426,
  MENU_ITEM_ALREADY_PUBLISHED_AT_LOCATION = 1427,

  // MenuItems, Products, Ingredients and Categories Error Codes 1600 - 1799
  INGREDIENT_INVALID = 1600,
  INGREDIENT_NOT_EXISTS = 1601,
  INGREDIENT_ASSIGNED_TO_MENU = 1602,
  PRODUCT_ASSIGNED_TO_MENU = 1603,
  PRODUCT_NOT_EXISTS = 1604,
  THUMBNAIL_IMAGE_FILE_IS_MISSING = 1605,
  THUMBNAIL_IMAGE_FILE_LARGER_THAN_500_KB = 1606,
  LABEL_MANDATORY = 1605,
  MENUITEM_DELETION_CONFLICT = 1609,
  MENUITEM_NOT_IN_USE = 1610,
  MENUITEM_PUBLISH_CONFLICT = 1612,
  PRODUCT_NAME_ALREADY_EXISTS = 1613,
  INGREDIENT_NAME_ALREADY_EXISTS = 1614,
  CATEGORY_CANNOT_BE_DELETED_AT_THIS_LOCATION = 1615,
  CATEGORY_HAS_ITEMS = 1616,
  INGREDIENT_SHELF_LIFE_MANDATORY = 1618,
  INGREDIENT_UOM_ASSIGNED_TO_RECIPE = 1619,
  MENUITEM_CATEGORY_NAME_ALREADY_EXISTS = 1621,
  PRODUCT_RESTORE_FAILED = 1622,
  INGREDIENT_RESTORE_FAILED = 1623,
  PRODUCT_RESTORE_DUPLICATE_NAME = 1624,
  INGREDIENT_RESTORE_DUPLICATE_NAME = 1625,
  INGREDIENT_CANNOT_HAVE_UOM_OTHER_THAN_GRAM = 1626,
  UOM_WRONG_FOR_ASSIGNED_COMPONENTS = 1627,
  RECIPE_CANNOT_HAVE_UOM_OTHER_THAN_GRAM = 1628,
  MENU_BULK_EDIT = 1630,
  INGREDIENT_LABELING_CONFLICTS = 1631,
  ASSIGNED_LABELS_MUST_BE_DIFFERENT = 1632,
  MENUITEM_HAS_LISTCUSTOMFIELD_NO_LISTOPTIONID = 1635,
  MENU_BULK_EDIT_OVERRIDE_ITEM_STYLE = 1428,
  // Recipes Error Codes 1800 - 1999
  RECIPE_INVALID = 1800,
  PRODUCT_COULD_NOT_UNASSIGN_RECIPES = 1810,
  RECIPE_DELETION_CONFLICT = 1811,
  RECIPE_NAME_ALREADY_EXISTS = 1812,
  RECIPE_CONFLICTS_INGREDIENTS = 1813,
  RECIPE_CONFLICTS_RECIPES = 1814,
  RECIPE_ASSIGNED_TO_RECIPE = 1815,
  RECIPE_ASSIGNED_TO_PRODUCT = 1816,
  RECIPE_OPTION_NAME_ALREADY_EXISTS = 1817,
  RECIPE_OPTION_SECONDARY_NAME_ALREADY_EXISTS = 1818,
  RECIPE_UOM_ASSIGNED_TO_RECIPE = 1819,
  RECIPE_UOM_ASSIGNED_TO_PRODUCT = 1820,
  RECIPE_OPTION_HAS_RECIPE_DELETED = 1821,
  RECIPE_OPTION_SIZE_HAS_ADDON = 1822,
  RECIPE_ASSIGNED_ADDON_VIOLATION = 1827,
  PRODUCT_SHOULD_HAVE_ONE_PRICE_OPTION = 1828,
  ASSIGNED_RECIPE_BATCH_PRINT_VIOLATION = 1830,
  PRODUCT_DUPLICATE_RECIPE_OPTION_SIZE_NAME = 1831,
  PRODUCT_DUPLICATE_RECIPE_OPTION_RECIPE = 1832,
  DELETE_SHORTCUTS_NOT_CONFIRMED = 1835,
  RECIPE_OPTION_HAS_MISSING_FIELDS = 1836,

  // Nutrition facts Error Codes 2000 - 2199
  NF_TRANSLATION_ADD_ERROR = 2000,
  NF_TRANSLATION_UPDATE_ERROR = 2001,
  NF_UPDATE_ERROR = 2002,

  // Settings error codes 2200 - 2399
  STYLE_NAME_ALREADY_EXISTS = 2200,
  CUSTOM_FIELD_NAME_ALREADY_EXISTS = 2201,
  CUSTOM_FIELD_ALREADY_ASSIGNED_CANNOT_DELETE = 2202,
  CUSTOM_FIELLD_ALREADY_ASSIGNED_CANNOT_UPDATE_TYPE = 2203,
  DATA_TRANSLATION_DUPLICATE_NAME = 2211,
  DATA_TRANSLATION_INVALID_LENGTH = 2213,
  CUSTOM_FIELD_ALREADY_ASSIGNED_TO_SUBSCRIBERS = 2204,
  CUSTOM_FIELD_LIST_OPTION_ALREADY_ASSIGNED_CANNOT_DELETE = 2221,
  CUSTOM_FIELD_MAX_SEARCHABLE_FIELDS_REACHED = 2222,

  // Media error codes 2400 - 2599
  MEDIACATEGORY_UPDATE = 2400,
  MEDIA_UPDATE = 2401,
  TERMINAL_INFO_UPDATED_CANT_REFRESH_TOKEN = 208,
  INVALID_REFRESH_TOKEN = 1208,
  MEDIA_NAME_ALREADY_EXISTS = 2402,
  MEDIA_DELETION_CONFLICT = 2405,
  MEDIA_BULK_INVALID_COUNT_SIZE = 2406,
  MEDIA_CATEGORY_NAME_ALREADY_EXIST = 2407,
  MEDIA_CATEGORY_NOT_OF_TYPE_MEDIA_CATEGORY_GENERATE = 2408,
  MEDIA_CATEGORY_INVALID_SUBSCRIPTION_CODE = 2409,
  TENANT_MEDIA_CATEGORY_WRONG_BRAND = 2410,
  TENANT_MEDIA_CATEGORY_ALREADY_SUBSCRIBED = 2411,
  MEDIA_CATEGORY_CANT_CLEAR_SUBSCRIPTION_CODE_HAS_MEDIA_CATEGORY_SUBSCRIPTION_UNDER_IT = 2412,
  MEDIA_CATEGORY_NOT_OF_TYPE_MEDIA_CATEGORY_CLEAR = 2413,
  TENANT_OWNS_MEDIA_CATEGORY = 2414,
  MEDIA_CATEGORY_HAS_MEDIA = 2415,
  MEDIA_CATEGORY_HAS_A_SUBSCRIPTION_CODE = 2416,
  MEDIA_DIFFERENT_TENANT_CANNOT_DELETE = 2417,
  MEDIA_CATEGORY_NOT_OF_TYPE_MEDIA_CATEGORY_ADD = 2418,
  MEDIA_CATEGORY_HAS_MEDIA_SUBSCRIPTIONS_UNDER_IT_GENERATE = 2419,
  TENANT_ALREADY_SUBSCRIBED_TO_PART_OF_MEDIA_CATEGORY = 2422,
  MEDIA_CATEGORY_HAS_NO_MEDIAS = 2423,
  MEDIA_CANNOT_BE_ADDED_UNDER_ROOT_MEDIA_CATEGORY = 2424,
  MEDIA_CATEGORY_PARENT_HAS_SUBSCRIPTION_CODE = 2425,
  MEDIA_CATEGORY_HAS_ACTIVE_MEDIA_DELETE = 2427,
  MEDIA_CATEGORY_HAS_MEDIA_SUBSCRIPTIONS_DELETE = 2428,
  MEDIA_INVALID_FILE_SIZE = 2429,

  //REFRESH_TOKEN_INVALID
  REFRESH_TOKEN_INVALID = 1214,
  REFRESH_TOKEN_EXPIRED_UNAUTHORIZED_ACCESS = 1215,

  //Addons error code
  ADDON_INVALID_TYPE = 2600,
  ADDON_DELETION_CONFLICT = 2602,
  ADDON_NAME_ALREADY_EXISTS = 2603,
  ADDON_PRICE_CANNOT_BE_NEGATIVE = 2604,

  //Import Users Error Code
  FILE_TYPE_NOT_SUPPORTED = 60,
  FILE_IMPORT_BAD_DATA = 61,
  FILE_IMPORT_EMPTY = 62,
  FILE_WRONG_FORMAT = 84,
  FILE_IMPORT_DUPLICATE_VALUES = 63,
  FILE_IMPORT_DUPLICATE_USERS_IDS = 80,
  FILE_IMPORT_DATA_ERROR = 82,
  FILE_IMPORT_EXCEL_ERROR = 83,
  USER_IMPORT_ALREADY_EXISTS_IN_DB = 1212,
  USER_DUPLICATE_KEY_IN_FILE = 1213,
  BAD_REQUEST = 40,
  TERMINAL_IMPORT_ALREADY_EXISTS_IN_DB = 210,
  TERMINAL_DUPLICATE_KEY_IN_FILE = 211,
  USER_COUNTRY_ISO_CODE_INVALID_LENGTH = 1230,
  TERMINAL_IMPORT_SERIAL_NUMBER_ALREADY_EXIST = 330,

  // Import locations Error Code
  FILE_IMPORT_DUPLICATE_IDS = 64,
  FILE_IMPORT_LOCATION_PARENTID_ROOT_NODE_DOES_NOT_EXIST = 65,
  FILE_IMPORT_STORE_ADDRESS_AND_PHONE_NUMBER_ARE_MANDATORY = 66,
  FILE_IMPORT_PARENT_ID_NOT_DEFINED_IN_IDS = 67,
  FILE_IMPORT_LOCATION_NAME_ALREADY_EXISTS_UNDER_SAME_LOCATION = 68,
  FILE_IMPORT_LOCATION_NAME_MANDATORY = 69,
  FILE_IMPORT_LOCATION_ID_MANDATORY = 70,
  FILE_IMPORT_LOCATION_HIERARCHY_PROBLEM = 71,
  FILE_IMPORT_LOCATION_ONLY_ONE_STORE_ALLOWED_PER_BRANCH = 72,
  FILE_IMPORT_LOCATION_STORE_NUMBER_ALREADY_EXISTS = 74,
  FILE_IMPORT_LOCATION_ID_DOENT_EXIST = 75,
  FILE_IMPORT_LOCATION_DUPLICATE_STORE_NUMBER_IN_FILE = 76,
  FILE_IMPORT_STORE_COUNTRY_ISO_CODE_INVALID_LENGTH = 77,
  FILE_IMPORT_STORE_COUNTRY_AND_CODE_AND_PHONE_NUMBER_CODE_ARE_MANDATORY = 78,
  FILE_IMPORT_STORE_COUNTRY_FIELDS_MUST_BE_IN_CORRECT_FORMAT = 79,
  FILE_IMPORT_LOCATION_INCORRECT_FORMAT = 97,
  DEVICE_ID_COLUMN_MISSING = 98,

  // SMTP_ERROR
  SMTP_ERROR = 2800,

  // Activation

  // Roles
  ROLE_NOT_FOUND = 3400,
  MODULES_NOT_FOUND = 3401,
  MODULE_PERMISSION_NOT_FOUND = 3402,
  DUPLICATE_ROLE_NAME = 3403,

  // DIS
  DIS_ON_DEMAND_IMPORT_ALREADY_REQUESTED = 3600,

  // LOGS
  LOG_DIRECTORY_NOT_FOUND = 3800,
  LOG_FILE_NOT_FOUND = 3801,

  WIDGET_DELETION_CONFLICT = 4101,
  WIDGET_CLONING_FAILED = 4102,
  WIDGET_NAME_ALREADY_EXISTS = 4106,
  LABEL_SIZE_DELETION_CONFLICT = 4003,
  LABEL_SIZE_USED_BY_SUBSCRIBERS = 4005,
  WIDGET_NFPROFILE_MISMATCH = 4108,
  WIDGET_NFPROFILE_MISMATCH_FOR_WIDGET = 4109,
  WIDGET_USED_BY_SUBSCRIBERS = 4110,
  // Audit Trail
  AUDIT_TRAIL_LIMIT_EXCEEDED = 6001,
  // Temp Reports
  NO_REPORT_AVAILABLE_FOR_THIS_DATE = 6102,

  //Temp Configuration
  WORKFLOW_TEMPLATE_LINKED_TO_ACCOUNT = 5100,
  WORKFLOW__IMAGE_FILE_IS_MISSING = 3434,
  WORKFLOW_TEMPLATE_NAME_ALREADY_EXISTS = 5101,
  WORKFLOW_Workflow_NOT_EXIST = 5285,
  WORKFLOW_TENANT_NAME_ALREADY_EXISTS = 5102,
  WORKFLOW_TEMPLATE_KEY_ALREADY_EXISTS = 5103,
  WORKFLOW_ASSIGNED_TO_INGREDIENT = 5105,
  WORKFLOW_USED_IN_TASKS = 5106,
  WORKFLOW_USED_BY_SUBSCRIBER_ACCOUNTS = 5110,
  WORKFLOW_TYPE_ALREADY_EXISTS_FOR_ACCOUNT = 5111,

  //Workflow builder 5300-5400
  WORKFLOW_NAME_ALREADY_EXISTS = 5301,

  // Checklist
  CHECKLIST_NAME_ALREADY_EXISTS = 6401,
  CHECKLIST_ASSIGNED_DELETE_CONFLICT = 6403,
  CHECKLIST_HAS_SUBSCRIBERS = 6405,
  SUBSCRIPTION_CHECKLIST_CODE_CLEAR = 6419,
  CHECKLIST_SUBSCRIPTION_CODE_GENERATION_FAILED_NO_ENABLED_TASKS = 6418,
  CHECKLIST_SECTION_NAME_ALREADY_EXISTS = 6501,
  CHECKLIST_CANT_BE_PUBLISHED = 6417,
  CHECKLIST_CANT_BE_PUBLISHED_CZ_OF_UNDESIGNED_TASKS = 6503,
  CHECKLIST_CANT_BE_PUBLISHED_DUE_TO_NO_ENABLED_TASKS = 6504,
  OPS_CHECKLIST_CAN_NOT_BE_ADDED_INVALID_TYPE = 6502,
  APPROVED_EQUIPMENT_OR_CHECKLIST_SUBSCRIPTION_CAN_NOT_BE_REJECTED = 6505,

  CHECKLIST_CANT_BE_LOCKED_TASKS_ADDED_AT_CHILD_LOCATIONS = 6506,
  CHECKLIST_CANT_BE_LOCKED_TASKS_CUSTOMIZED_ALREADY = 6507,
  CHECKLIST_CANT_BE_LOCKED_CUSTOMIZATIONS_AND_CHILD_TASKS_EXIST = 6508,
  TEMP_CHECKLIST_SECTION_IMPORT_ERROR = 6509,
  CHECKLIST_ALREADY_PUBLISHED_AT_LOCATION = 6512,

  //RESELLER ERROR CODES
  RESELLER_SUB_ROUTE_IS_DUPLICATE = 6702,

  //CHECKLIST SUBSCRIPTION
  CHECKLIST_SUBSCRIPTION_CODE_IS_INVALID = 6420,
  CHECKLIST_IS_OWNED_BY_TENANT = 6421,
  CHECKLIST_SUBSCRIBER_HAS_DIFFERENT_BRAND = 6422,
  CHECKLIST_IS_ALREADY_SUBSCRIBED = 6423,
  CHECKLIST_DOES_NOT_EXIST_UNDER_LOCATION = 6500,
  CHECKLIST_CANT_SUBSCRIBE_MULTIPLE_PUBLISHERS = 6540,
  CHECKLIST_CANT_SUBSCRIBE_EXISTING_SESSIONS = 6541,
  CHECKLIST_EXPORT_ERROR = 6920,
  CHECKLIST_IMPORT_ERROR = 6900,

  CHECKLIST_CAN_NOT_BE_EDITED_NOT_CREATED_AT_LOCATION = 6424,

  //Temp Tasks
  TASK_NAME_ALREADY_EXISTS = 6200,

  // Temp Session
  TEMP_SESSION_NAME_ALREADY_EXISTS = 2204,
  TEMP_SESSION_OVERNIGHT = 2206,
  TEMP_SESSION_ADD_IS_SUBSCRIBER = 2208,
  TEMP_SESSION_EDIT_IS_SUBSCRIPTION = 2209,
  SESSION_DELETE_CONFLICT = 6201,
  TEMP_SESSION_CHECKLIST_CONFLICTS = 2212,

  //Group Tags
  GROUPTAG_NAME_ALREADY_EXISTS = 6600,
  GROUPTAG_DOES_NOT_EXIST = 6601,
  GROUPTAG_ASSIGNED_TO_PRODUCT = 6602,
  GROUPTAG_ASSIGNED_TO_INGREDIENT = 6603,
  GROUPTAG_ASSIGNED_TO_RECIPE = 6604,

  //Equipment Type
  EQUIPMENT_TYPE_NAME_ALREADY_EXISTS = 6304,
  EQUIPMENT_TYPE_UNIT_NAME_ALREADY_EXISTS = 6306,
  NO_ACTIVE_EQUIPMENT_TYPES = 6314,
  EQUIPMENTTYPES_SUBSCRIPTION_CODE_INVALID = 6315,
  EQUIPMENTTYPES_SUBSCRIPTION_CODE_NOT_FOUND = 6316,
  EQUIPMENTTYPES_TENANT_ALREADY_SUBSCRIBED = 6317,
  TENANTS_ALREADY_SUBSCRIBED_TO_EQUIPMENT_TYPES = 6319,
  TENANT_EQUIPMENTTYPE_BRAND_ERROR = 6320,
  EQUIPMENT_TYPE_CAN_NOT_DEACTIVATE_SENSE = 6321,

  TENANT_HAS_NO_SUBLOCATION_FEATURE = 1014,
  SUBLOCATION_CANNOT_HAVE_CHILDREN = 6802,
  SUBLOCATION_PARENT_NOT_STORE = 6803,
  TARGET_LOCATION_HAS_A_STORE_WITH_SAME_NAME_OF_SUBLOCATION = 6805,
  EQUIPMENT_ASSIGNED_TO_LOCATION_CANT_BE_DELETED_DEACTIVATED = 6305,
  CHECKLIST_LOCATION_SAME_LOCATION_NUMBER = 1016,
  CHECKLIST_CANNOT_CHANGE_ASSIGNED_WORKFLOW_TEMPLATE = 6203,
  CHECKLIST_CANNOT_CHANGE_INPUT_TYPE_OR_EXPECTED_VALUE_TYPE = 6204,
  UNSUPPORTED_WORKFLOW_UNIT = 6202,

  OPS_TASK_SEVERITY_LEVEL_ERROR = 6207,
  OPS_TASK_DIFFICULTY_LEVEL_ERROR = 6208,
  OPS_TASK_AREA_ERROR = 6209,
  OPS_TASK_TYPE_ERROR = 6210,
  PUBLISHED_TASK_STEPS_EDIT_NOT_ALLOWED = 6211,
  TEMP_TASK_OVERLAPPING_TIMEFRAMES = 6212,
  TASK_RECURRENCE_SESSIONS_INVALID = 6215,
  TASK_RECURRENCE_TIME_FRAME_INVALID_START_TIME = 6216,
  OPS_TASK_NAME_TOO_BIG = 6218,
  TEMP_TASK_DUPLICATE_RECURRENCES = 6220,
  TEMP_TASKS_BULK_EDIT_UPDATE_ERROR = 6224,

  CHECKLIST_OPTION_NAME_DUPLICATE = 7100,
  Checklist_Option_Start_End_Time_Should_Be_Unique = 7101,
  Checklist_Option_Start_Time_Should_Be_Less_Than_End_Time = 7102,
  CHECKLIST_OPTION_CHECKLIST_TYPE_IN_USE_CAN_NOT_BE_DELETED = 7103,
  CHECKLIST_OPTION_TASK_SEVERITY_LEVEL_IN_USE_CAN_NOT_BE_DELETED = 7104,
  CHECKLIST_OPTION_TASK_DIFFICULTY_LEVEL_IN_USE_CAN_NOT_BE_DELETED = 7105,
  CHECKLIST_OPTION_TASK_TYPE_IN_USE_CAN_NOT_BE_DELETED = 7106,
  CHECKLIST_OPTION_LOCATION_AREA_IN_USE_CAN_NOT_BE_DELETED = 7107,

  HIERARCHY_DUPLICATE_NAME = 7200,

  TIMER_TEMPLATE_DUPLICATE_NAME = 8200,
  TIMER_TEMPLATE_ASSIGNED_TO_NODE = 8202,

  APP_RELEASE_NOTE_DUPLICATE = 8300,

  PRINTER_MODEL_DUPLICATE = 8400,
  // worklow designer
  CONNECTION_TO_START_NOT_ALLOWED = 8500,
  INVALID_LIQUID_EXPRESSION = 8501,
  INFINITE_LOOP = 8502,

  SCALE_MODEL_DUPLICATE = 8600,

  UNKNOWN_ZY_ERROR = 9000,
  //Ordering
  ORDERING_TEMPLATE_DUPLICATE_NAME = 9101,
  ORDERING_INVALID_DELIVERY_DATE = 9012,
  TEMPLATE_ITEM_COUNT_NOT_SUPPORTED = 9103,
  ORDERING_FAILED_ORDER_ALREADY_FOUND = 9013,
  TEMPLATE_ITEM_LIMIT_REACHED = 9105,

  KNOWLEDGE_BASE_ZY_API_ERROR = 9120,
  KNOWLEDGE_BASE_NO_ZY_TOKNEN = 9121,

  // SCALE CONTAINERS
  SCALE_CONTAINER_NAME_ALREADY_EXSIST = 9200,
  SCALE_CONTAINER_ALREADY_ASSIGNED = 9201,

  // BATCH PRINT
  BATCH_PRINT_HAS_CUSTOMIZED_ITEMS = 9300,
  BATCH_PRINT_DUPLICATE_NAME = 9301,

  // Enterprise Portal Distribution Centers
  ENTERPRISE_PORTAL_DISTRIBUTION_CENTERS_GENERAL_ERROR = 9900,

  // PowerBI
  POWER_BI_REPORT_NAME_ALREADY_EXIST = 11100,

  // Retail (Reserved 999001 -> 999100)
  CATALOG_NOT_ACCESSIBLE = 999001,
  CATALOG_ADF_PIPELINE_RUN_ALREADY_IN_PROGRESS = 999002
}
