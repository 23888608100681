import { MatPassToggleVisibilityComponent, MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CommonModule } from '@angular/common';
import { Component, ViewChild, Input, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'nome-password-visibility-toggle',
  templateUrl: './password-visibility-toggle.component.html',
  styleUrls: ['./password-visibility-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatPasswordStrengthModule, CommonModule]
})
export class PasswordVisibilityToggleComponent implements AfterViewInit {
  @Input() eyeOpen = true;
  @ViewChild(MatPassToggleVisibilityComponent) eyeIcon: MatPassToggleVisibilityComponent;
  get inputType(): string {
    return !this.eyeIcon || this.eyeIcon.isVisible ? 'password' : 'text';
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.cd.detectChanges();
  }
}
